import { billingTypes as actionTypes } from '../types';

export const initialState = {
    data: [],
    loading: false,
    transaction: [],
    transactionMeta: {}
};

const state = (state = initialState, { type, payload }) => {
    switch(type) {
        case actionTypes.CURRENCY_FETCH_SUCCESS:
            return {
                ...state,
                data: [
                    payload
                ]
            };

        case actionTypes.TRANSACTION_FETCH_BEGIN:
            return {
                ...state,
                loading: true
            };

        case actionTypes.TRANSACTION_FETCH_SUCCESS:
            return {
                ...state,
                transaction: [ ...payload.data ],
                transactionMeta: { ...payload.meta },
                loading: false
            };

        case actionTypes.TRANSACTION_FETCH_FAILURE:
            return {
                ...state,
                transaction: [],
                transactionMeta: {},
                loading: false
            };

        default:
            return state;
    }
};

export default state;

