import { financeTypes as actionTypes } from '../types';

export const initialState = {
    financeData: {
        data: [],
        meta: {},
        isLoadData: false,
        isLoadingProcess: false
    },
    paymentsData: {
        data: [],
        meta: {},
        isLoadData: false,
        isLoadingProcess: false
    },
    loading: false,
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.FETCH_ACCOUNTS_LIST_BEGIN:
            if ( state.financeData.isLoadingProcess ) {
                return state;
            }
            return {
                ...state,
                financeData: {
                    ...state.financeData,
                    isLoadingProcess: true
                },
                loading: true,
            };

        case actionTypes.FETCH_ACCOUNTS_LIST_SUCCESS:
            return {
                ...state,
                financeData: {
                    ...state.financeData,
                    ...payload,
                    isLoadData: true,
                    isLoadingProcess: false
                },
                loading: false,
            };

        case actionTypes.FETCH_ACCOUNTS_LIST_FAILURE:
            const { messageAccounts } = payload.error;
            if ( messageAccounts && messageAccounts === 'Operation canceled due to new request.' ) {
                return state;
            }
            return {
                ...state,
                financeData: {
                    ...state.financeData,
                    isLoadingProcess: false
                },
                loading: false,
            };

        case actionTypes.GET_PAYMENTS_BEGIN:
            if ( state.paymentsData.isLoadingProcess ) {
                return state;
            }
            return {
                ...state,
                paymentsData: {
                    ...state.paymentsData,
                    isLoadingProcess: true
                },
                loading: true,
            };

        case actionTypes.GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                paymentsData: {
                    ...state.paymentsData,
                    ...payload,
                    isLoadData: true,
                    isLoadingProcess: false
                },
                loading: false,
            };

        case actionTypes.GET_PAYMENTS_FAILURE:
            const { messagePayments } = payload.error;
            if ( messagePayments && messagePayments === 'Operation canceled due to new request.' ) {
                return state;
            }
            return {
                ...state,
                paymentsData: {
                    ...state.paymentsData,
                    isLoadingProcess: false
                },
                loading: false,
            };

        default:
            return state;
    }
};

export default state;