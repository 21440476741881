export const ROLES_FETCH_BEGIN = 'Roles/FetchBegin';
export const ROLES_FETCH_SUCCESS = 'Roles/FetchSuccess';
export const ROLES_FETCH_FAILURE = 'Roles/FetchFailure';

export const ROLES_ROLE_FETCH_BEGIN = 'Roles/RoleFetchBegin';
export const ROLES_ROLE_FETCH_SUCCESS = 'Roles/RoleFetchSuccess';
export const ROLES_ROLE_FETCH_FAILURE = 'Roles/RoleFetchFailure';

export const ROLES_ROLE_CREATE_BEGIN = 'Roles/RoleCreateBegin';
export const ROLES_ROLE_CREATE_SUCCESS = 'Roles/RoleCreateSuccess';
export const ROLES_ROLE_CREATE_FAILURE = 'Roles/RoleCreateFailure';

export const ROLES_ROLE_DELETE_BEGIN = 'Roles/RoleDeleteBegin';
export const ROLES_ROLE_DELETE_SUCCESS = 'Roles/RoleDeleteSuccess';
export const ROLES_ROLE_DELETE_FAILURE = 'Roles/RoleDeleteFailure';

export const ROLES_ROLE_UPDATE_BEGIN = 'Roles/RoleUpdateBegin';
export const ROLES_ROLE_UPDATE_SUCCESS = 'Roles/RoleUpdateSuccess';
export const ROLES_ROLE_UPDATE_FAILURE = 'Roles/RoleUpdateFailure';

export const PERMISSIONS_FETCH_BEGIN = 'Roles/PermissionsFetchBegin';
export const PERMISSIONS_FETCH_SUCCESS = 'Roles/PermissionsFetchSuccess';
export const PERMISSIONS_FETCH_FAILURE = 'Roles/PermissionsFetchFailure';