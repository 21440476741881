import { createSelector, defaultMemoize } from 'reselect';
import { arrayContainSomeOfArray } from '../../helpers/arrayHelpers';
import { CpcId, CpmId } from "../../core/constants";

const rolesSelector = state => state.Dictionaries.roles;
const limitsSelector = state => state.Dictionaries.limits;
const featuresSelector = state => state.Dictionaries.features;
const assetTypesSelector = state => state.Dictionaries.assetTypes;
const featureAssetTypesSelector = state => state.Dictionaries.featureAssetTypes;
const placementTypesSelector = state => state.Dictionaries.placementTypes;
const pricingTypesSelector = state => state.Dictionaries.pricingTypes;
const currenciesSelector = state => state.Dictionaries.currencies;
const countriesSelector = state => state.Dictionaries.countries;
const devicesSelector = state => state.Dictionaries.devices;
const systemsSelector = state => state.Dictionaries.systems;
const groupBySelector = state => state.Dictionaries.groupBy;
const reportMetricsSelector = state => state.Dictionaries.reportMetrics;
const creativeTypesSelector = state => state.Dictionaries.creativeTypes;
const paymentSystemsSelector = state => state.Dictionaries.paymentSystems;
const priorityTypesSelector = state => state.Dictionaries.priorityTypes;
const categoriesSelector = state => state.Dictionaries.categories;
const dictionariesListSelector = state => state.Dictionaries.dictionariesList;
const dimensionsSelector = state => state.Dictionaries.dimensions;
const assetTagsSelector = state => state.Dictionaries.assetTags;
const dimensionsDateSelector = state => state.Dictionaries.dimensionsDate;
const feedTemplatesSelector = state => state.Dictionaries.feedTemplates;
const nonStdTemplatesSelector = state => state.Dictionaries.nonStdTemplates;
const sharedActionsSelector = state => state.Dictionaries.sharedActions;
const paymentMethodsSelector = state => state.Dictionaries.paymentMethods;
const paymentSystemMethodsSelector = state => state.Dictionaries.paymentSystemMethods;
const connectionTypesSelector = state => state.Dictionaries.connection_types;
const ispSelector = state => state.Dictionaries.isp;
const statusesSelector = state => state.Dictionaries.statuses;
const trafficFormatsSelector = state => state.Dictionaries.trafficFormats;
const trackingEventsSelector = state => state.Dictionaries.trackingEvents;
const synteticsSelector = state => state.Dictionaries.syntetics;
const webAnalyticProvidersSelector = state => state.Dictionaries.web_analytic_providers;
const timeZonesSelector = state => state.Dictionaries.timeZones;
const creativeTemplatesSelector = state => state.Dictionaries.creativeTemplates;
const languagesSelector = state => state.Dictionaries.languages;
const shapingSelector = state => state.Dictionaries.shaping;
const paymentGatewaysSelector = state => state.Dictionaries.payment_gateways;
const serviceDomainsSelector = state => state.Dictionaries.serviceDomains;

export const selectors = {
    rolesSelector,
    limitsSelector,
    featuresSelector,
    assetTypesSelector,
    featureAssetTypesSelector,
    placementTypesSelector,
    pricingTypesSelector,
    currenciesSelector,
    countriesSelector,
    devicesSelector,
    systemsSelector,
    groupBySelector,
    reportMetricsSelector,
    creativeTypesSelector,
    paymentSystemsSelector,
    priorityTypesSelector,
    categoriesSelector,
    dictionariesListSelector,
    dimensionsSelector,
    assetTagsSelector,
    dimensionsDateSelector,
    feedTemplatesSelector,
    nonStdTemplatesSelector,
    sharedActionsSelector,
    paymentMethodsSelector,
    paymentSystemMethodsSelector,
    connectionTypesSelector,
    ispSelector,
    statusesSelector,
    trafficFormatsSelector,
    synteticsSelector,
    webAnalyticProvidersSelector,
    timeZonesSelector,
    creativeTemplatesSelector,
    languagesSelector,
    shapingSelector,
    paymentGatewaysSelector,
    serviceDomainsSelector
};

export const dictionariesList = createSelector(dictionariesListSelector, dictionariesList => dictionariesList);
export const makeDictionariesList = () => createSelector(dictionariesListSelector, dictionariesList => dictionariesList);
export const roles = createSelector(rolesSelector, roles => roles);
export const limits = createSelector(limitsSelector, limits => limits);
export const features = createSelector(featuresSelector, features => features);
export const assetTypes = createSelector(assetTypesSelector, assetTypes => assetTypes);
export const featureAssetTypes = createSelector(featureAssetTypesSelector, featureAssetTypes => featureAssetTypes);
export const placementTypes = createSelector(placementTypesSelector, placementTypes => placementTypes);
export const makePlacementTypes = () => createSelector(placementTypesSelector, placementTypes => placementTypes);
export const pricingTypes = createSelector(pricingTypesSelector, pricingTypes => pricingTypes);
export const currencies = createSelector(currenciesSelector, currencies => currencies);
export const countries = createSelector(countriesSelector, countries => countries);
export const devices = createSelector(devicesSelector, devices => devices);
export const systems = createSelector(systemsSelector, systems => systems);
export const groupBy = createSelector(groupBySelector, groupBy => groupBy);
export const reportMetrics = createSelector(reportMetricsSelector, reportMetrics => reportMetrics);
export const creativeTypes = createSelector(creativeTypesSelector, creativeTypes => creativeTypes);
export const makeCreativeTypes = () => createSelector(creativeTypesSelector, creativeTypes => creativeTypes);
export const paymentSystems = createSelector(paymentSystemsSelector, paymentSystems => paymentSystems);
export const priorityTypes = createSelector(priorityTypesSelector, priorityTypes => priorityTypes);
export const categories = createSelector(categoriesSelector, categories => categories);
export const dimensions = createSelector(dimensionsSelector, dimensions => dimensions);
export const assetTags = createSelector(assetTagsSelector, assetTags => assetTags);
export const dimensionsDate = createSelector(dimensionsDateSelector, dimensionsDate => dimensionsDate);
export const feedTemplates = createSelector(feedTemplatesSelector, feedTemplates => feedTemplates);
export const nonStdTemplates = createSelector(nonStdTemplatesSelector, nonStdTemplates => nonStdTemplates);
export const sharedActions = createSelector(sharedActionsSelector, sharedActions => sharedActions);
export const systemActions = createSelector(sharedActionsSelector, sharedActions => sharedActions.filter(action => !!action.is_system));
export const notSystemActions = createSelector(sharedActionsSelector, sharedActions => sharedActions.filter(action => !action.is_system));
export const paymentMethods = createSelector(paymentMethodsSelector, paymentMethods => paymentMethods);
export const paymentSystemMethods = createSelector(paymentSystemMethodsSelector, paymentSystemMethods => paymentSystemMethods);
export const connection_types = createSelector(connectionTypesSelector, connection_types => connection_types);
export const isp = createSelector(ispSelector, isp => isp);
export const statuses = createSelector(statusesSelector, statuses => statuses);
export const trafficFormats = createSelector(trafficFormatsSelector, trafficFormats => trafficFormats);
export const trackingEvents = createSelector(trackingEventsSelector, trackingEvents => trackingEvents);
export const syntetics = createSelector(synteticsSelector, syntetics => syntetics);
export const webAnalyticProviders = createSelector(webAnalyticProvidersSelector, web_analytic_providers => web_analytic_providers);
export const timeZones = createSelector(timeZonesSelector, timeZones => {
    const utc = timeZones.find(item => item.name === 'UTC');
    if ( utc ) {
        return [
            utc || {},
            ...timeZones.filter(item => item.name !== 'UTC' )
        ];
    }
    return timeZones;
});
export const creativeTemplates = createSelector(creativeTemplatesSelector, creativeTemplates => creativeTemplates);

export const categoriesByAssetType = defaultMemoize(
    assetType => createSelector(categoriesSelector, categories => categories.filter(el => el.asset_types.includes(assetType)))
);
export const assetTagsByAssetType = defaultMemoize(
    assetType => createSelector(assetTagsSelector, assetTags => assetTags.filter(el => el.asset_types.includes(assetType)))
);
export const statusesByAssetType = defaultMemoize(
    assetType => createSelector(statusesSelector, statuses => statuses.filter(el => el.scope.includes(assetType)))
);
export const languages = createSelector(languagesSelector, languages => languages);
export const shaping = createSelector(shapingSelector, shaping => shaping);

export const pricingTypesProjects = createSelector(
        pricingTypesSelector,
            pricingTypes => pricingTypes.filter( item => arrayContainSomeOfArray(item.asset_types, ['project', 'campaign', 'creative']) )
);
export const pricingTypesChannels = createSelector(
        pricingTypesSelector,
            pricingTypes => pricingTypes.filter( item => arrayContainSomeOfArray(item.asset_types, ['channel', 'placement']) )
);
export const pricingTypesCpcCpm = createSelector(
        pricingTypesSelector,
            pricingTypes => pricingTypes.filter(el => el.id === CpcId || el.id === CpmId)
);
export const assetTagsByProjects = createSelector(
    assetTags,
    assetTags => assetTags.filter( item => arrayContainSomeOfArray(item.asset_types, ['project', 'campaign', 'creative']) )
);
export const assetTagsByChannels = createSelector(
    assetTags,
    assetTags => assetTags.filter( item => arrayContainSomeOfArray(item.asset_types, ['channel', 'placement']) )
);
export const categoriesByProjects = createSelector(
    categoriesSelector,
        categories => categories.filter( item => arrayContainSomeOfArray(item.asset_types, ['project', 'campaign', 'creative']) )
);
export const categoriesByChannels = createSelector(
    categoriesSelector,
        categories => categories.filter( item => arrayContainSomeOfArray(item.asset_types, ['channel', 'placement']) )
);
export const statusesByProjects = createSelector(
    statusesSelector,
        statuses => statuses.filter( item => arrayContainSomeOfArray(item.scope, ['project', 'campaign', 'creative']) )
);
export const statusesByChannels = createSelector(
    statusesSelector,
        statuses => statuses.filter( item => arrayContainSomeOfArray(item.scope, ['channel', 'placement']) )
);
export const statusesByUser = createSelector( statusesSelector, statuses => statuses.filter(el => el.scope.includes('user')) );
export const statusesByUserWithOutDeleted = createSelector( statusesSelector, statuses => statuses.filter(el => el.scope.includes('user') && el.status !== 'deleted') );

export const featureShapingDictionariesSelector = createSelector(
    [ countriesSelector, sharedActionsSelector ],
    ( countries, sharedActions ) => {
        return {
            countriesList: countries,
            actions: sharedActions
        }
    }
);
export const featureThirdPartyEventsDictionariesSelector = createSelector(
    [ trackingEventsSelector, sharedActionsSelector ],
    ( trackingEvents, sharedActions ) => {
        return {
            trackingEvents: trackingEvents,
            dictionaries: {
                actions: sharedActions.filter(action => !!action.is_system)
            }
        }
    }
);

export const paymentGateways = createSelector(paymentGatewaysSelector, payment_gateways => payment_gateways);
export const paymentAmounts = createSelector(state => state.Dictionaries.payment_amounts, payment_amounts => payment_amounts);
export const paymentPeriods = createSelector(state => state.Dictionaries.payment_periods, payment_periods => payment_periods);

export const campaignsEditDictionaries = createSelector(
    [priorityTypesSelector, statusesSelector, assetTagsSelector, creativeTypesSelector],
    ( priorityTypes, statuses, assetTags, creativeTypes ) => ({
        priority_type: priorityTypes,
        statuses: statuses.filter(el => el.scope.includes('campaign')),
        assetTags: assetTags.filter(el => el.asset_types.includes('campaign')),
        creativeTypes: creativeTypes,
    })
);
export const bannersListDictionaries = createSelector(
    [statusesSelector, creativeTypesSelector],
    ( statuses, creativeTypes ) => ({
        statuses: statuses.filter(el => el.scope.includes('creative')),
        creativeTypes: creativeTypes,
    })
);

export const serviceDomains = createSelector(serviceDomainsSelector, serviceDomains => serviceDomains);