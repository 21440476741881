import {creativetemplatesTypes as actionTypes, profileTypes as profileActionTypes} from "../types/index";

export const initialState = {
    data: [],
    massSharingData: {}
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.CREATIVETEMPLATES_FETCH_SUCCESS:
            return {
                ...state,
                data: [...payload],
            };

        case actionTypes.SHARED_CREATIVETEMPLATES_FETCH_SUCCESS:
            return {
                ...state,
                massSharingData: {...payload},
            };

        case profileActionTypes.REINCARNATION_SUCCESS: {
            if ( payload.token ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        default:
            return state;
    }
};

export default state;