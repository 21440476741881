import i18n from 'i18next';
//import XHR from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nDebug } from './constants';

// load translations from languages files
import translationEN from '../locales/en/translations.json';
// import translationRU from '../locales/ru/translations.json';
const resources = {
    en: {
        translations: translationEN
    },
    /*ru: {
        translations: translationRU
    }*/
};

i18n
    //.use(XHR)
    // .use(LanguageDetector) // commented to prevent select ru language
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        resources,
        languages: ['en'],
        whitelist: ['en'],
        // languages: ['en', 'ru'],
        // whitelist: ['en', 'ru'],
        nonExplicitWhitelist: false,
        fallbackLng: 'en',
        //load: 'languageOnly',
        load: 'currentOnly',
        //load: 'all',
        ns: ['translations'],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },
        /*react: {
            wait: true
        },*/
        /*react:{
            bindI18n: 'languageChanged',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
            useSuspense: false //   <---- this will do the magic
        },*/
        debug: i18nDebug
    });

export default i18n;
