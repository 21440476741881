import {createSelector, defaultMemoize} from 'reselect';

export const data = createSelector(state => state.Actions.data, data => data);
export const massSharingData = createSelector(state => state.Actions.massSharingData, massSharingData => massSharingData);
export const massSharingActions = createSelector(state => state.Actions.massSharingData?.data?.actions, actions => actions || []);
export const massSharingUsers = createSelector(state => state.Actions.massSharingData?.data?.users, users => users || []);
export const massSharingRoles = createSelector(state => state.Actions.massSharingData?.data?.roles, roles => roles || []);
export const loading = createSelector(state => state.Actions.loading, loading => loading);
export const page = createSelector(state => state.Actions.meta?.pagination?.current_page, current_page => current_page || 1);
export const total = createSelector(state => state.Actions.meta?.pagination?.total, total => total || 0);
export const postbackUrl = createSelector(state => state.Actions.meta?.postback_url, postback_url => postback_url || '');
export const list = createSelector(state => state.Actions.massSharingData.data, data => data.map(item => { item.expand = ''; return {...item}; }));

export const actionData = defaultMemoize(
    actionId => createSelector(
        state => state.Actions.data,
        data => data.find(item => parseInt(item.id) === parseInt(actionId))
    )
);