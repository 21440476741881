import axios from '../../core/axiosInit';
import checkPermission from "../../core/checkPermiss";

import { analyticsTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import {queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries} from '../helpers';
import toast from '../../helpers/transToast';
import { sizePerPageList } from '../../views/Analytics/Tabs/formData';
import { STATUS_REPORT_READY, STATUS_REPORT_FAILED } from '../../core/constants';
import { errorsProcessor } from '../../helpers/errorsProcessor';

export const fetchReports = () => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.REPORTS_FETCH_BEGIN));
    try {
        let params = {};
        const dictionaries = [];
        if (
            checkPermission('allow statistics timezone filter')
        ) {
            dictionaries.push('time_zone');
        }

        if (
            checkPermission('allow statistics actions dictionary')
            && checkPermission('allow analytics group action_id')
        ) {
            dictionaries.push('action');
        }

        const { Dictionaries: dicts } = getState();
        params = requestAssignDictionaries(dicts, dictionaries);

        const response = await axios.get('reports', {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.REPORTS_FETCH_SUCCESS, { data: response.data.data, analytic: response.data.meta.analytic }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.REPORTS_FETCH_FAILURE));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const setMetricsReceived = () => async dispatch => {
    dispatch(queryAction(actionTypes.REPORTS_METRICS_RECEIVED));
};

export const addReportTemplate = params => async (dispatch) => {
    dispatch(queryAction(actionTypes.REPORTS_ADD_TEMPLATE_BEGIN));
    try {
        const response = await axios.post(`reports`, { ...params });
        dispatch(queryAction(actionTypes.REPORTS_ADD_TEMPLATE_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.REPORTS_ADD_TEMPLATE_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const updateReportTemplate = (id, params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.REPORTS_UPDATE_TEMPLATE_BEGIN, id));
    try {
        const response = await axios.put(`reports/${id}`, { ...params });
        dispatch(queryAction(actionTypes.REPORTS_UPDATE_TEMPLATE_SUCCESS, { id: id, data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.REPORTS_UPDATE_TEMPLATE_FAILURE, { id, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const deleteReportTemplate = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.REPORTS_DELETE_TEMPLATE_BEGIN, id));
    try {
        const response = await axios.delete(`reports/${id}`);
        dispatch(queryAction(actionTypes.REPORTS_DELETE_TEMPLATE_SUCCESS, { id: id, data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.REPORTS_DELETE_TEMPLATE_FAILURE, { id, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const onRunReport = params => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.RUN_REPORT_BEGIN));
    try {
        const response = await axios.post(`statistics`, { ...params, withGlobeCancelToken: true });
        dispatch(queryAction(
            actionTypes.RUN_REPORT_SUCCESS,
            {
                data: response.data.data,
                report: response.data.meta && { ...response.data.meta.report, sort: params.sort || null }
            }
        ));
        // preventing showing report witch was deleted. added deleted to response data
        const { deletedReports } = getState().Analytics;
        if ( response.data.meta.report && deletedReports.indexOf(params.report_id) !== -1 ) {
            response.data.meta.report.deleted = true;
        }
        return response.data;
    } catch (error) {
        if ( axios.isCancel(error) ) {
            return { success: false };
        }
        dispatch(queryAction(actionTypes.RUN_REPORT_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const setNewReportId = (report_id) => (dispatch) => {
    dispatch(queryAction(actionTypes.SET_INTERVAL, report_id));
};

export const getReportProgress = (payload) => async (dispatch, getState) => {
    // get previous state
    const { progress, reports } = getState().Analytics;

    for (let item of payload) {
        const previousProgress = parseInt(progress[item?.id]?.progress) || 0;
        const newProgress = parseInt(item?.progress) || 1;

        if (item?.failed) {
            console.log("STATUS FAILED --- CRASHHHHH");
            dispatch(queryAction(actionTypes.CRASH_REPORT, reports[item.id]?.report));
        }

        // if receive same or less progress value
        if (newProgress <= previousProgress) {
            return;
        }

        // dispatch new data
        dispatch(queryAction(actionTypes.SET_REPORT_PROGRESS, payload));

        const report_id = item?.id;

        if (newProgress > previousProgress && newProgress === 100) {
            const data = {
                report_id,
                current_page: 1,
                limit: sizePerPageList[0].value,
                sort: null,
                not_empty_metrics: 1,
            };
            let response = await dispatch(onRunReport(data));
            if (!response.success || response.status !== 200) {
                if (response.status === 500) {
                    errorsProcessor(response.messages || "500 Internal Server Error");
                    return;
                }
                const report = response.meta && response.meta.report ? response.meta.report : { id: report_id };
                dispatch(queryAction(actionTypes.CRASH_REPORT, report));
                return;
            }
            const report = response.meta.report;
            if (report === null) {
                console.log("REPORT DELETED");
                return;
            }
            if (report.report_status === STATUS_REPORT_READY) {
                console.log("CLEAR INTERVAL --- READY");
                const toastId = toast.info("Loading", "Messages.Loading");
                toast.update(toastId, { type: "success" }, `${report.name} is ready!`, `${report.name} is ready!`);
                dispatch(queryAction(actionTypes.REPORT_READY, report));
            }
            if (report.report_status === STATUS_REPORT_FAILED) {
                console.log("CLEAR INTERVAL --- CRASHHHHH");
                dispatch(queryAction(actionTypes.CRASH_REPORT, report));
            }
        }
    }
};

export const reloadReport = (report_id) => (dispatch) => {
    dispatch(queryAction(actionTypes.RELOAD_REPORT, report_id));
};

export const onSetOrderColumns = (values) => (dispatch) => {
    dispatch(queryAction(actionTypes.SET_ORDER_COLUMNS, values));
};

export const fetchPlacementLink = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.PLACEMENT_LINK_FETCH_BEGIN));
    try {
        const response = await axios.get(`placement/link/${id}`);
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.PLACEMENT_LINK_FETCH_SUCCESS, { data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PLACEMENT_LINK_FETCH_FAILURE));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const fetchCampaignLink = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.CAMPAIGN_LINK_FETCH_BEGIN));
    try {
        const response = await axios.get(`campaigns/link/${id}`);
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.CAMPAIGN_LINK_FETCH_SUCCESS, { data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.CAMPAIGN_LINK_FETCH_FAILURE));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const fetchCreativeLink = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.CREATIVE_LINK_FETCH_BEGIN));
    try {
        const response = await axios.get(`creative/link/${id}`);
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.CREATIVE_LINK_FETCH_SUCCESS, { data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.CREATIVE_LINK_FETCH_FAILURE));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};