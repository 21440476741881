import { createSelector, defaultMemoize } from 'reselect';
import { selectors as dictionaries, statusesByAssetType } from './dictionaries';
// import { selectors as profile } from './profile';
import { deepCopyObject } from '../../helpers/objectHelpers';

const creativesSelector = state => state.Campaigns.creatives;
const dataSelector = state => state.Campaigns.data;

export const list = defaultMemoize(
    projectId => createSelector(state => state.Projects.data, data => {
        const project = data.find(item => parseInt(item.id) === parseInt(projectId));
        return project?.campaigns || [];
    })
);
export const loadingCampaigns = createSelector(state => state.Campaigns.loadingCampaigns, loadingCampaigns => loadingCampaigns);
export const creatives = createSelector(creativesSelector, creatives => creatives);
export const makeCreatives = () => createSelector(creativesSelector, creatives => creatives);

export const getCampaignData = defaultMemoize(
    campaignId => createSelector( dataSelector, data => data.find(item => parseInt(item.id) === parseInt(campaignId)) )
);
export const loadingCreatives = createSelector(state => state.Campaigns.loadingCreatives, loadingCreatives => loadingCreatives);
export const getCampaignCreatives = defaultMemoize(
    campaignId => createSelector( dataSelector, data => {
        const campaign = data.find(item => parseInt(item.id) === parseInt(campaignId));
        return campaign ? campaign?.creatives || [] : [];
    } )
);

export const getCreativeData = defaultMemoize(
    creativeId => createSelector(
        state => state.Campaigns.creatives,
        creatives => creatives.find(item => parseInt(item.id) === parseInt(creativeId)) )
);

export const getCreativeDictionaries = createSelector(
    [
        dictionaries.placementTypesSelector,
        dictionaries.creativeTypesSelector,
        dictionaries.assetTagsSelector,
        dictionaries.feedTemplatesSelector,
        dictionaries.nonStdTemplatesSelector,
        dictionaries.statusesSelector,
        dictionaries.trafficFormatsSelector,
        dictionaries.creativeTemplatesSelector,
    ],
    (
        placementTypes,
        creativeTypes,
        assetTags,
        feedTemplates,
        nonStdTemplates,
        statuses,
        trafficFormats,
        creativeTemplates
    ) => {
        return {
            'creative_type': creativeTypes, // TODO need to filter creative_types by permissions
            'asset_tag': assetTags.filter(el => el.asset_types.includes('creative')),
            'feed_template': feedTemplates,
            'non_standard_template': nonStdTemplates,
            'creative_template': creativeTemplates,
            'status': statuses.filter(el => el.scope.includes('creative')),
            'traffic_format': deepCopyObject(trafficFormats).map(el => {
                delete el.placement_types;
                return el;
            }),
        };
    }
);

export const campaignsDictionaries = createSelector(
    [statusesByAssetType('campaign')],
    (statuses) => ({
        statuses
    })
);