export const PROFILE_FETCH_BEGIN = 'Profile/FetchBegin';
export const PROFILE_FETCH_SUCCESS = 'Profile/FetchSuccess';
export const PROFILE_FETCH_FAILURE = 'Profile/FetchFailure';

export const AUTH_BEGIN = 'Profile/AuthBegin';
export const AUTH_SUCCESS = 'Profile/AuthFetchSuccess';
export const AUTH_FAILURE = 'Profile/AuthFetchFailure';

export const LOGOUT = 'Profile/Logout';

export const REGISTER_BEGIN = 'Profile/RegisterBegin';
export const REGISTER_SUCCESS = 'Profile/RegisterSuccess';
export const REGISTER_FAILURE = 'Profile/RegisterFailure';

export const ERROR_CHECK_AUTH = 'Profile/CheckAuth';

export const PROFILE_EDIT_BEGIN = 'Profile/EditBegin';
export const PROFILE_EDIT_SUCCESS = 'Profile/EditSuccess';
export const PROFILE_EDIT_FAILURE = 'Profile/EditFailure';

export const PROFILE_CHANGE_PASSWORD_BEGIN = 'Profile/ChangePasswordBegin';
export const PROFILE_CHANGE_PASSWORD_SUCCESS = 'Profile/ChangePasswordSuccess';
export const PROFILE_CHANGE_PASSWORD_FAILURE = 'Profile/ChangePasswordFailure';

export const RESET_PASSWORD_BEGIN = 'Profile/ResetPasswordBegin';
export const RESET_PASSWORD_SUCCESS = 'Profile/ResetPasswordSuccess';
export const RESET_PASSWORD_FAILURE = 'Profile/ResetPasswordFailure';

export const RESET_CHANGE_PASSWORD_BEGIN = 'Profile/ResetChangePasswordBegin';
export const RESET_CHANGE_PASSWORD_SUCCESS = 'Profile/ResetChangePasswordSuccess';
export const RESET_CHANGE_PASSWORD_FAILURE = 'Profile/ResetChangePasswordFailure';

export const AUTH_VERIFIED_FAILURE = 'Profile/AuthVerifiedFetchFailure';

export const CONFIRM_EMAIL_BEGIN = 'Profile/ConfirmEmailBegin';
export const CONFIRM_EMAIL_SUCCESS = 'Profile/ConfirmEmailSuccess';
export const CONFIRM_EMAIL_FAILURE = 'Profile/ConfirmEmailFailure';

export const CANCEL_CONFIRM_EMAIL_SUCCESS = 'Profile/CancelConfirmEmailSuccess';

export const ADD_PAYMENTS_SYSTEMS_BEGIN = 'Profile/AddPaymentsSystemsBegin';
export const ADD_PAYMENTS_SYSTEMS_SUCCESS = 'Profile/AddPaymentsSystemsSuccess';
export const ADD_PAYMENTS_SYSTEMS_FAILURE = 'Profile/AddPaymentsSystemsFailure';

export const VERIFICATION_PASSWORD_BEGIN = 'Profile/VerificationPasswordBegin';
export const VERIFICATION_PASSWORD_SUCCESS = 'Profile/VerificationPasswordSuccess';
export const VERIFICATION_PASSWORD_FAILURE = 'Profile/VerificationPasswordFailure';

export const INCARNATION_BEGIN = 'Profile/IncarnationBegin';
export const INCARNATION_SUCCESS = 'Profile/IncarnationSuccess';
export const INCARNATION_FAILURE = 'Profile/IncarnationFailure';

export const REINCARNATION_BEGIN = 'Profile/ReincarnationBegin';
export const REINCARNATION_SUCCESS = 'Profile/ReincarnationSuccess';
export const REINCARNATION_FAILURE = 'Profile/ReincarnationFailure';

export const PROFILE_FETCH_SETTINGS_BEGIN = 'Profile/ProfileFetchSettingsBegin';
export const PROFILE_FETCH_SETTINGS_SUCCESS = 'Profile/ProfileFetchSettingsSuccess';
export const PROFILE_FETCH_SETTINGS_FAILURE = 'Profile/ProfileFetchSettingsFailure';

export const PROFILE_SET_SETTINGS_BEGIN = 'Profile/ProfileSetSettingsBegin';
export const PROFILE_SET_SETTINGS_SUCCESS = 'Profile/ProfileSetSettingsSuccess';
export const PROFILE_SET_SETTINGS_FAILURE = 'Profile/ProfileSetSettingsFailure';

export const PROFILE_UPDATE_TOKEN_STATISTICS_BEGIN = 'Profile/UpdateUserStaticticsTokenBegin';
export const PROFILE_UPDATE_TOKEN_STATISTICS_SUCCESS = 'Profile/UpdateUserStaticticsTokenSuccess';
export const PROFILE_UPDATE_TOKEN_STATISTICS_FAILURE = 'Profile/UpdateUserStaticticsTokenFailure';

export const PROFILE_FETCH_POSTBACK_BEGIN = 'Profile/FetchPostBackUrlBegin';
export const PROFILE_FETCH_POSTBACK_SUCCESS = 'Profile/FetchPostBackUrlSuccess';
export const PROFILE_FETCH_POSTBACK_FAILURE = 'Profile/FetchPostBackUrlFailure';

export const PROFILE_POSTBACK_URL_BEGIN = 'Profile/PostBackUrlBegin';
export const PROFILE_POSTBACK_URL_SUCCESS = 'Profile/PostBackUrlSuccess';
export const PROFILE_POSTBACK_URL_FAILURE = 'Profile/PostBackUrlFailure';

export const UPDATE_REINCARNATION_TOKEN = 'Profile/UpdateReincarnationToken';

export const HIDE_ALERT_MESSAGE = 'Profile/HideAlertMessage';