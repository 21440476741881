import { profileTypes as actionTypes } from '../types';
import { parseJwt, updateUrlHashPart, getUrlHashParameter } from "../../helpers/jwtHelpers";
import { _PROD_ENV_, showLimitTrafficMessageENV } from '../../core/constants';

export const initialState = {
    authError: false,
    authVerifiedError: false,
    isAuth: false,
    isTree: true,
    needToShowAlertMessage: false,
    userData: {
        name: 'Unknown',
        roles: [],
        permissions: [],
        settings: {},
        parentData: null,
    },
    postback_url: '',
    message: null,
    reincarnation: false
};

let hash;

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.AUTH_SUCCESS:
            sessionStorage.removeItem('hiddenTabID');
            sessionStorage.removeItem('fetchCurrentID');
            return {
                ...state,
                // token: payload.token,
                isTree: 'undefined' !== typeof payload.settings && payload.settings && 'undefined' !== typeof payload.settings.isTree ? payload.settings.isTree : true,
                userData: {
                    ...payload,
                    name: payload.name,
                    roles: payload.roles,
                    permissions: payload.permissions,
                    parentData: null,
                },
                needToShowAlertMessage: !!_PROD_ENV_ && !!showLimitTrafficMessageENV,
                isAuth: true,
                authError: false,
                message: null,
                postback_url: ''
            };

        case actionTypes.PROFILE_FETCH_POSTBACK_SUCCESS:
        case actionTypes.PROFILE_POSTBACK_URL_SUCCESS:
            return {
                ...state,
                postback_url: payload
            };

        case actionTypes.HIDE_ALERT_MESSAGE:
            return {
                ...state,
                needToShowAlertMessage: false
            };

        case actionTypes.AUTH_FAILURE: {
            let messages = payload.error.response && payload.error.response.data;
            return {
                ...state,
                isAuth: false,
                authError: true,
                message: messages
            };
        }

        case actionTypes.AUTH_VERIFIED_FAILURE:
            return {
                ...state,
                isAuth: false,
                authVerifiedError: true,
                authError: false
            };

        case actionTypes.CANCEL_CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                isAuth: false,
                authVerifiedError: false,
                authError: false
            };

        case actionTypes.CONFIRM_EMAIL_FAILURE:
            return{
                ...state,
                isAuth: false,
                authVerifiedError: true,
                authError: false
            };

        case actionTypes.PROFILE_FETCH_BEGIN:
            if (!hash && window.location.hash && getUrlHashParameter('t')) {
                hash = getUrlHashParameter('t');
                const token = localStorage.getItem(`t__${hash}`);
                if ( token ) {
                    const jwtDataOnUpdate = parseJwt(token);
                    sessionStorage.setItem('hiddenTabID', jwtDataOnUpdate.sub);
                    sessionStorage.setItem('fetchCurrentID', jwtDataOnUpdate.sub);
                    return {
                        ...state,
                        userData: {
                            ...state.userData,
                            parentData: {
                                id: jwtDataOnUpdate.parent_id,
                                name: jwtDataOnUpdate.parent_name,
                                sub: jwtDataOnUpdate.sub,
                                hash: hash
                            },
                        },
                        isAuth: true,
                    };
                }
            }
            return state;

        case actionTypes.PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                isAuth: true,
                isTree: 'undefined' !== typeof payload.settings && payload.settings && 'undefined' !== typeof payload.settings.isTree ? payload.settings.isTree : true,
                userData: {
                    ...payload,
                    parentData: state.userData.parentData
                },
                message: null
            };

        case actionTypes.PROFILE_EDIT_SUCCESS:
        case actionTypes.PROFILE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...payload
                }
            };

        case actionTypes.ADD_PAYMENTS_SYSTEMS_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    profile: payload
                }
            };

        // TODO change logic of logout from reincarnation
        case actionTypes.LOGOUT:
            if ( state.isAuth ) {
                window.location.replace('/login');
            }
            return {
                ...state,
                ...initialState
            };

        /*case actionTypes.REINCARNATION_BEGIN:
            return {
                ...state,
                reincarnation: true
            };*/

        case actionTypes.REINCARNATION_SUCCESS:
            if ( payload.token ) {
                const jwtDataReincarnation = parseJwt(payload.token);
                hash = btoa(`${state.userData.name}-${state.userData.id}-${jwtDataReincarnation.parent_id}`).replace(/=/g,'');
                localStorage.setItem(`t__${hash}`, payload.token);
                return {
                    ...state,
                    userData: {
                        ...payload.data,
                        ws_token: payload.ws_token,
                        parentData: {
                            id: jwtDataReincarnation.parent_id,
                            name: jwtDataReincarnation.parent_name,
                            sub: jwtDataReincarnation.sub,
                            hash: hash
                        },
                    },
                    isAuth: true,
                    authError: false,
                    reincarnation: false
                };
            }
            localStorage.removeItem(`t__${hash}`);
            return {
                ...state,
                userData: {
                    ...payload.data,
                    ws_token: payload.ws_token,
                    parentData: null,
                },
                isAuth: true,
                authError: false,
                reincarnation: false
            };

        case actionTypes.REINCARNATION_FAILURE:
            return {
                ...state,
                reincarnation: false
            };

        case actionTypes.PROFILE_SET_SETTINGS_SUCCESS:
            return {
                ...state,
                isTree: payload.settings.isTree,
                userData: {
                    ...state.userData,
                    ...payload,
                    settings: payload.settings
                }
            };

        case actionTypes.PROFILE_UPDATE_TOKEN_STATISTICS_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    params: {
                        ...state.userData.params,
                        ...payload.params
                    }

                }
            };

        case actionTypes.UPDATE_REINCARNATION_TOKEN:
            if ( payload ) {
                const jwtDataUpdate = parseJwt(payload);
                hash = btoa(`${state.userData.name}-${state.userData.id}-${jwtDataUpdate.parent_id}`).replace(/=/g,'');
                localStorage.setItem(`t__${hash}`, payload);
                updateUrlHashPart('t', hash);
                return {
                    ...state,
                    userData: {
                        ...state.userData,
                        parentData: {
                            id: jwtDataUpdate.parent_id,
                            name: jwtDataUpdate.parent_name,
                            sub: jwtDataUpdate.sub,
                            hash: hash
                        },
                        reincarnation: !state.userData.parentData
                    },
                    isAuth: true,
                };
            } else {
                hash = btoa(`${state.userData.name}-${state.userData.id}-${state.userData.parentData.parent_id}`).replace(/=/g,'');
                updateUrlHashPart('t');
                localStorage.removeItem(`t__${hash}`);
                return {
                    ...state,
                    userData: {
                        ...state.userData,
                        parentData: null,
                        reincarnation: !!state.userData.parentData
                    },
                    isAuth: true,
                };
            }

        default:
            return state;
    }
};

export default state;