import axios from "../../core/axiosInit";

import { exchangeTypes as actionTypes } from "../types";
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from "../helpers";
import checkAuthError from "./checkAuthError";

export const fetchExchangeCurrencies = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.EXCHANGE_CURRENCIES_FETCH_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ["PaymentSystem", "currency"];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get("exchange-currencies", { params: { ...params } });
        checkDictionaries(dispatch, response.data);
        dispatch(
            queryAction(actionTypes.EXCHANGE_CURRENCIES_FETCH_SUCCESS, {
                data: response.data.data,
                meta: response.data.meta,
            })
        );
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.EXCHANGE_CURRENCIES_FETCH_FAILURE));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const fetchPaymentSystem = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_FETCH_BEGIN));
    try {
        const response = await axios.get(`payment-systems/${id}`);
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_FETCH_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_FETCH_FAILURE));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const createPaymentSystem = (params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_CREATE_BEGIN));
    try {
        const response = await axios.post(`payment-systems`, { ...params });
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_CREATE_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_CREATE_FAILURE, error));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const updatePaymentSystem = (id, params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_UPDATE_BEGIN, id));
    try {
        const response = await axios.put(`payment-systems/${id}`, { ...params });
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_UPDATE_SUCCESS, { id: id, data: response.data.data }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_UPDATE_FAILURE, { id, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const deletePaymentSystem = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_DELETE_BEGIN, id));
    try {
        const response = await axios.delete(`payment-systems/${id}`);
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_DELETE_SUCCESS, { id: id, data: response.data.data }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PAYMENT_SYSTEM_DELETE_FAILURE, { id, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};
