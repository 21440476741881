import { applyDictionaries } from './actions/dictionaries';
import { SentryError } from '../helpers/Sentry';

export const getTS = () => Math.floor(new Date().getTime() / 1000);

export const queryAction = (type, payload) => {
    if ( !payload ) {
        return { type: type };
    }
    return {
        type: type,
        payload: payload ? payload : {}
    };
}

export const checkResponseError = error => {
    // console.log('error',error, error.response);

    let response = error;
    if (
        'undefined' !== typeof error.response
        && 'undefined' !== typeof error.response.status
    ) {
        // const errorData = error.response.data;
        const errorData = error.response;
        if (errorData.status !== 411) {
            const err = {
                status: errorData.status,
                message: preparedErrors(errorData.messages),
            }
            SentryError(err);
        }

        switch (error.response.status) {
            case 409:
            case 422:
                response = { success: false, status: error.response.status, messages: error.response.data.messages };
                break;

            case 404:
                response = { success: false, status: 404, messages: error.response.data.messages };
                break;

            case 413:
                response = { success: false, status: 413, messages: 'The amount of data being transferred is too large' };
                break;

            case 401:
                response = { success: false, status: 401 };
                break;

            case 500:
                response = { success: false, status: 500, messages: error.response.data.messages };
                break;

            case 503:
                response = { success: false, status: 503, messages: error.response.data.messages };
                break;

            default:
                //response = error;
                response = error.response.data;
                break;
        }
    }
    return response;
};

export const checkDictionaries = (dispatch, data) => {
    if ('undefined' !== typeof data.meta && data.meta && 'undefined' !== typeof data.meta.dictionaries) {
        applyDictionaries(dispatch, data.meta.dictionaries);
    }
};

export const requestAssignDictionaries = (dicts, list, params) => {
    const { dictionariesList } = dicts;
    let toAssign = [];
    if ('undefined' === typeof params) {
        params = {};
    }

    list.forEach(item => {
        if ('undefined' !== typeof dictionariesList[item]) {
            const storeName = dictionariesList[item];
            if (
                'undefined' !== typeof dicts[storeName]
                && !dicts[storeName].length
                && (
                    'undefined' === typeof params.dictionaries
                    || params.dictionaries.indexOf(item) === -1
                )
            ) {
                toAssign.push(item);
            }
        }
    });

    if (toAssign.length) {
        if ('undefined' === typeof params.dictionaries) {
            params.dictionaries = [];
        }
        params.dictionaries = params.dictionaries.concat(toAssign);
        params.dictionaries = params.dictionaries.join('|');
    }

    if (Array.isArray(params.dictionaries)) {
        params.dictionaries = params.dictionaries.join('|');
    }

    return params;
};

export const preparedErrors = (messages) => {
    let message = ''
    if ('string' === typeof messages) {
        message = messages;
    } else if ('undefined' !== typeof messages && Object.prototype.toString.call(messages) === '[object Array]') {
        messages.forEach(item => {
            if ('string' === typeof item) {
                message = item;
            } else if ('object' === typeof item && null !== item) {
                if ('undefined' !== typeof item.fieldName) {
                    message = JSON.stringify(item.message);
                }
            }
        });
    }
    return message
};
