import axios from '../../core/axiosInit';

import { dashboardTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, getTS } from '../helpers';

export const getStatistics = (params, tab, userId, filterForTime) => async (dispatch) => {
    dispatch( queryAction(actionTypes.DASHBOARD_STATISTICS_BEGIN) );
    try {
        const response = await axios.post(`dashboard`, {...params});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(
            actionTypes.DASHBOARD_STATISTICS_SUCCESS,
            {
                data: {
                    data: response.data.data[0][filterForTime],
                    filter: filterForTime,
                    lastRequest: getTS(),
                    dateStart: params.date_from,
                    dateEnd: params.date_to
                },
                tab,
                userId
            }
            )
        );
        return response.data;
    } catch (error) {
        dispatch( queryAction(
            actionTypes.DASHBOARD_STATISTICS_FAILURE,
            {
                error,
                data: {
                    data: [],
                    filter: filterForTime,
                    lastRequest: getTS(),
                    dateStart: params.date_from,
                    dateEnd: params.date_to
                },
                tab,
                userId
            }
            )
        );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

// export const getStatistics = (params, tab, userId, filterForTime) => async (dispatch) => {
//     dispatch( queryAction(actionTypes.DASHBOARD_STATISTICS_TABLE_BEGIN) );
//     try {
//         // const { Dictionaries: dicts } = getState();
//         // const dictionaries = ['device', 'system'];
//         // params = requestAssignDictionaries(dicts, dictionaries, params);

//         const response = await axios.post(`dashboard`, {...params});
//         checkDictionaries(dispatch, response.data);
//         dispatch( queryAction(
//             actionTypes.DASHBOARD_STATISTICS_TABLE_SUCCESS,
//             {
//                 data: {
//                     data: response.data.data[0][filterForTime],
//                     filter: filterForTime,
//                     lastRequest: getTS(),
//                     page: params.current_page,
//                     field: params.sort ? params.sort.field : '',
//                     order: params.sort ? params.sort.order : '',
//                     limit: params.limit,
//                     dateStart: params.date_from,
//                     dateEnd: params.date_to
//                 },
//                 tab,
//                 userId
//             }
//             )
//         );
//         return response.data;
//     } catch (error) {
//         dispatch( queryAction(
//             actionTypes.DASHBOARD_STATISTICS_TABLE_FAILURE,
//             {
//                 error,
//                 data: {
//                     data: [],
//                     filter: filterForTime,
//                     lastRequest: getTS(),
//                     page: params.current_page,
//                     field: params.sort ? params.sort.field : '',
//                     order: params.sort ? params.sort.order : '',
//                     limit: params.limit,
//                     dateStart: params.date_from,
//                     dateEnd: params.date_to
//                 },
//                 tab,
//                 userId
//             }
//             )
//         );
//         dispatch( checkAuthError(error) );
//         return checkResponseError(error);
//     }
// };

// export const getStatisticsChart = (params, tab, userId, filterForTime) => async (dispatch) => {
//     dispatch( queryAction(actionTypes.DASHBOARD_STATISTICS_CHART_BEGIN) );
//     try {
//         const response = await axios.post(`dashboard`, {...params});
//         checkDictionaries(dispatch, response.data);
//         dispatch( queryAction(
//             actionTypes.DASHBOARD_STATISTICS_CHART_SUCCESS,
//             {
//                 data: {
//                     data: response.data.data[0][filterForTime],
//                     filter: filterForTime,
//                     lastRequest: getTS(),
//                     dateStart: params.date_from,
//                     dateEnd: params.date_to
//                 },
//                 tab,
//                 userId
//             }
//             )
//         );
//         return response.data;
//     } catch (error) {
//         dispatch( queryAction(
//             actionTypes.DASHBOARD_STATISTICS_CHART_FAILURE,
//             {
//                 error,
//                 data: {
//                     data: [],
//                     filter: filterForTime,
//                     lastRequest: getTS(),
//                     dateStart: params.date_from,
//                     dateEnd: params.date_to
//                 },
//                 tab,
//                 userId
//             }
//             )
//         );
//         dispatch( checkAuthError(error) );
//         return checkResponseError(error);
//     }
// };

export const getStatisticsComparatives = (params, userId) => async (dispatch) => {
    dispatch( queryAction(actionTypes.DASHBOARD_STATISTICS_COMPARATIVES_BEGIN, { data: [], userId, time: getTS() }) );
    try {
        const response = await axios.post(`dashboard`, {...params});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(
            actionTypes.DASHBOARD_STATISTICS_COMPARATIVES_SUCCESS,
            {data: response.data.data[0].comparatives, userId, time: getTS()})
        );
        return response.data;
    } catch (error) {
        dispatch( queryAction(
            actionTypes.DASHBOARD_STATISTICS_COMPARATIVES_FAILURE, { error, data: [], userId, time: getTS() })
        );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};
