import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './core/i18n';
import { ThroughProvider } from 'react-through';

import App from './App';

export const Root = props => {
    return (
        <Provider store={props.store}>
            <BrowserRouter>
                <I18nextProvider i18n = { i18n }>
                    <ThroughProvider>
                        <App/>
                    </ThroughProvider>
                </I18nextProvider>
            </BrowserRouter>
        </Provider>
    );
};

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root;
