import {actionsTypes as actionTypes, profileTypes as profileActionTypes} from '../types';

export const initialState = {
    data: [],
    loading: false,
    massSharingData: {},
    meta: {}
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ACTIONS_FETCH_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ACTIONS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: [
                    ...payload.data
                ],
                meta: payload.meta
            };
        case actionTypes.ACTIONS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case actionTypes.ACTION_FETCH_SUCCESS:
            return {
                ...state,
                data: state.data.length ? state.data.map( item => {
                    if ( parseInt(item.id) === parseInt(payload.id) ) {
                        return {...item, ...payload};
                    }
                    return item;
                } ) : [{...payload}]
            };

        case actionTypes.ACTION_ADD_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data,
                    payload
                ]
            };

        case actionTypes.ACTION_UPDATE_SUCCESS:
            return {
                ...state,
                data: state.data.map( item => {
                    if ( parseInt(item.id) === parseInt(payload.id) ) {
                        return {...item, ...payload};
                    }
                    return item;
                } )
            };

        case actionTypes.ACTION_DELETE_SUCCESS:
            return {
                ...state,
                data: state.data.filter( item => parseInt(item.id) !== parseInt(payload.id) )
            };

        case actionTypes.ACTION_FEATURE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                data: state.data.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = 1 === parseInt(feature.required) ? 1 :
                                    (parseInt(feature.enabled) - 1 < 0 ? 1 : 0);
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.MASS_SHARING_ACTIONS_FETCH_SUCCESS:
            return {
                ...state,
                massSharingData: {
                    ...payload
                }
            };

        case profileActionTypes.REINCARNATION_SUCCESS: {
            if ( payload.token ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        default:
            return state;
    }
};

export default state;