import React from 'react';
import { toast } from 'react-toastify';
import Translate from './Translate';

export const transToast = {
    success: (message, i18nKey, options) => {
        if ('undefined' === typeof i18nKey) {
            i18nKey = message;
        }
        return toast.success(<Translate i18nKey={i18nKey}>{message}</Translate>, options);
    },

    info: (message, i18nKey, options) => {
        if ('undefined' === typeof i18nKey) {
            i18nKey = message;
        }
        /*if ( 'undefined' === typeof options || 'undefined' === typeof options.autoClose) {
            if ( 'undefined' === typeof options ) {
                options = {};
            }
            options.autoClose = 10000;
        }*/
        return toast.info(<Translate i18nKey={i18nKey}>{message}</Translate>, options);
    },

    warn: (message, i18nKey, options) => {
        if ('undefined' === typeof i18nKey) {
            i18nKey = message;
        }
        return toast.warn(<Translate i18nKey={i18nKey}>{message}</Translate>, options);
    },

    error: (message, i18nKey, options) => {
        if ('undefined' === typeof i18nKey) {
            i18nKey = message;
        }
        return toast.error(<Translate i18nKey={i18nKey}>{message}</Translate>, {autoClose: 10000, ...options});
    },

    isActive: (toastId) => {
        return toast.isActive(toastId);
    },

    dismiss: (toastId) => {
        if ( 'undefined' !== typeof toastId ) {
            return toast.dismiss(toastId);
        }
        return toast.dismiss();
    },

    done: (toastId) => {
        return toast.done(toastId);
    },

    update: (toastId, options, message, i18nKey) => {
        if ('undefined' === typeof i18nKey) {
            i18nKey = message;
        }
        options.render = <Translate i18nKey={i18nKey}>{message}</Translate>;
        toast.update(toastId, options);
    },

    default: (message, i18nKey, options) => {
        return toast(<Translate i18nKey={i18nKey}>{message}</Translate>, options);
    },

    TYPE: {
        INFO: 'info',
        SUCCESS: 'success',
        WARNING: 'warning',
        ERROR: 'error',
        DEFAULT: 'default'
    }
};
export default transToast;