import { rolesTypes as actionTypes } from '../types';

export const sortNumbers = (a, b) => a - b;
export const sortById = (a, b) => a.id - b.id;
// export const sortById = (a, b) => a.id > b.id;
// const sortByName = (a, b) => a.name > b.name;

export const initialState = {
    roles: [],
    permissions: [],
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.PERMISSIONS_FETCH_SUCCESS:
            return {
                ...state,
                permissions: [
                    ...payload.sort(sortById)
                ]
            };

        case actionTypes.ROLES_FETCH_SUCCESS:
            return {
                ...state,
                roles: payload.map(role => {
                    role.permissions = role.permissions.map( item => item.id ).sort(sortNumbers);
                    return {...role, isLeaf: true};
                })
            };

        case actionTypes.ROLES_ROLE_FETCH_SUCCESS:
            return {
                ...state,
                roles: state.roles.map(role => {
                    if ( parseInt(role.id) === parseInt(payload.id) ) {
                        payload.data.permissions = payload.data.permissions.map( item => item.id ).sort(sortNumbers);
                        return {...payload.data, isLeaf: true};
                    }
                    return role;
                })
            };

        case actionTypes.ROLES_ROLE_CREATE_SUCCESS:
            const { roles } = state;
            payload.permissions = payload.permissions ? payload.permissions.map( item => item.id ).sort(sortNumbers) : [];
            roles.push({...payload, isLeaf: true});
            return {
                ...state,
                roles
            };

        case actionTypes.ROLES_ROLE_DELETE_SUCCESS:
            return {
                ...state,
                roles: state.roles.filter(role => parseInt(role.id) !== parseInt(payload.id))
            };

        case actionTypes.ROLES_ROLE_UPDATE_SUCCESS:
            return {
                ...state,
                roles: state.roles.map(role => {
                    if ( parseInt(role.id) === parseInt(payload.id) ) {
                        payload.data.permissions = payload.data.permissions.map( item => item.id ).sort(sortNumbers);
                        return {...payload.data, isLeaf: true};
                    }
                    return role;
                })
            };

        default:
            return state;
    }
};

export default state;