import { createSelector } from 'reselect';

const isAuthSelector = state => state.Profile.isAuth;
const isTreeSelector = state => state.Profile.isTree;
const userDataSelector = state => state.Profile.userData;
const userDataObjectSelector = state => JSON.stringify({userData: state.Profile.userData});

export const selectors = {
    isAuthSelector,
    isTreeSelector,
    userDataSelector
};

export const isAuth = createSelector(
    isAuthSelector,
    isAuth => isAuth || false
);

export const getIsAuth = createSelector(
    [
        state => state.Profile.isAuth || false,
        state => state.Common.isServiceAvailable,
        state => state.Common.unavailableTimeout,
    ],
    (isAuth, isServiceAvailable, unavailableTimeout) => ({
        isAuth: isAuth,
        isServiceAvailable: isServiceAvailable,
        unavailableTimeout: unavailableTimeout
    })
);

export const isTree = createSelector(
    isTreeSelector,
    isTree => isTree
);

export const userData = createSelector(
    userDataSelector,
    userData => userData
);
export const parentData = createSelector(
    userDataSelector,
    userData => userData?.parentData
);
export const userId = createSelector(
    userDataSelector,
    userData => userData.id
);
export const userStatus = createSelector(
    userDataSelector,
    userData => userData?.status_id
);
export const userSettings = createSelector(
    userDataSelector,
    userData => userData?.settings
);
export const postbackUrl = createSelector(
    state => state.Profile.postback_url,
    postback_url => postback_url
);
export const apiData = createSelector(
    userDataSelector,
    userData => userData?.params?.api
);

export const getUserData = createSelector(
    userDataObjectSelector,
    userData => JSON.parse(userData)
);

export const getUserDataWithTree = createSelector(
    [
        state => JSON.stringify(state.Profile.userData),
        state => state.Profile.isTree
    ],
    (userData, isTree) => ({
            userData: JSON.parse(userData),
            isTree: isTree
        })
);

export const needToShowAlertMessageSelector = createSelector(
    state => state.Profile.needToShowAlertMessage,
    needToShowAlertMessage => needToShowAlertMessage
);

export const redirectURL = createSelector(
    state => state.Profile.userData.redirect,
    redirect => redirect || 'dashboard'
);
