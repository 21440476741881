import { commonTypes as actionTypes } from '../types';
import { appVersion } from '../../core/constants';
import { SentryMessage } from "../../helpers/Sentry";

export const initialState = {
    isNeedReloadPage: false,
    reloadToStartPage: false,
    isEditJsonTemplate: true,
    feedTemplateConfig: {},
    isFormNotSaved: false,
    isServiceAvailable: true,
    unavailableTimeout: null,
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.RELOAD_PAGE:
            return {
                ...state,
                isNeedReloadPage: payload.isNeedToReloadPage,
                reloadToStartPage: 'undefined' !== payload.reloadToStartPage ? payload.reloadToStartPage : state.reloadToStartPage
            };

        case actionTypes.FEED_TEMPLATE_EDIT_JSON:
            return {
                ...state,
                isEditJsonTemplate: payload.isEditJsonTemplate
            };

        case actionTypes.FEED_TEMPLATE_UPDATE_CODE:
            return {
                ...state,
                feedTemplateConfig: payload.config
            };

        case actionTypes.CHECK_SAVED_FORM:
            return {
                ...state,
                isFormNotSaved: payload.value,
            };

        case actionTypes.GET_APP_VERSION_SUCCESS:
            let reloadCounter = 0;
            if ( payload && appVersion && payload !== appVersion ) {
                if (window.sessionStorage) {
                    let reloadCounterSession = sessionStorage.getItem('reloadCounter');
                    if (!reloadCounterSession) {
                        reloadCounterSession = 0;
                    }
                    reloadCounter = parseInt(reloadCounterSession) + 1;
                    sessionStorage.setItem('reloadCounter', reloadCounter.toString());
                }
            } else {
                if ( window.sessionStorage ) {
                    if ( sessionStorage.getItem('reloadCounter') ) {
                        sessionStorage.removeItem('reloadCounter');
                    }
                    if ( sessionStorage.getItem('reloadMessageSent') ) {
                        sessionStorage.removeItem('reloadMessageSent');
                    }
                }
            }
            // console.log('appVersion, loadedAppVersion, reloadCounter', appVersion, payload, reloadCounter);
            if ( reloadCounter > 2 ) {
                if ( !sessionStorage.getItem('reloadMessageSent') ) {
                    SentryMessage(`[LoadedVersionError] Reloading due to loaded versions dismatch, appVersion=${appVersion}, loadedAppVersion=${payload}, reloadCounter=${reloadCounter}`);
                    sessionStorage.setItem('reloadMessageSent', '1');
                }
            }
            if ( payload && appVersion && payload !== appVersion && reloadCounter < 3) {
                return {
                    ...state,
                    isNeedReloadPage: true
                };
            }
            return state;

        case actionTypes.SERVICE_UNAVAILABLE:
            return {
                ...state,
                isServiceAvailable: false,
                unavailableTimeout: payload && payload > 0 ? payload : 0
            };

        case actionTypes.SERVICE_AVAILABLE:
            return {
                ...state,
                isServiceAvailable: true,
                unavailableTimeout: null
            };

        case actionTypes.SERVICE_UNAVAILABLE_TIMEOUT:
            return {
                ...state,
                unavailableTimeout: payload && payload > 0 ? payload : 0
            };

        default:
            return state;
    }
};

export default state;