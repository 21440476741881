import { createSelector } from 'reselect';
import { countries, creativeTypes, pricingTypesCpcCpm } from "./dictionaries";

export const pricingInfoData = createSelector(state => state.PricingInfo.pricingInfoData, pricingInfoData => pricingInfoData);
export const pricingInfoList = createSelector(state => state.PricingInfo.pricingInfoData.data, data => data);
export const pricingInfoTotal = createSelector(state => state.PricingInfo.pricingInfoData.meta?.pagination?.total, total => total || 0);
export const pricingInfoCurrentPage = createSelector(state => state.PricingInfo.pricingInfoData.meta?.pagination?.current_page, current_page => current_page || 1);
export const loading = createSelector(state => state.PricingInfo.loading, loading => loading);
export const pricingUserInfoData = createSelector(state => state.PricingInfo.pricingUserInfoData, pricingUserInfoData => pricingUserInfoData);
export const pricingUserInfoList = createSelector(state => state.PricingInfo.pricingUserInfoData.data, data => data);
export const pricingUserInfoTotal = createSelector(state => state.PricingInfo.pricingUserInfoData.meta?.pagination?.total, total => total || 0);
export const pricingUserInfoCurrentPage = createSelector(state => state.PricingInfo.pricingUserInfoData.meta?.pagination?.current_page, current_page => current_page || 1);

export const dictionaries = createSelector(
    [
        countries,
        creativeTypes,
        pricingTypesCpcCpm
    ],
    (countries, creativeTypes, pricingTypes) => ({
        countries,
        creativeTypes,
        pricingTypes: pricingTypes
    })
);