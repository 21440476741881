export const CHANNELS_FETCH_BEGIN = 'Channels/FetchListBegin';
export const CHANNELS_FETCH_SUCCESS = 'Channels/FetchListSuccess';
export const CHANNELS_FETCH_FAILURE = 'Channels/FetchListFailure';

export const CHANNEL_FETCH_BEGIN = 'Channels/FetchChannelBegin';
export const CHANNEL_FETCH_SUCCESS = 'Channels/FetchChannelSuccess';
export const CHANNEL_FETCH_FAILURE = 'Channels/FetchChannelFailure';

export const CHANNEL_ADD_BEGIN = 'Channels/AddChannelBegin';
export const CHANNEL_ADD_SUCCESS = 'Channels/AddChannelSuccess';
export const CHANNEL_ADD_FAILURE = 'Channels/AddChannelFailure';

export const CHANNEL_UPDATE_BEGIN = 'Channels/UpdateChannelBegin';
export const CHANNEL_UPDATE_SUCCESS = 'Channels/UpdateChannelSuccess';
export const CHANNEL_UPDATE_FAILURE = 'Channels/UpdateChannelFailure';

export const CHANNEL_DELETE_BEGIN = 'Channels/DeleteChannelBegin';
export const CHANNEL_DELETE_SUCCESS = 'Channels/DeleteChannelSuccess';
export const CHANNEL_DELETE_FAILURE = 'Channels/DeleteChannelFailure';

export const CHANNEL_RESTORE_BEGIN = 'Channels/RestoreChannelBegin';
export const CHANNEL_RESTORE_SUCCESS = 'Channels/RestoreChannelSuccess';
export const CHANNEL_RESTORE_FAILURE = 'Channels/RestoreChannelFailure';

export const CHANNEL_PLACEMENTS_FETCH_BEGIN = 'Channels/FetchPlacementsBegin';
export const CHANNEL_PLACEMENTS_FETCH_SUCCESS = 'Channels/FetchPlacementsSuccess';
export const CHANNEL_PLACEMENTS_FETCH_FAILURE = 'Channels/FetchPlacementsFailure';

export const CHANNEL_PLACEMENT_FETCH_BEGIN = 'Channels/FetchPlacementBegin';
export const CHANNEL_PLACEMENT_FETCH_SUCCESS = 'Channels/FetchPlacementSuccess';
export const CHANNEL_PLACEMENT_FETCH_FAILURE = 'Channels/FetchPlacementFailure';

export const CHANNEL_PLACEMENT_ADD_BEGIN = 'Channels/AddPlacementBegin';
export const CHANNEL_PLACEMENT_ADD_SUCCESS = 'Channels/AddPlacementSuccess';
export const CHANNEL_PLACEMENT_ADD_FAILURE = 'Channels/AddPlacementFailure';

export const CHANNEL_PLACEMENT_UPDATE_BEGIN = 'Channels/UpdatePlacementBegin';
export const CHANNEL_PLACEMENT_UPDATE_SUCCESS = 'Channels/UpdatePlacementSuccess';
export const CHANNEL_PLACEMENT_UPDATE_FAILURE = 'Channels/UpdatePlacementFailure';

export const CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_BEGIN = 'Channels/ChangePlacementFeatureStatusBegin';
export const CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_SUCCESS = 'Channels/ChangePlacementFeatureStatusSuccess';
export const CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_FAILURE = 'Channels/ChangePlacementFeatureStatusFailure';

export const CHANNEL_FEATURE_STATUS_CHANGE_BEGIN = 'Channels/ChangeFeatureStatusBegin';
export const CHANNEL_FEATURE_STATUS_CHANGE_SUCCESS = 'Channels/ChangeFeatureStatusSuccess';
export const CHANNEL_FEATURE_STATUS_CHANGE_FAILURE = 'Channels/ChangeFeatureStatusFailure';

export const CHANNEL_ACTIONS_PLACEMENT_BEGIN = 'Channels/ActionsPlacementBegin';
export const CHANNEL_ACTIONS_PLACEMENT_SUCCESS = 'Channels/ActionsPlacementSuccess';
export const CHANNEL_ACTIONS_PLACEMENT_FAILURE = 'Channels/ActionsPlacementFailure';

export const CHANNEL_STATUS_UPDATE_BEGIN = 'Channels/UpdateStatusChannelBegin';
export const CHANNEL_STATUS_UPDATE_SUCCESS = 'Channels/UpdateStatusChannelSuccess';
export const CHANNEL_STATUS_UPDATE_FAILURE = 'Channels/UpdateStatusChannelFailure';

export const CHANNEL_COPY_BEGIN = 'Channels/CopyBegin';
export const CHANNEL_COPY_SUCCESS = 'Channels/CopySuccess';
export const CHANNEL_COPY_FAILURE = 'Channels/CopyFailure';

export const CHANNEL_PLACEMENT_COPY_BEGIN = 'Channels/CopyPlacementBegin';
export const CHANNEL_PLACEMENT_COPY_SUCCESS = 'Channels/CopyPlacementSuccess';
export const CHANNEL_PLACEMENT_COPY_FAILURE = 'Channels/CopyPlacementFailure';

export const CHANNEL_CHANGE_PARAMS = 'Channels/ChangeParams';
