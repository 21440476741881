import { commonTypes as actionTypes } from '../types';
import { checkResponseError, queryAction } from '../helpers';
import axios from "../../core/axiosInit";
import checkAuthError from "./checkAuthError";

export const onReloadPage = (value) => dispatch => {
    dispatch(queryAction(actionTypes.RELOAD_PAGE, value));
};

export const onEditJson = (value) => dispatch => {
    dispatch(queryAction(actionTypes.FEED_TEMPLATE_EDIT_JSON, value))
};

export const onChangeFeedCode = (code) => dispatch => {
    dispatch(queryAction(actionTypes.FEED_TEMPLATE_UPDATE_CODE, code))
};

export const onCheckSavedForm = (value) => dispatch => {
    dispatch(queryAction(actionTypes.CHECK_SAVED_FORM, value))
};

export const getAppVersion = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.GET_APP_VERSION_BEGIN) );
    try {
        const response = await axios.get(`app-current-version`);

        dispatch( queryAction(actionTypes.GET_APP_VERSION_SUCCESS, response.data.data.version_for_reload) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.GET_APP_VERSION_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const setServiceUnavailable = (value) => dispatch => {
    dispatch(queryAction(actionTypes.SERVICE_UNAVAILABLE, value))
};

export const setServiceAvailable = () => dispatch => {
    dispatch(queryAction(actionTypes.SERVICE_AVAILABLE))
};

export const setServiceTimeout = (value) => dispatch => {
    dispatch(queryAction(actionTypes.SERVICE_UNAVAILABLE_TIMEOUT, value))
};