import axios from '../../core/axiosInit';
import { rolesTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';

export const fetchRoles = (params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ROLES_FETCH_BEGIN) );
    try {

        const response = await axios.get(`roles`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.ROLES_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ROLES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchRole = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ROLES_ROLE_FETCH_BEGIN) );
    try {

        const response = await axios.get(`roles/${id}`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.ROLES_ROLE_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ROLES_ROLE_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const createRole = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.ROLES_ROLE_CREATE_BEGIN) );
    try {

        const response = await axios.post(`roles`, {...params});

        dispatch( queryAction(actionTypes.ROLES_ROLE_CREATE_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ROLES_ROLE_CREATE_FAILURE, { error, params }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateRole = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ROLES_ROLE_UPDATE_BEGIN, id) );
    try {

        const response = await axios.put(`roles/${id}`, {...params});

        dispatch( queryAction(actionTypes.ROLES_ROLE_UPDATE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ROLES_ROLE_UPDATE_FAILURE, { id, error, params }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteRole = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ROLES_ROLE_DELETE_BEGIN, id) );
    try {

        const response = await axios.delete(`roles/${id}`);

        dispatch( queryAction(actionTypes.ROLES_ROLE_DELETE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ROLES_ROLE_DELETE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchPermissions = (params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.PERMISSIONS_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['permission_template'];
        params = requestAssignDictionaries(dicts, dictionaries, params);
        const response = await axios.get(`permissions`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.PERMISSIONS_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PERMISSIONS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};