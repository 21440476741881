import {createSelector, defaultMemoize} from 'reselect';

export const managers = createSelector(state => state.Users.managers, managers => managers);
export const usersData = createSelector(state => state.Users.usersData, usersData => usersData);
export const list = createSelector(state => state.Users.usersData.data, data => data);
export const currentPage = createSelector(state => state.Users.usersData.meta?.pagination?.current_page, current_page => current_page || 1);
export const total = createSelector(state => state.Users.usersData.meta?.pagination?.total, total => total || 0);
export const loading = createSelector(state => state.Users.loading, loading => loading);
export const allUsers = createSelector(state => state.Users.all_users, all_users => all_users);
export const userInfo = defaultMemoize(
    userId => createSelector(state => state.Users.viewedUsers, viewedUsers => viewedUsers.find(item => parseInt(item.id) === parseInt(userId)) || null)
);
export const referralTrafficData = defaultMemoize(
    userId => createSelector(
        state => state.Users.referralTraffic,
            referralTraffic => referralTraffic.find(item => parseInt(item.id) === parseInt(userId)) || {}
    )
);
export const referralTrafficLoading = createSelector(state => state.Users.referralTrafficLoading, referralTrafficLoading => referralTrafficLoading);

export const dictionariesForUsers = createSelector(
    [
        state => state.Users.managers,
        state => state.Dictionaries.roles,
        state => state.Dictionaries.statuses,
    ],
    (managers, roles, statuses) => ({
        statuses: statuses.filter(el => el.scope.includes('user')),
        roles,
        managers
    })
);