import * as Sentry from '@sentry/react';

export const SentryConfigureScope = (data) => {
    Sentry.configureScope((scope) => {
        scope.setUser({data});
    });
}

export const SentryError = (error) => {
    Sentry.captureException(error);
};

export const SentryMessage = (message) => {
    Sentry.captureMessage(message);
};