import { wsTypes as actionTypes } from "../types";

export const initialState = { connected: false };

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.WS_CONNECTED:
            return { ...state, connected: true };
        case actionTypes.WS_DISCONNECTED:
            return { ...state, connected: false };
        default:
            return state;
    }
};

export default state;