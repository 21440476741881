export const PRICING_INFO_FETCH_DATA_BEGIN = 'Moderation/FetchPricingInfoBegin';
export const PRICING_INFO_FETCH_DATA_SUCCESS = 'Moderation/FetchPricingInfoSuccess';
export const PRICING_INFO_FETCH_DATA_FAILURE = 'Moderation/FetchPricingInfoFailure';

export const UPDATE_PRICING_INFO_DATA_BEGIN = 'Moderation/UpdatePricingInfoBegin';
export const UPDATE_PRICING_INFO_DATA_SUCCESS = 'Moderation/UpdatePricingInfoSuccess';
export const UPDATE_PRICING_INFO_DATA_FAILURE = 'Moderation/UpdatePricingInfoFailure';

export const PRICING_USER_INFO_FETCH_DATA_BEGIN = 'Moderation/FetchPricingUserInfoBegin';
export const PRICING_USER_INFO_FETCH_DATA_SUCCESS = 'Moderation/FetchPricingUserInfoSuccess';
export const PRICING_USER_INFO_FETCH_DATA_FAILURE = 'Moderation/FetchPricingUserInfoFailure';