import {
    treesTypes as actionTypes,
    channelsTypes as actionTypesChannels,
    projectsTypes as actionTypesProjects,
    campaignsTypes as actionTypesCampaigns,
    profileTypes as actionTypesProfiles
} from '../types';
import { sortByKey, arrayContainSomeOfArray } from "../../helpers/arrayHelpers";
import { assetTypesIDs } from '../../core/constants';
import { deepCopyObject } from "../../helpers/objectHelpers";
import moment from "moment";

export const initialState = {
    channels: [],
    channelsLoaded: false,
    channelsUsers: [],
    channelsFilters: {},
    channelsFiltersSelected: [],
    channelsFiltered: [],
    projects: null,
    projectsLoaded: false,
    projectsUsers: [],
    projectsFilters: {},
    projectsFiltersSelected: [],
    projectsFiltered: [],
    assetIndex: []
};

const applyFiltersOnTree = ( assetIndex, data, filters, treeType ) => {
    data = deepCopyObject(data);
    assetIndex = deepCopyObject(assetIndex);
    let assetTypes = [assetTypesIDs.channel, assetTypesIDs.placement];
    if ( treeType === 'projects' ) {
        assetTypes = [assetTypesIDs.project, assetTypesIDs.campaign, assetTypesIDs.creative];
    }
    let isUsedFilters = false;

    const between = (x, min, max) => x >= min && x <= max;

    // filter by asset type
    assetIndex = assetIndex.filter(item => assetTypes.includes(item.asset_type_id));

    if ( assetIndex.length ) {
        // filter by name & id
        /*if ( filters.search ) {
            // TODO uncomment when name will be added
            // assetIndex = assetIndex.filter(item => item.asset_id.toString().indexOf(filters.search) !== -1 || item.name.indexOf(filters.search) !== -1);
            assetIndex = assetIndex.filter(item => item.asset_id.toString().indexOf(filters.search) !== -1);
            isUsedFilters = true;
        }*/

        // filter by creative type
        if (filters.creative_type && Array.isArray(filters.creative_type) && filters.creative_type.length) {
            filters.creative_type = filters.creative_type.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.creative_type.indexOf(item.asset_sub_type_id) !== -1);
            isUsedFilters = true;
        }

        // filter by placement type
        if (filters.placement_type && Array.isArray(filters.placement_type) && filters.placement_type.length) {
            filters.placement_type = filters.placement_type.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.placement_type.indexOf(item.asset_sub_type_id) !== -1);
            isUsedFilters = true;
        }

        // filter by user
        if (filters.user && Array.isArray(filters.user) && filters.user.length) {
            filters.user = filters.user.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.user.indexOf(item.user_id) !== -1);
            isUsedFilters = true;
        }

        // filter by traffic_format
        if (filters.traffic_format && Array.isArray(filters.traffic_format) && filters.traffic_format.length) {
            filters.traffic_format = filters.traffic_format.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.traffic_format.indexOf(item.traffic_format_id) !== -1);
            isUsedFilters = true;
        }

        // filter by pricing_types
        if (filters.pricing_type && Array.isArray(filters.pricing_type) && filters.pricing_type.length) {
            filters.pricing_type = filters.pricing_type.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.pricing_type.indexOf(item.pricing_type_id) !== -1);
            isUsedFilters = true;
        }

        // filter by manager
        if (filters.manager && Array.isArray(filters.manager) && filters.manager.length) {
            filters.manager = filters.manager.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => arrayContainSomeOfArray(item.manager_user_id, filters.manager));
            isUsedFilters = true;
        }

        // filter by priority_type
        if (filters.priority_type && Array.isArray(filters.priority_type) && filters.priority_type.length) {
            filters.priority_type = filters.priority_type.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.priority_type.indexOf(item.priority_id) !== -1);
            isUsedFilters = true;
        }

        // filter by asset_tag
        if (filters.asset_tag && Array.isArray(filters.asset_tag) && filters.asset_tag.length) {
            filters.asset_tag = filters.asset_tag.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => arrayContainSomeOfArray(item.tag_id, filters.asset_tag));
            isUsedFilters = true;
        }

        // filter by category
        if (filters.category && Array.isArray(filters.category) && filters.category.length) {
            filters.category = filters.category.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => arrayContainSomeOfArray(item.category_id, filters.category));
            isUsedFilters = true;
        }

        // filter by status
        if (filters.status && Array.isArray(filters.status) && filters.status.length) {
            filters.status = filters.status.map(item => parseInt(item));
            assetIndex = assetIndex.filter(item => filters.status.indexOf(item.status) !== -1);
            isUsedFilters = true;
        }

        // filter by price
        if (filters.price_from || filters.price_to) {
            if (filters.price_from && filters.price_to) {
                assetIndex = assetIndex.filter(item => between(item.price_min, filters.price_from, filters.price_to)
                    || between(item.price_max, filters.price_from, filters.price_to));
            } else if (filters.price_from) {
                assetIndex = assetIndex.filter(item => filters.price_from <= item.price_max);
            } else if (filters.price_to) {
                assetIndex = assetIndex.filter(item => filters.price_to >= item.price_max);
            }
            isUsedFilters = true;
        }

        // filter by price CPM
        if (filters.price_cpm_from || filters.price_cpm_to) {
            if (filters.price_cpm_from && filters.price_cpm_to) {
                assetIndex = assetIndex.filter(item => between(item.total_cpm, filters.price_cpm_from, filters.price_cpm_to));
            } else if (filters.price_cpm_from) {
                assetIndex = assetIndex.filter(item => filters.price_cpm_from <= item.total_cpm);
            } else if (filters.price_cpm_to) {
                assetIndex = assetIndex.filter(item => filters.price_cpm_to >= item.total_cpm);
            }
            isUsedFilters = true;
        }

        // filter by created_at
        if (filters.created_at && (filters.created_at.startDate || filters.created_at.endDate)) {
            const startDate = filters?.created_at?.startDate || null;
            const endDate = filters?.created_at?.endDate || null;
            if (startDate && endDate) {
                assetIndex = assetIndex.filter(item => moment(item.created_at).isSameOrAfter(startDate)
                    && moment(item.created_at).isSameOrBefore(endDate));
            } else if (startDate) {
                assetIndex = assetIndex.filter(item => moment(item.created_at).isSameOrAfter(startDate));
            } else if (endDate) {
                assetIndex = assetIndex.filter(item => moment(item.created_at).isSameOrBefore(endDate));
            }
            isUsedFilters = true;
        }

        // filter by edited_at
        if (filters.edited_at && (filters.edited_at.startDate || filters.edited_at.endDate)) {
            const startDate = filters?.edited_at?.startDate || null;
            const endDate = filters?.edited_at?.endDate || null;
            if (startDate && endDate) {
                assetIndex = assetIndex.filter(item => moment(item.edited_at).isSameOrAfter(startDate)
                    && moment(item.edited_at).isSameOrBefore(endDate));
            } else if (startDate) {
                assetIndex = assetIndex.filter(item => moment(item.edited_at).isSameOrAfter(startDate));
            } else if (endDate) {
                assetIndex = assetIndex.filter(item => moment(item.edited_at).isSameOrBefore(endDate));
            }
            isUsedFilters = true;
        }
    }

    // filtering data
    if (isUsedFilters) {
        // divide IDs by asset types
        const assetsIDsByType = {};
        assetIndex.forEach(item => {
            if ('undefined' === typeof assetsIDsByType[item.asset_type_id.toString()]) {
                assetsIDsByType[item.asset_type_id.toString()] = [];
            }
            assetsIDsByType[item.asset_type_id.toString()].push(item.asset_id.toString());
        });

        data = data.filter(lev1el => {
            return (
                    assetsIDsByType[assetTypes[0].toString()]
                    && assetsIDsByType[assetTypes[0].toString()].indexOf(lev1el.id.toString()) !== -1
                )
                || (assetTypes[1]
                    && (lev1el.children = (lev1el.children || []).filter(lev2el => (
                        (
                            assetsIDsByType[assetTypes[1].toString()]
                            && assetsIDsByType[assetTypes[1].toString()].indexOf(lev2el.id.toString()) !== -1
                        )
                        || (assetTypes[2]
                            && (lev2el.children = (lev2el.children || []).filter(lev3el => (
                                (
                                    assetsIDsByType[assetTypes[2].toString()]
                                    && assetsIDsByType[assetTypes[2].toString()].indexOf(lev3el.id.toString()) !== -1
                                )
                            ))).length
                        )
                    ))).length
                )
        });
    }

    // TODO remove when asset index will work
    // filter by creative type
    if ( filters.creative_type && Array.isArray(filters.creative_type) && filters.creative_type.length ) {
        filters.creative_type = filters.creative_type.map(item => parseInt(item));
        data = data.filter(el => {
            return (el.children = (el.children || []).filter(
                    item => (item.children = (item.children || []).filter(
                            creat => filters.creative_type.indexOf(creat.creative_type_id) !== -1
                        )).length
                )).length;
        });
    }

    // TODO remove when asset index will work
    // filter by placement type
    if ( filters.placement_type && Array.isArray(filters.placement_type) && filters.placement_type.length ) {
        filters.placement_type = filters.placement_type.map(item => parseInt(item));
        data = data.filter(el => {
            return (el.children = (el.children || []).filter(
                item => filters.placement_type.indexOf(item.placement_type_id) !== -1
            )).length;
        });
    }

    // TODO remove when asset index will work
    // filter by user
    if ( filters.user && Array.isArray(filters.user) && filters.user.length ) {
        filters.user = filters.user.map(item => parseInt(item));
        data = data.filter(item => filters.user.indexOf(item.user_id) !== -1);
    }

    // TODO remove when asset index will work
    // filter by asset_tag
    if ( filters.asset_tag && Array.isArray(filters.asset_tag) && filters.asset_tag.length ) {
        filters.asset_tag = filters.asset_tag.map(item => parseInt(item));
        data = data.filter(el => {
            return (
                arrayContainSomeOfArray(el.asset_tag_ids, filters.asset_tag)
                )
                || (el.children = (el.children || []).filter(
                    item => (
                            arrayContainSomeOfArray(item.asset_tag_ids, filters.asset_tag)
                        )
                        || (item.children = (item.children || []).filter(
                            creat => (
                                arrayContainSomeOfArray(creat.asset_tag_ids, filters.asset_tag)
                            )
                        )).length
                )).length;
        });
    }

    // TODO remove when asset index will work
    // filter by category
    if ( filters.category && Array.isArray(filters.category) && filters.category.length ) {
        filters.category = filters.category.map(item => parseInt(item));
        data = data.filter(el => {
            return (
                    arrayContainSomeOfArray(el.asset_category_ids, filters.category)
                )
                || (el.children = (el.children || []).filter(
                    item => (
                            arrayContainSomeOfArray(item.asset_category_ids, filters.category)
                        )
                        || (item.children = (item.children || []).filter(
                            creat => (
                                arrayContainSomeOfArray(creat.asset_category_ids, filters.category)
                            )
                        )).length
                )).length;
        });
    }

    // TODO remove when asset index will work
    // filter by status
    if ( filters.status && Array.isArray(filters.status) && filters.status.length ) {
        filters.status = filters.status.map(item => parseInt(item));
        data = data.filter(el => {
            return (
                    el.status && filters.status.indexOf(el.status) !== -1
                )
                || (el.children = (el.children || []).filter(
                    item => (
                            item.status && filters.status.indexOf(item.status) !== -1
                        )
                        || (item.children = (item.children || []).filter(
                            creat => (
                                creat.status && filters.status.indexOf(creat.status) !== -1
                            )
                        )).length
                )).length;
        });
    }

    // TODO remove when asset index will work
    if ( filters.search ) {
        data = data.filter(el => {
            return (
                    el.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
                    || el.id.toString().indexOf(filters.search) !== -1
                )
                || (el.children = (el.children || []).filter(
                    item => (
                            item.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
                            || item.id.toString().indexOf(filters.search) !== -1
                        )
                        || (item.children = (item.children || []).filter(
                            creat => (
                                creat.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
                                || creat.id.toString().indexOf(filters.search) !== -1
                            )
                        )).length
                )).length;
        });
    }

    if ( filters.trafficStatus ) {
        if ( parseInt(filters.trafficStatus) === 1 ) {
            data = data.filter(el => {
                return !!el.has_traffic
                    || (el.children = (el.children || []).filter(
                        item => !!item.has_traffic
                            || (item.children = (item.children || []).filter(
                                creat => !!creat.has_traffic
                            )).length
                    )).length;
            });
        }
        if ( parseInt(filters.trafficStatus) === 2 ) {
            data = data.filter(el => {
                return !el.has_traffic
                    || (el.children = (el.children || []).filter(
                        item => !item.has_traffic
                            || (item.children = (item.children || []).filter(
                                creat => !creat.has_traffic
                            )).length
                    )).length;
            });
        }
    }

    if ( filters.activeStatus ) {
        if ( parseInt(filters.activeStatus) === 1 ) {
            data = data.filter(el => {
                return !!el.active
                    || (el.children = (el.children || []).filter(
                        item => !!item.active
                            || (item.children = (item.children || []).filter(
                                creat => !!creat.active
                            )).length
                    )).length;
            });
        }
        if ( parseInt(filters.activeStatus) === 2 ) {
            data = data.filter(el => {
                return !el.active
                    || (el.children = (el.children || []).filter(
                        item => !item.active
                            || (item.children = (item.children || []).filter(
                                creat => !creat.active
                            )).length
                    )).length;
            });
        }
        /*data = data.filter(el => {
            return (
                    el.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
                    || el.id.toString().indexOf(filters.search) !== -1
                )
                || (el.children = (el.children || []).filter(
                    item => (
                            item.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
                            || item.id.toString().indexOf(filters.search) !== -1
                        )
                        || (item.children = (item.children || []).filter(
                            creat => (
                                creat.name.toLowerCase().indexOf(filters.search.toLowerCase()) !== -1
                                || creat.id.toString().indexOf(filters.search) !== -1
                            )
                        )).length
                )).length;
        });*/
    }

    // console.log('data', treeType, data);

    return data;
};

const makeProjectsStructure = data => data.map(proj => {
    let { campaigns, ...projData } = proj;
    if ( campaigns && campaigns.length ) {
        campaigns = campaigns.map(camp => {
            let { creatives, ...campData } = camp;
            if ( creatives && creatives.length ) {
                creatives = creatives.map(creat => ({...creat, isLeaf: true}));
            }
            return {...campData, children: creatives ? [...creatives] : []};
        });
    }
    return {...projData, children: campaigns ? [...campaigns] : []};
});

const makeChannelsStructure = data => data.map(chan => {
    let { placements, ...channelData } = chan;
    if ( placements && placements.length ) {
        placements = placements.map(place => ({...place, isLeaf: true}));
    }
    return {...channelData, children: placements ? [...placements] : []};
});

const makeGroupOperationChanges = ({ payload, channels, projects }) => {
    const actions = payload.data.action;
    const action = Object.keys(actions)[0];

    const switchActions = (action, item) => {
        switch (action) {
            case 'enable':
                item.enabled = 1;
                return {...item};

            case 'disable':
                item.enabled = 0;
                return {...item};

            case 'remove':
                return null;

            default:
                break;
        }
        return item;
    };

    if ( 'undefined' !== typeof actions[action].channel || 'undefined' !== typeof actions[action].placement ) {
        channels = channels.map(item => {
            if ( 'undefined' !== typeof actions[action].placement ) {
                item.children = item.children.map(child => {
                    if ( actions[action].placement.includes('' + child.id) || actions[action].placement.includes(child.id) ) {
                        child = switchActions(action, child);
                    }
                    return child;
                }).filter(child => !!child);
            }
            if ( 'undefined' !== typeof actions[action].channel ) {
                if ( actions[action].channel.includes('' + item.id) || actions[action].channel.includes(item.id) ) {
                    item = switchActions(action, item);
                }
            }
            return item;
        }).filter(item => !!item);
    } else {
        projects = projects.map(item => {
            if (
                'undefined' !== typeof actions[action].creative
                || 'undefined' !== typeof actions[action].campaign
            ) {
                item.children = item.children.map(child => {
                    if ('undefined' !== typeof actions[action].creative) {
                        child.children = child.children.map(creat => {
                            if (actions[action].creative.includes('' + creat.id) || actions[action].creative.includes(creat.id)) {
                                creat = switchActions(action, creat);
                            }
                            return creat;
                        }).filter(creat => !!creat);
                    }
                    if ('undefined' !== typeof actions[action].campaign) {
                        if (actions[action].campaign.includes('' + child.id) || actions[action].campaign.includes(child.id)) {
                            child = switchActions(action, child);
                        }
                    }
                    return child;
                }).filter(child => !!child);
            }
            if ( 'undefined' !== typeof actions[action].project ) {
                if ( actions[action].project.includes('' + item.id) || actions[action].project.includes(item.id) ) {
                    item = switchActions(action, item);
                }
            }
            return item;
        }).filter(item => !!item);
    }
    return [projects, channels];
};

const extractUsersFromTree = treeData => {
    const users = new Map();
    treeData.forEach(data => {
        if ( data.user ) {
            users.set(data.user.id, {...data.user});
        }
    });
    return sortByKey( [...users.values()], 'name');
    // sortByKey([...new Map( treeData.map(data => [data.user_id || data.user.id, {...data.user}] ) ).values()], 'name');
};

const state = (state = initialState, { type, payload }) => {
    switch(type) {
        case actionTypesProfiles.REINCARNATION_SUCCESS:
            return {
                ...state,
                channels: [],
                channelsUsers: [],
                channelsFilters: {},
                channelsFiltersSelected: [],
                channelsFiltered: [],
                projects: null,
                projectsUsers: [],
                projectsFilters: {},
                projectsFiltersSelected: [],
                projectsFiltered: [],
                assetIndex: []
            };

        case actionTypes.TREE_CHANNELS_FETCH_SUCCESS:
            return {
                ...state,
                channels: [
                    ...payload.data
                ]
            };

        case actionTypes.TREE_PLACEMENTS_FETCH_SUCCESS:
            return {
                ...state,
                channels: state.channels.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.channelId) ) {
                        item.children = payload.data.map( child => ({...child, isLeaf: true}) );
                        return {...item};
                    }
                    return item;
                })
            };

        case actionTypes.TREE_PROJECTS_FETCH_SUCCESS:
            return {
                ...state,
                projects: [
                    ...payload.data
                ],
            };

        case actionTypes.TREE_CAMPAIGNS_FETCH_SUCCESS:
            return {
                ...state,
                projects: state.projects.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.projectId) ) {
                        item.children = payload.data.map( child => ({...child, isLeaf: true}) );
                        return {...item};
                    }
                    return item;
                })
            };

        case actionTypes.TREE_CREATIVES_FETCH_SUCCESS:
            return {
                ...state,
                projects: state.projects.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.projectId) ) {
                        item.children = item.children.map(campaign => {
                            if ( parseInt(campaign.id) === parseInt(payload.campaignId) ) {
                                campaign.children = payload.data.map( child => ({...child, isLeaf: true}) );
                                return {...campaign};
                            }
                            return campaign;
                        });
                        return {...item};
                    }
                    return item;
                })
            };

        case actionTypes.TREE_PROJECTS_ALL_FETCH_SUCCESS:
            const projectsOnGetAll = makeProjectsStructure(payload);
            if ( JSON.stringify(projectsOnGetAll) === JSON.stringify(state.projects) ) {
                return state;
            }
            return {
                ...state,
                projects: projectsOnGetAll,
                projectsLoaded: true,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnGetAll, state.projectsFilters, 'projects')],
                projectsUsers: extractUsersFromTree(payload),
            };

        case actionTypes.TREE_PROJECTS_ALL_FETCH_FAILURE:
            return {
                ...state,
                projectsLoaded: true,
            };

        case actionTypes.TREE_CHANNELS_ALL_FETCH_SUCCESS:
            return {
                ...state,
                channels: makeChannelsStructure(payload),
                channelsLoaded: true,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, makeChannelsStructure(payload), state.channelsFilters, 'channels')],
                channelsUsers: extractUsersFromTree(payload)
            };

        case actionTypes.TREE_CHANNELS_ALL_FETCH_FAILURE:
            return {
                ...state,
                channelsLoaded: true,
            };

        case actionTypes.TREE_GROUP_SUCCESS:
            const [ projectsGO, channelsGO ] = makeGroupOperationChanges({
                payload,
                projects: state.projects,
                channels: state.channels
            });

            return {
                ...state,
                channels: channelsGO,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channelsGO, state.channelsFilters, 'channels')],
                channelsUsers: extractUsersFromTree(channelsGO),
                projects: projectsGO,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsGO, state.projectsFilters, 'projects')],
                projectsUsers: extractUsersFromTree(projectsGO),
            };

        case actionTypes.TREE_ITEM_STATUS_UPDATE:
            if ( 'undefined' !== typeof payload.assetData.project_id || 'undefined' !== typeof payload.assetData.campaign_id ) {
                const projectsOnItemStatusUpdate = state.projects.map(item => {
                    if ( 'undefined' !== typeof item.children && item.children.length ) {
                        // channels
                        item.children = item.children.map(campaign => {
                            if ( 'undefined' !== typeof payload.assetData.project_id && parseInt(campaign.id) === parseInt(payload.assetData.id) ) {
                                return {...campaign, status: payload.newStatus};
                            }
                            else if ( 'undefined' !== typeof payload.assetData.campaign_id && parseInt(campaign.id) === parseInt(payload.assetData.campaign_id) ) {
                                //creatives
                                campaign.children = [
                                    campaign.children.map(creative => {
                                        if ( parseInt(creative.id) === parseInt(payload.assetData.id) ) {
                                            return {...creative, status: payload.newStatus};
                                        }
                                        return creative;
                                    })
                                ];
                                return {...campaign};
                            }
                            return campaign;
                        });
                        return {...item};
                    }
                    return item;
                });
                return {
                    ...state,
                    projects: projectsOnItemStatusUpdate,
                    projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnItemStatusUpdate, state.projectsFilters, 'projects')],
                };
            }
            return state;

        case actionTypes.TREE_LOAD_INDEX_SUCCESS:
            return {
                ...state,
                assetIndex: [...payload],
                projectsFiltered: [...applyFiltersOnTree([...payload], state.projects, state.projectsFilters, 'projects')],
                channelsFiltered: [...applyFiltersOnTree([...payload], state.channels, state.channelsFilters, 'channels')],
            };

        // on projects tree filters update
        case actionTypes.TREE_PROJECTS_SET_FILTERS:
            return {
                ...state,
                projectsFilters: {...payload},
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, state.projects, payload, 'projects')],
            };

        case actionTypes.TREE_PROJECTS_SET_FILTERS_SELECTED:
            return {
                ...state,
                projectsFiltersSelected: [...payload],
            };

        case actionTypes.TREE_PROJECTS_UPDATE_FILTERS:
            return {
                ...state,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, state.projects, payload.filters, 'projects')],
                projectsFilters: {...payload.filters},
                projectsFiltersSelected: [...payload.selectedFilters],
            };

        // on channels tree filters update
        case actionTypes.TREE_CHANNELS_SET_FILTERS:
            return {
                ...state,
                channelsFilters: {...payload},
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, state.channels, payload, 'channels')],
            };

        case actionTypes.TREE_CHANNELS_SET_FILTERS_SELECTED:
            return {
                ...state,
                channelsFiltersSelected: [...payload],
            };

        case actionTypes.TREE_CHANNELS_UPDATE_FILTERS:
            return {
                ...state,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, state.channels, payload.filters, 'channels')],
                channelsFilters: {...payload.filters},
                channelsFiltersSelected: [...payload.selectedFilters],
            };

        // update tree data if channel asset changed
        case actionTypesChannels.CHANNEL_UPDATE_SUCCESS:
            const channelsOnUpdate = state.channels.map(item => {
                if ( parseInt(item.id) === parseInt(payload.id) ) {
                    return {...payload.data, children: [...item.children]};
                }
                return item;
            });
            return {
                ...state,
                channels: channelsOnUpdate,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channelsOnUpdate, state.channelsFilters, 'channels')],
            };
        case actionTypesChannels.CHANNEL_ADD_SUCCESS:
            const channelsOnAdd = [
                ...state.channels.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                payload
            ];
            return {
                ...state,
                channels: channelsOnAdd,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channelsOnAdd, state.channelsFilters, 'channels')],
                channelsUsers: extractUsersFromTree(channelsOnAdd)
            };
        case actionTypesChannels.CHANNEL_DELETE_SUCCESS:
            const channels_deleted = state.channels.filter(item => parseInt(item.id) !== parseInt(payload.id));
            return {
                ...state,
                channels: channels_deleted,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channels_deleted, state.channelsFilters, 'channels')],
                channelsUsers: extractUsersFromTree(channels_deleted)
            };

        // update tree data if placement asset changed
        case actionTypesChannels.CHANNEL_PLACEMENT_UPDATE_SUCCESS:
            const channelsOnPlacementUpdate = state.channels.map(item => {
                if ( parseInt(item.id) === parseInt(payload.channel) ) {
                    item.children = item.children.map(child => {
                        if ( parseInt(child.id) === parseInt(payload.id) ) {
                            return {...payload.data, isLeaf: true}
                        }
                        if ( 'undefined' === typeof child.isLeaf ) {
                            return {...child, isLeaf: true};
                        }
                        return child;
                    });
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                channels: channelsOnPlacementUpdate,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channelsOnPlacementUpdate, state.channelsFilters, 'channels')],
            };
        case actionTypesChannels.CHANNEL_PLACEMENT_ADD_SUCCESS:
            const channelsOnPlacementAdd = state.channels.map(item => {
                if ( parseInt(item.id) === parseInt(payload.channel) ) {
                    item.children = [
                        ...(item.children || []).filter(child => parseInt(child.id) !== parseInt(payload.data.id)),
                        {...payload.data, isLeaf: true}
                    ];
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                channels: channelsOnPlacementAdd,
                channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channelsOnPlacementAdd, state.channelsFilters, 'channels')],

            };
        case actionTypesChannels.CHANNEL_ACTIONS_PLACEMENT_SUCCESS:
            switch (payload.action) {
                case 'delete':
                    const channelsPlacementActionDelete = state.channels.map(item => {
                        payload.data.forEach(placement => {
                            if ( parseInt(item.id) === parseInt(placement.channel_id) ) {
                                item.children = item.children.filter(child => parseInt(child.id) !== parseInt(placement.id));
                                return {...item};
                            }
                        });
                        return item;
                    });
                    return {
                        ...state,
                        channels: channelsPlacementActionDelete,
                        channelsFiltered: [...applyFiltersOnTree(state.assetIndex, channelsPlacementActionDelete, state.channelsFilters, 'channels')],
                    };

                default:
                    return state;
            }

        // update tree data if project asset changed
        case actionTypesProjects.PROJECT_UPDATE_SUCCESS:
            const projectsOnUpdate = state.projects.map(item => {
                if ( parseInt(item.id) === parseInt(payload.data.id) ) {
                    return {...payload.data, children: item.children};
                }
                return item;
            });
            return {
                ...state,
                projects: projectsOnUpdate,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnUpdate, state.projectsFilters, 'projects')],
            };
        case actionTypesProjects.PROJECT_DELETE_SUCCESS:
            const projects_delete = state.projects.filter(item => parseInt(item.id) !== parseInt(payload.data.id));
            return {
                ...state,
                projects: projects_delete,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projects_delete, state.projectsFilters, 'projects')],
                projectsUsers: extractUsersFromTree(projects_delete)
            };
        case actionTypesProjects.PROJECT_ADD_SUCCESS:
            const projects_added = [
                ...state.projects.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                payload
            ];
            return {
                ...state,
                projects: projects_added,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projects_added, state.projectsFilters, 'projects')],
                projectsUsers: extractUsersFromTree(projects_added)
            };

        // update tree data if campaign asset changed
        case actionTypesCampaigns.CAMPAIGN_UPDATE_SUCCESS:
            const projectsOnCampaignUpdate = state.projects.map(item => {
                if ( parseInt(item.id) === parseInt(payload.data.project_id) ) {
                    item.children = item.children.map(campaign => {
                        if ( parseInt(campaign.id) === parseInt(payload.data.id) ) {
                            return {...payload.data, children: campaign.children}
                        }
                        return campaign;
                    });
                    return {...item};
                }
                return item;
            })
            return {
                ...state,
                projects: projectsOnCampaignUpdate,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnCampaignUpdate, state.projectsFilters, 'projects')],
            };
        case actionTypesCampaigns.CAMPAIGN_DELETE_SUCCESS:
            const projectsOnCampaignDelete = state.projects.map(item => {
                if ( parseInt(item.id) === parseInt(payload.data.project_id) ) {
                    item.children = item.children.filter(child => parseInt(child.id) !== parseInt(payload.id));
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                projects: projectsOnCampaignDelete,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnCampaignDelete, state.projectsFilters, 'projects')],
            };
        case actionTypesCampaigns.CAMPAIGN_ADD_SUCCESS:
            const projectsOnCampaignAdd = state.projects.map(item => {
                if ( parseInt(item.id) === parseInt(payload.project_id) ) {
                    item.children = [
                        ...(item.children || []).filter(child => parseInt(child.id) !== parseInt(payload.id)),
                        payload
                    ];
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                projects: projectsOnCampaignAdd,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnCampaignAdd, state.projectsFilters, 'projects')],
            };

        // update tree data if creative asset changed
        case actionTypesCampaigns.CAMPAIGN_CREATIVE_UPDATE_SUCCESS:
            const projectsOnCreativeUpdate = state.projects.map(item => {
                if ( 'undefined' !== typeof item.children && item.children.length ) {
                    item.children = item.children.map(campaign => {
                        if ( parseInt(campaign.id) === parseInt(payload.data.campaign_id) ) {
                            campaign.children = campaign.children.map(creative => {
                                if ( parseInt(creative.id) === parseInt(payload.data.id) ) {
                                    return {...payload.data, isLeaf: true}
                                }
                                return creative;
                            });
                            return {...campaign};
                        }
                        return campaign;
                    });
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                projects: projectsOnCreativeUpdate,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnCreativeUpdate, state.projectsFilters, 'projects')],
            };
        case actionTypesCampaigns.CAMPAIGN_CREATIVE_DELETE_SUCCESS:
            const projectsOnCreativeDelete = state.projects.map(item => {
                if ( 'undefined' !== typeof item.children && item.children.length ) {
                    item.children = item.children.map(campaign => {
                        if ( parseInt(campaign.id) === parseInt(payload.data.campaign_id) ) {
                            campaign.children = campaign.children.filter(child => parseInt(child.id) !== parseInt(payload.data.id));
                            return {...campaign};
                        }
                        return campaign;
                    });
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                projects: projectsOnCreativeDelete,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnCreativeDelete, state.projectsFilters, 'projects')],
                projectsUsers: extractUsersFromTree(projectsOnCreativeDelete)
            };
        case actionTypesCampaigns.CAMPAIGN_CREATIVE_ADD_SUCCESS:
            const projectsOnCreativeAdd = state.projects.map(item => {
                if ( 'undefined' !== typeof item.children && item.children.length ) {
                    item.children = item.children.map(campaign => {
                        if ( parseInt(campaign.id) === parseInt(payload.data.campaign_id) ) {
                            campaign.children = [
                                ...(campaign.children || []).filter(child => parseInt(child.id) !== parseInt(payload.data.id)),
                                {...payload.data, isLeaf: true}
                            ];
                            return {...campaign};
                        }
                        return campaign;
                    });
                    return {...item};
                }
                return item;
            });
            return {
                ...state,
                projects: projectsOnCreativeAdd,
                projectsFiltered: [...applyFiltersOnTree(state.assetIndex, projectsOnCreativeAdd, state.projectsFilters, 'projects')],
                projectsUsers: extractUsersFromTree(projectsOnCreativeAdd)
            };

        default:
            return state;
    }
};

export default state;