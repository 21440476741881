import { feedtemplatesTypes as actionTypes } from '../types/index';

export const initialState = {
    list: [],
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.FEEDTEMPLATES_FETCH_SUCCESS:
            return {
                ...state,
                list: payload.map(item => {item.isLeaf = true; return {...item};})
            };

        case actionTypes.FEEDTEMPLATES_CREATE_SUCCESS:
            const { list } = state;
            payload.isLeaf = true;
            list.push(payload);
            return {
                ...state,
                list
            };

        case actionTypes.FEEDTEMPLATES_UPDATE_SUCCESS:
            return {
                ...state,
                list: state.list.map(template => {
                    if ( parseInt(template.id) === parseInt(payload.id) ) {
                        payload.data.isLeaf = true;
                        return payload.data;
                    }
                    return template;
                })
            };

        default:
            return state;
    }
};

export default state;