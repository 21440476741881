import { createSelector, defaultMemoize } from 'reselect';

export const list = createSelector(state => state.Projects.list, list => list);
export const loading = createSelector(state => state.Projects.loading, loading => loading);
export const total = createSelector(state => state.Projects.listMeta?.pagination?.total, total => total);

export const getProjectData = defaultMemoize(
    projectId => createSelector( state => state.Projects.data, data => data.find(item => parseInt(item.id) === parseInt(projectId)) )
);

export const projectsEditDictionaries = createSelector(
    [
        state => state.Dictionaries.assetTags
    ],
    ( assetTags ) => {
        return {
            assetTags: assetTags.filter(el => el.asset_types.includes('project'))
        };
    }
);