import axios from '../../core/axiosInit';
import { billingTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';
import { errorsProcessor } from "../../helpers/errorsProcessor";

export const fetchBilling = () => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CURRENCY_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['currency'];
        const params = requestAssignDictionaries(dicts, dictionaries);
        const response = await axios.get(`billing`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CURRENCY_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CURRENCY_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchTransaction = (params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.TRANSACTION_FETCH_BEGIN) );
    try {
        const response = await axios.get(`billing-transaction`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.TRANSACTION_FETCH_SUCCESS, {
            data: response.data.data,
            meta: response.data.meta
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.TRANSACTION_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return errorsProcessor(checkResponseError(error));
    }
};
