import axios from '../../core/axiosInit';

import { projectsTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import {queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries} from '../helpers';

export const fetchProjects = params => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.PROJECTS_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = [
            "traffic_format", 
            "pricing_type", 
            "priority_type", 
            "asset_tag", 
            "category", 
            "status"
        ];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`projects`, {params: {...params}});
        dispatch( queryAction(actionTypes.PROJECTS_FETCH_SUCCESS, {list: response.data.data, meta: response.data.meta}) );
        checkDictionaries(dispatch, response.data);
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECTS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchProject = (id, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.PROJECT_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        params = requestAssignDictionaries(dicts, params && params.dictionaries ? params.dictionaries : [], params);

        const response = await axios.get(`projects/${id}`, {params: {...params}});
        dispatch( queryAction(actionTypes.PROJECT_FETCH_SUCCESS, response.data.data[0]) );
        checkDictionaries(dispatch, response.data);
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addProject = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_ADD_BEGIN) );
    try {
        const response = await axios.post(`projects`, {...params});
        dispatch( queryAction(actionTypes.PROJECT_ADD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_ADD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateProject = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`projects/${id}`, {...params});
        dispatch( queryAction(actionTypes.PROJECT_UPDATE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_UPDATE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteProject = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_DELETE_BEGIN, id) );
    try {
        const response = await axios.delete(`projects/${id}`);
        dispatch( queryAction(actionTypes.PROJECT_DELETE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_DELETE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const restoreProject = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_RESTORE_BEGIN, id) );
    try {
        const response = await axios.patch(`projects/${id}`);
        dispatch( queryAction(actionTypes.PROJECT_RESTORE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_RESTORE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchProjectCampaigns = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_CAMPAIGNS_FETCH_BEGIN) );
    try {
        const response = await axios.get(`projects/${id}/campaigns`, {params: {...params}});
        dispatch( queryAction(actionTypes.PROJECT_CAMPAIGNS_FETCH_SUCCESS, {
            projectId: id,
            data: response.data.data
        }) );
        checkDictionaries(dispatch, response.data);
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_CAMPAIGNS_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changeProjectFeatureStatus = ({feature_asset_type_id, asset_id, params}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_FEATURE_STATUS_CHANGE_BEGIN, {feature_asset_type_id, asset_id}) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        dispatch( queryAction(actionTypes.PROJECT_FEATURE_STATUS_CHANGE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_FEATURE_STATUS_CHANGE_FAILURE, { feature_asset_type_id, asset_id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const copyProject = (projectId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROJECT_COPY_BEGIN, {projectId, params}) );
    try {
        const response = await axios.post(`projects/${projectId}`, params);
        dispatch( queryAction(actionTypes.PROJECT_COPY_SUCCESS, {
            projectId: projectId,
            data: response.data.data
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROJECT_COPY_FAILURE, { projectId, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};
