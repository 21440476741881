import { createSelector } from 'reselect';
import {currencies} from "./dictionaries";

export const data = createSelector(state => state.Billing.data, data => data);
export const transactions = createSelector(state => state.Billing.transaction, transaction => transaction);
export const loading = createSelector(state => state.Billing.loading, loading => loading);
export const page = createSelector(state => state.Billing.transactionMeta.pagination?.current_page, current_page => current_page || 1);
export const total = createSelector(state => state.Billing.transactionMeta.pagination?.total, total => total || 0);

export const billingDictionaries = createSelector(
    [currencies],
    (currencies) => ({
        currencies
    })
);