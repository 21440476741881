import axios from '../../core/axiosInit';
import { postbackslogTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, requestAssignDictionaries, checkDictionaries } from '../helpers';

export const fetchPostbacksLog = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.POSTBACKSLOG_FETCH_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ["action"];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`postbacks`, { params: { ...params } });
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.POSTBACKSLOG_FETCH_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.POSTBACKSLOG_FETCH_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};