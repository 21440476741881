import { createSelector, defaultMemoize } from 'reselect';
import { STATUS_REPORT_READY } from '../../core/constants';

export const dataSelector = createSelector(state => state.Analytics.data, data => data);
export const reportsSelector = createSelector(state => state.Analytics.reports, reports => reports);
export const intervalSelector = createSelector(state => state.Analytics.interval, interval => interval);
export const progressSelector = createSelector(state => state.Analytics.progress, progress => progress);
export const errorsReportSelector = createSelector(state => state.Analytics.errorsReport, errorsReport => errorsReport);
export const metricsSelector = createSelector(state => state.Analytics.metrics, metrics => metrics);
export const receivedMetricsSelector = createSelector(state => state.Analytics.receivedMetrics, receivedMetrics => receivedMetrics);
export const orderColumnsSelector = createSelector(state => state.Analytics.orderColumns, orderColumns => orderColumns);

export const reportsList = createSelector(
    [
        state => state.Analytics.data,
        state => state.Analytics.deletedReports
    ],
    (data, deletedReports) => [
        {
            name: 'Filter',
            type: 'filter',
            delete: false
        },
        ...data.filter(el => el.report_type === 'report' && !deletedReports.includes(el.id))
            .sort((a, b) => (a.order > b.order) ? 1 : -1)
    ]
);
export const templatesList = createSelector(
    state => state.Analytics.data,
    list => list.filter(el => el.report_type === 'template').sort((a, b) => (a.order > b.order) ? 1 : -1)
);
export const getReportData = defaultMemoize(
    reportId => createSelector(
        state => state.Analytics.reports,
        reports => reports[reportId] ? {
            ...reports[reportId].report,
            statisticsSummary: reports[reportId].summary,
            statisticsRow: reports[reportId].rows,
            isWaitingStatistic: reports[reportId].report_status === STATUS_REPORT_READY
        } : {}
    )
);