import { createSelector } from 'reselect';
import {sharedActions} from "./dictionaries";

export const postbacksLogData = createSelector(state => state.Postbackslog.postbacksLogData, postbacksLogData => postbacksLogData);
export const list = createSelector(state => state.Postbackslog.postbacksLogData.data, data => data);
export const loading = createSelector(state => state.Postbackslog.loading, loading => loading);
export const total = createSelector(state => state.Postbackslog.postbacksLogData.meta?.pagination?.total, total => total);
export const currentPage = createSelector(state => state.Postbackslog.postbacksLogData.meta?.pagination?.current_page, current_page => current_page);
export const isLoadData = createSelector(state => state.Postbackslog.postbacksLogData.isLoadData, isLoadData => isLoadData);
export const dictionaries = createSelector(
    [sharedActions],
    (sharedActions) => ({
        sharedActions
    })
);