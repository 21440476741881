import Centrifuge from "centrifuge";
import { wsUrl } from "../constants";
import { wsActions, analyticsActions } from "../../redux/actions";
import { wsTypes as actionTypes } from "../../redux/types";

import { isPlainObject } from "../../helpers/objectHelpers";

const socketMiddleware = () => {
    let centrifuge = null;

    const onOpen = (store) => (res) => {
        store.dispatch(wsActions.wsConnected());
    };

    const onClose = (store) => (context) => {
        console.log('Context', context)
        store.dispatch(wsActions.wsDisconnected());
    };

    const getTypeMap = (store) => (action, value ) => {
        const actions = {
            'report': () => store.dispatch(analyticsActions.getReportProgress(value)),
        };

        return actions[action]?.(value) ?? null;
    };

    const onPublish = (store) => (ctx) => {
        if (!isPlainObject(ctx)) return;
        if (!ctx.hasOwnProperty("data")) return;
        if (Object.keys(ctx.data).length === 0) return;

        const { data } = ctx;

        for (const key in data) {
            getTypeMap(store)(key, data[key]);
        }
    };

    return (store) => (next) => (action) => {
        switch (action.type) {
            case actionTypes.WS_CONNECT:
                const currentProtocol = window.location.protocol !== "https:" ? "ws" : "wss";
                console.log("REACT_APP_WS_URL", wsUrl);

                if(!wsUrl) return;
                centrifuge = new Centrifuge(wsUrl?.indexOf('://') !== -1 ? wsUrl : `${currentProtocol}://${wsUrl}`, {
                    debug: false,
                });

                centrifuge.setToken(store.getState().Profile.userData?.ws_token);

                centrifuge.on("connect", onOpen(store));
                centrifuge.on("disconnect", onClose(store));
                centrifuge.on("publish", onPublish(store));

                centrifuge.connect();
                break;

            case actionTypes.WS_DISCONNECT:
                if (centrifuge) {
                    centrifuge.disconnect();
                }
                break;

            default:
                return next(action);
        }
    };
};

export default socketMiddleware();
