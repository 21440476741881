import axios from '../../core/axiosInit';
import { pricinginfoTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import {queryAction, checkResponseError, requestAssignDictionaries} from '../helpers';

export const fetchPricingInfo = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.PRICING_INFO_FETCH_DATA_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['country', 'pricing_type', 'creative_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`pricing-info`, { params: { ...params } });
        dispatch(queryAction(actionTypes.PRICING_INFO_FETCH_DATA_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PRICING_INFO_FETCH_DATA_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const updatePricingInfo = (params, id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.UPDATE_PRICING_INFO_DATA_BEGIN) );
    try {
        const response = await axios.put(`pricing-info/${id}`, {...params});
        dispatch( queryAction(actionTypes.UPDATE_PRICING_INFO_DATA_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.UPDATE_PRICING_INFO_DATA_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchPricingUserInfo = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.PRICING_USER_INFO_FETCH_DATA_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['country', 'pricing_type', 'creative_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`pricing-user-info`, { params: { ...params } });
        dispatch(queryAction(actionTypes.PRICING_USER_INFO_FETCH_DATA_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.PRICING_USER_INFO_FETCH_DATA_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};
