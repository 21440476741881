import axios from '../../core/axiosInit';

import { dictionariesTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, requestAssignDictionaries } from '../helpers';

/*export const assetsGetRoles = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.DICTIONARIES_ROLES_FETCH_BEGIN) );
    try {
        const response = await axios.get(`dictionaries/roles`);
        dispatch( queryAction(actionTypes.DICTIONARIES_ROLES_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_ROLES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};*/

/*export const assetsGetLimits = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.DICTIONARIES_LIMITS_FETCH_BEGIN) );
    try {
        const response = await axios.get(`dictionaries/limits`);
        dispatch( queryAction(actionTypes.DICTIONARIES_LIMITS_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_LIMITS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};*/

/*export const assetsGetFeatures = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.DICTIONARIES_FEATURES_FETCH_BEGIN) );
    try {
        const response = await axios.get(`dictionaries/features`);
        dispatch( queryAction(actionTypes.DICTIONARIES_FEATURES_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_FEATURES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};*/

/*export const getAssetTypes = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.DICTIONARIES_ASSET_TYPES_FETCH_BEGIN) );
    try {
        const response = await axios.get(`dictionaries/asset-types`);
        dispatch( queryAction(actionTypes.DICTIONARIES_ASSET_TYPES_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_ASSET_TYPES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};*/

/*export const getFeatureAssetTypes = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_BEGIN) );
    try {
        const response = await axios.get(`dictionaries/feature-asset-type`);
        dispatch( queryAction(actionTypes.DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};*/

export const getDictionaries = (dictionaries) => async (dispatch, getState) => {
    try {
        const { Dictionaries: dicts } = getState();
        const params = requestAssignDictionaries(dicts, dictionaries);

        if ( Object.keys(params).length ) {
            dispatch( queryAction(actionTypes.DICTIONARIES_FETCH_BEGIN) );
            const response = await axios.get(`dictionaries`, {params: {...params}});
            dispatch(queryAction(actionTypes.DICTIONARIES_FETCH_SUCCESS, response.data.meta.dictionaries));
            //checkDictionaries(dispatch, response.data);
            return response.data;
        }
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const getDictionaryWithFilter = (dictionary, filters, filterKey) => async (dispatch, getState) => {
    try {
        const { Dictionaries } = getState();
        if ( Dictionaries.dictionariesList[dictionary] ) {
            const storeName = Dictionaries.dictionariesList[dictionary];
            if ( Dictionaries[storeName] && Dictionaries[storeName].find(item => item.filterKey === filterKey) ) {
                return false;
            }
        }

        const params = {
            dictionaries: dictionary,
            filters: JSON.stringify(filters)
        }

        if ( Object.keys(params).length ) {
            dispatch( queryAction(actionTypes.DICTIONARIES_FILTERED_FETCH_BEGIN) );
            const response = await axios.get(`dictionaries`, {params: {...params}});
            dispatch(queryAction(actionTypes.DICTIONARIES_FILTERED_FETCH_SUCCESS, {
                filterKey: filterKey,
                dictionary: Dictionaries.dictionariesList[dictionary],
                data: response.data.meta.dictionaries[Object.keys(response.data.meta.dictionaries)[0]]
            }));
            return true;
        }
    } catch (error) {
        dispatch( queryAction(actionTypes.DICTIONARIES_FILTERED_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const applyDictionaries = (dispatch, dictionaries) => {
    if ( Object.keys(dictionaries).length ) {
        dispatch(queryAction(actionTypes.DICTIONARIES_FETCH_SUCCESS, dictionaries));
    }
};
