import axios from '../../core/axiosInit';

import { channelsTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';
import checkPermission from '../../core/checkPermiss';

export const fetchChannels = params => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CHANNELS_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['status'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`channels`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CHANNELS_FETCH_SUCCESS, { list: response.data.data, meta: response.data.meta}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNELS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchChannel = (id, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CHANNEL_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['category', 'asset_tag', 'status', 'web_analytic_provider', 'placement_type'];
        if (checkPermission('can read field traffic_format')) {
            dictionaries.push('traffic_format')
        }
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`channels/${id}`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CHANNEL_FETCH_SUCCESS, {...response.data.data[0], placement_type_ids: response.data.meta.placement_type_ids || [] }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addChannel = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_ADD_BEGIN) );
    try {
        const response = await axios.post(`channels`, {...params});
        dispatch( queryAction(actionTypes.CHANNEL_ADD_SUCCESS, {...response.data.data[0], placement_type_ids: response.data.meta.placement_type_ids || [] }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_ADD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateChannel = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`channels/${id}`, {...params});
        dispatch( queryAction(actionTypes.CHANNEL_UPDATE_SUCCESS, {
            id: id,
            data: {...response.data.data[0], placement_type_ids: response.data.meta.placement_type_ids || [] }
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_UPDATE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteChannel = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_DELETE_BEGIN, id) );
    try {
        const response = await axios.delete(`channels/${id}`);
        dispatch( queryAction(actionTypes.CHANNEL_DELETE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_DELETE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const restoreChannel = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_RESTORE_BEGIN, id) );
    try {
        const response = await axios.patch(`channels/${id}`);
        dispatch( queryAction(actionTypes.CHANNEL_RESTORE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_RESTORE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchChannelPlacements = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_PLACEMENTS_FETCH_BEGIN) );
    try {
        const response = await axios.get(`channels/${id}/placements`);
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENTS_FETCH_SUCCESS, { channelId: id, data: response.data.data }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENTS_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchChannelPlacement = (channel, id, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['placement_type', 'asset_tag', 'traffic_format'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`channels/${channel}/placements/${id}`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_FETCH_FAILURE, { channel, id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addPlacement = (channel, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_ADD_BEGIN) );
    try {
        const response = await axios.post(`channels/${channel}/placements`, {...params});
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_ADD_SUCCESS, {channel: channel, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_ADD_FAILURE, { channel, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updatePlacement = (channel, id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`channels/${channel}/placements/${id}`, {...params});
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_UPDATE_SUCCESS, {
            channel: channel,
            id: id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_UPDATE_FAILURE, { channel, id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changePlacementFeatureStatus = ({feature_asset_type_id, asset_id, params}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_BEGIN, {feature_asset_type_id, asset_id}) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_FAILURE, { feature_asset_type_id, asset_id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changeChannelFeatureStatus = ({feature_asset_type_id, asset_id, params}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_FEATURE_STATUS_CHANGE_BEGIN, {feature_asset_type_id, asset_id}) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        dispatch( queryAction(actionTypes.CHANNEL_FEATURE_STATUS_CHANGE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_FEATURE_STATUS_CHANGE_FAILURE, { feature_asset_type_id, asset_id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const groupActionsPlacements = ({action, channelId, placementId}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_ACTIONS_PLACEMENT_BEGIN, action) );
    try {
        let response;
        if ( action === 'delete' ) {
            response = await axios.delete(`channels/${channelId}/placements/${placementId}`);
        } else if ( action === 'restore' ) {
            response = await axios.patch(`channels/${channelId}/placements/${placementId}`);
        }
        dispatch( queryAction(actionTypes.CHANNEL_ACTIONS_PLACEMENT_SUCCESS, {action: action, data: response.data.data}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_ACTIONS_PLACEMENT_FAILURE, { action, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const groupActionsChannelStatus = (action, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_STATUS_UPDATE_BEGIN, action) );
    try {
        const response = await axios.patch(`channels-status`, params);
        dispatch( queryAction(actionTypes.CHANNEL_STATUS_UPDATE_SUCCESS, {action: action, data: response.data.data}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_STATUS_UPDATE_FAILURE, { action, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const copyChannel = (channelId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_COPY_BEGIN, channelId, params) );
    try {
        const response = await axios.post(`channels/${channelId}`, params);
        dispatch( queryAction(actionTypes.CHANNEL_COPY_SUCCESS, {
            channelId: channelId,
            data: response.data.data
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_COPY_FAILURE, { channelId, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const copyPlacement = (channelId, placementId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_COPY_BEGIN, channelId, placementId, params) );
    try {
        const response = await axios.post(`channels/${channelId}/placements/${placementId}`, params);
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_COPY_SUCCESS, {
            channelId: channelId,
            placementId: placementId,
            data: response.data.data
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CHANNEL_PLACEMENT_COPY_FAILURE, { channelId, placementId, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};
export const changeChannelParams = (assetId, data) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CHANNEL_CHANGE_PARAMS, {id: assetId, data}) );
};
