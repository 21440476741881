import { combineReducers } from 'redux';

import wsReducer, { initialState as wsInitialState } from './ws';
import profileReducer, { initialState as profileInitialState } from './profile';
import usersReducer, { initialState as usersInitialState } from './users';
import channelsReducer, { initialState as channelsInitialState } from './channels';
import dictionariesReducer, { initialState as dictionariesInitialState } from './dictionaries';
import featuresReducer, { initialState as featuresInitialState } from './features';
import campaignsReducer, { initialState as campaignsInitialState } from './campaigns';
import billingReducer, { initialState as billingInitialState } from './billing';
import analyticsReducer, { initialState as analyticsInitialState } from './analytics';
import projectsReducer, { initialState as projectsInitialState } from './projects';
import treesReducer, { initialState as treesInitialState } from './trees';
import rolesReducer, { initialState as rolesInitialState } from './roles';
import feedtemplatesReducer, { initialState as feedtemplatesInitialState } from './feedtemplates';
import actionsReducer, { initialState as actionsInitialState } from './actions';
import commonReducer, { initialState as commonInitialState } from './common';
import dashboardReducer, { initialState as dashboardInitialState } from './dashboard';
import moderationReducer, { initialState as moderationInitialState } from './moderation';
import activitiesReducer, { initialState as activitiesInitialState } from './activities';
import financeReducer, { initialState as financeInitialState } from './finance';
import autopaymentsReducer, { initialState as autopaymentsInitialState } from './autopayments';
import pricinginfoReducer, { initialState as pricinginfoInitialState } from './pricinginfo';
import postbackslogReducer, { initialState as postbackslogInitialState } from './postbackslog';
import exchangeReducer, { initialState as exchangeInitialState } from './exchange';
import creativetemplatesReducer, { initialState as creativetemplatesInitialState } from './creativetemplates';

export const defaultInitialState = {
    Ws: wsInitialState,
    Profile: profileInitialState,
    Users: usersInitialState,
    Channels: channelsInitialState,
    Dictionaries: dictionariesInitialState,
    Features: featuresInitialState,
    Campaigns: campaignsInitialState,
    Billing: billingInitialState,
    Analytics: analyticsInitialState,
    Projects: projectsInitialState,
    Trees: treesInitialState,
    Roles: rolesInitialState,
    FeedTemplates: feedtemplatesInitialState,
    Actions: actionsInitialState,
    Common: commonInitialState,
    Dashboard: dashboardInitialState,
    Moderation: moderationInitialState,
    Activities: activitiesInitialState,
    Finance: financeInitialState,
    Autopayments: autopaymentsInitialState,
    Pricinginfo: pricinginfoInitialState,
    Postbackslog: postbackslogInitialState,
    Exchange: exchangeInitialState,
    CreativeTemplates: creativetemplatesInitialState,
};

export const redusersList = {
    Ws: wsReducer,
    Profile: profileReducer,
    Users: usersReducer,
    Channels: channelsReducer,
    Dictionaries: dictionariesReducer,
    Features: featuresReducer,
    Campaigns: campaignsReducer,
    Billing: billingReducer,
    Analytics: analyticsReducer,
    Projects: projectsReducer,
    Trees: treesReducer,
    Roles: rolesReducer,
    FeedTemplates: feedtemplatesReducer,
    Actions: actionsReducer,
    Common: commonReducer,
    Dashboard: dashboardReducer,
    Moderation: moderationReducer,
    Activities: activitiesReducer,
    Finance: financeReducer,
    Autopayments: autopaymentsReducer,
    PricingInfo: pricinginfoReducer,
    Postbackslog: postbackslogReducer,
    Exchange: exchangeReducer,
    CreativeTemplates: creativetemplatesReducer,
};

export default combineReducers(redusersList);
