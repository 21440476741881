import axios from '../../core/axiosInit';
import { moderationTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import {queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries} from '../helpers';

export const fetchModeration = (url, params) => async (dispatch, getState) => {
    const requestType = url.toUpperCase();
    dispatch(queryAction(actionTypes[`MODERATION_FETCH_${requestType}_BEGIN`]));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['status', 'web_analytic_provider'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`moderation-status-${url}`, { params: { ...params } });
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes[`MODERATION_FETCH_${requestType}_SUCCESS`],
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes[`MODERATION_FETCH_${requestType}_FAILURE`], { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const confirmModerationStatus = (params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.MODERATION_FETCH_STATUS_CAMPAIGNS_CREATIVES_BEGIN));
    try {
        const response = await axios.post(`moderation-status-campaigns-creatives`, { ...params });
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.MODERATION_FETCH_STATUS_CAMPAIGNS_CREATIVES_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.MODERATION_FETCH_STATUS_CAMPAIGNS_CREATIVES_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const getModerationDiff = (asset_id, asset_type_id, params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.MODERATION_GET_MODERATION_DIFF_BEGIN, {asset_id, asset_type_id}));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['status', 'category', 'asset_tag', 'priority_type', 'creative_type', 'traffic_format', 'non_standard_template', 'feed_template'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`change/asset_type/${asset_type_id}/assets/${asset_id}`, { params: { ...params }});
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.MODERATION_GET_MODERATION_DIFF_SUCCESS, {
            data: response.data.data,
            asset_id: asset_id,
            asset_type_id: asset_type_id
        }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.MODERATION_GET_MODERATION_DIFF_FAILURE, { error, asset_id, asset_type_id }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const changeModerationStatus = (asset_id, asset_type_id, params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.MODERATION_SET_MODERATION_STATUS_BEGIN, {asset_id, asset_type_id}));
    try {
        const response = await axios.post(`change/asset_type/${asset_type_id}/assets/${asset_id}`, { ...params });
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.MODERATION_SET_MODERATION_STATUS_SUCCESS, {
            data: response.data.data,
            asset_id: asset_id,
            asset_type_id: asset_type_id,
            status: params.status
        }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.MODERATION_SET_MODERATION_STATUS_FAILURE, { error, asset_id, asset_type_id }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};