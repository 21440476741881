import axios from '../../core/axiosInit';

import { financeTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, requestAssignDictionaries, checkDictionaries } from '../helpers';

export const addRefillUser = params => async (dispatch) => {
    dispatch(queryAction(actionTypes.ADD_REFILL_USER_BEGIN));
    try {
        const response = await axios.post(`accounts/refill`, { ...params });
        dispatch(queryAction(actionTypes.ADD_REFILL_USER_SUCCESS, response.data.data[0]));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.ADD_REFILL_USER_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const getAccounts = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.FETCH_ACCOUNTS_LIST_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['currency', 'payment_method', 'PaymentSystem'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`accounts`, { params: { ...params } });
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.FETCH_ACCOUNTS_LIST_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.FETCH_ACCOUNTS_LIST_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const getPayment = params => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.GET_PAYMENTS_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['PaymentSystem', 'payment_method', 'payment_system_method'];
        params = requestAssignDictionaries(dicts, dictionaries, params);
        const response = await axios.get(`payments`, { params: { ...params } });

        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.GET_PAYMENTS_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.GET_PAYMENTS_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const createPayment = params => async (dispatch) => {
    dispatch(queryAction(actionTypes.CREATE_PAYMENT_BEGIN));
    try {
        const response = await axios.post(`payments`, { ...params });
        dispatch(queryAction(actionTypes.CREATE_PAYMENT_SUCCESS, response.data.data[0]));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.CREATE_PAYMENT_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const updatePayment = (params, id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.UPDATE_PAYMENT_BEGIN, id));
    try {
        const response = await axios.put(`payments/${id}`, { ...params });
        dispatch(queryAction(actionTypes.UPDATE_PAYMENT_SUCCESS, { id: id, data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.UPDATE_PAYMENT_FAILURE, { id, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const deletePayment = (id) => async (dispatch) => {
    dispatch(queryAction(actionTypes.DELETE_PAYMENT_BEGIN, id));
    try {
        const response = await axios.delete(`payments/${id}`);
        dispatch(queryAction(actionTypes.DELETE_PAYMENT_SUCCESS, { id: id, data: response.data.data[0] }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.DELETE_PAYMENT_FAILURE, { id, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};