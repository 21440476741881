import axios from '../../core/axiosInit';

import { activitiesTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError } from '../helpers';

export const getActivities = params => async (dispatch) => {
    dispatch(queryAction(actionTypes.ACTIVITIES_FETCH_BEGIN));
    try {
        const response = await axios.get(`activities`, { params: { ...params } });
        dispatch(queryAction(actionTypes.ACTIVITIES_FETCH_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.ACTIVITIES_FETCH_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};
