import {
    dictionariesTypes as actionTypes,
    profileTypes as profileActionTypes,
    exchangeTypes as exchangeActionTypes,
    featuresTypes as featuresActionTypes,
    actionsTypes as actionsActionTypes,
    creativetemplatesTypes as creativetemplatesActionTypes
} from '../types';
import { deepCopyObject } from "../../helpers/objectHelpers";


export const initialState = {
    types: ['campaign', 'channel', 'placement'],
    dictionariesList: {
        role: 'roles',
        limit: 'limits',
        feature: 'features',
        asset_type: 'assetTypes',
        feature_asset_type: 'featureAssetTypes',
        placement_type: 'placementTypes',
        pricing_type: 'pricingTypes',
        currency: 'currencies',
        country: 'countries',
        device: 'devices',
        system: 'systems',
        group_by: 'groupBy',
        report_metrics: 'reportMetrics',
        category: 'categories',
        creative_type: 'creativeTypes',
        PaymentSystem: 'paymentSystems',
        priority_type: "priorityTypes",
        redirect_url: "redirectUrls",
        dimensions: 'dimensions',
        asset_tag: 'assetTags',
        dimensions_date: 'dimensionsDate',
        feed_template: 'feedTemplates',
        non_standard_template: 'nonStdTemplates',
        action: 'sharedActions',
        service_domain: 'serviceDomains',
        payment_method: 'paymentMethods',
        connection_type: 'connection_types',
        isp: 'isp',
        status: 'statuses',
        payment_amount: 'payment_amounts',
        payment_period: 'payment_periods',
        payment_gateway: 'payment_gateways',
        web_analytic_provider: 'web_analytic_providers',
        capping_types: 'cappingTypes',
        period_types: 'periodTypes',
        subject_types: 'subjectTypes',
        traffic_format: 'trafficFormats',
        permission_template: 'permissionTemplates',
        payment_system_method: 'paymentSystemMethods',
        tracking_event: 'trackingEvents',
        syntetic: 'syntetics',
        time_zone: 'timeZones',
        creative_template: 'creativeTemplates',
        region: 'regions',
        city: 'cities',
        language: 'languages',
        shaping: 'shaping'
    },
    dictionariesNames: {
        roles: 'roles',
        limits: 'limits',
        features: 'features',
        asset_types: 'assetTypes',
        feature_asset_type: 'featureAssetTypes',
        placement_types: 'placementTypes',
        pricing_types: 'pricingTypes',
        currencies: 'currencies',
        countries: 'countries',
        devices: 'devices',
        systems: 'systems',
        group_by: 'groupBy',
        report_metrics: 'reportMetrics',
        categories: 'categories',
        creative_types: 'creativeTypes',
        payment_systems: 'paymentSystems',
        priority_types: 'priorityTypes',
        redirect_urls: "redirectUrls",
        dimensions: 'dimensions',
        asset_tags: 'assetTags',
        dimensions_date: 'dimensionsDate',
        feed_templates: 'feedTemplates',
        non_standard_templates: 'nonStdTemplates',
        actions: 'sharedActions',
        service_domains: 'serviceDomains',
        payment_methods: 'paymentMethods',
        connection_types: 'connection_types',
        isp: 'isp',
        statuses: 'statuses',
        payment_amounts: 'payment_amounts',
        payment_periods: 'payment_periods',
        payment_gateways: 'payment_gateways',
        web_analytic_providers: 'web_analytic_providers',
        capping_types: 'cappingTypes',
        period_types: 'periodTypes',
        subject_types: 'subjectTypes',
        traffic_formats: 'trafficFormats',
        permission_templates: 'permissionTemplates',
        payment_system_methods: 'paymentSystemMethods',
        tracking_events: 'trackingEvents',
        syntetics: 'syntetics',
        time_zones: 'timeZones',
        creative_templates: 'creativeTemplates',
        regions: 'regions',
        cities: 'cities',
        languages: 'languages',
        'shaping': "shaping"
    },
    roles: [],
    limits: [],
    placementTypes: [],
    features: [],
    assetTypes: [],
    featureAssetTypes: [],
    pricingTypes: [],
    creativeTypes: [],
    countries: [],
    regions: [],
    cities: [],
    devices: [],
    systems: [],
    currencies: [],
    groupBy: [],
    reportMetrics: [],
    categories: [],
    paymentSystems: [],
    priorityTypes: [],
    redirectUrls: [],
    dimensions: [],
    assetTags: [],
    dimensionsDate: [],
    feedTemplates: [],
    nonStdTemplates: [],
    sharedActions: [],
    serviceDomains: [],
    paymentMethods: [],
    connection_types: [],
    isp: [],
    statuses: [],
    payment_amounts: [],
    payment_periods: [],
    payment_gateways: [],
    web_analytic_providers: [],
    cappingTypes: [],
    periodTypes: [],
    subjectTypes: [],
    trafficFormats: [],
    permissionTemplates: [],
    paymentSystemMethods: [],
    trackingEvents: [],
    syntetics: [],
    timeZones: [],
    creativeTemplates: [],
    languages: [],
    shaping: [{id: 1, name: 'Shaping'}]
};

const state = (state = deepCopyObject(initialState), { type, payload }) => {
    switch (type) {

        case actionTypes.DICTIONARIES_ROLES_FETCH_SUCCESS:
            return {
                ...state,
                roles: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_LIMITS_FETCH_SUCCESS:
            return {
                ...state,
                limits: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_FEATURES_FETCH_SUCCESS:
            return {
                ...state,
                features: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_ASSET_TYPES_FETCH_SUCCESS:
            return {
                ...state,
                assetTypes: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_SUCCESS:
            return {
                ...state,
                featureAssetTypes: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_PRICING_TYPES_FETCH_SUCCESS:
            return {
                ...state,
                pricingTypes: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_COUNTRIES_FETCH_SUCCESS:
            return {
                ...state,
                countries: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_DEVICES_FETCH_SUCCESS:
            return {
                ...state,
                devices: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_SYSTEMS_FETCH_SUCCESS:
            return {
                ...state,
                systems: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_CURRENCIES_FETCH_SUCCESS:
            return {
                ...state,
                currencies: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_GROUP_BY_FETCH_SUCCESS:
            return {
                ...state,
                groupBy: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_PLACEMENT_TYPES_FETCH_SUCCESS:
            return {
                ...state,
                placementTypes: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_REPORT_METRICS_FETCH_SUCCESS:
            return {
                ...state,
                reportMetrics: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_CATEGORIES_FETCH_SUCCESS:
            return {
                ...state,
                categories: [
                    ...payload
                ]
            };
        case actionTypes.DICTIONARIES_CREATIVE_TYPES_FETCH_SUCCESS:
            return {
                ...state,
                creativeTypes: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_DIMENSIONS_FETCH_SUCCESS:
            return {
                ...state,
                dimensions: [
                    ...payload
                ]
            };

        case actionTypes.DICTIONARIES_DIMENSIONS_DATE_FETCH_SUCCESS:
            return {
                ...state,
                dimensionsDate: [
                    ...payload
                ]
            };

        /*case actionTypes.DICTIONARIES_TAGS_FETCH_SUCCESS:
                return {
                    ...state,
                    tags: [
                        ...payload
                    ]
                };*/

        case actionTypes.DICTIONARIES_FETCH_SUCCESS:
            const newState = {};
            Object.keys(payload).forEach(dictionary => {
                if ( 'undefined' !== typeof state.dictionariesNames[dictionary] ) {
                    // TODO needed for tests
                    /*if ( dictionary === 'creative_types' ) {
                        console.log('payload[dictionary]', dictionary, payload[dictionary]);
                        payload[dictionary] = payload[dictionary].map( item => {
                            if ( item.id === 2 ) {
                                item.fields[0].permission = ['admin', 'manager'];
                            }
                            return item;
                        } )
                    }*/

                    // TODO - testing new catfish teaser template - remove after migration to database
                    /*if ( dictionary === 'creative_templates' ) {
                        console.log('creative_templates',payload[dictionary]);
                        payload[dictionary] = payload[dictionary].map( template => {
                            if ( template.name === 'teaser' ) {
                                template.fields.push({
                                    "name": "preview",
                                    "type": "template_preview",
                                    "label": "Preview",
                                    "onlyForUI": true
                                });
                            }
                            return template;
                        } );
                    }*/

                    newState[state.dictionariesNames[dictionary]] = payload[dictionary];
                }
            });
            // console.log('newState',newState);
            return {
                ...state,
                ...newState
            };

        case actionTypes.DICTIONARIES_FILTERED_FETCH_SUCCESS:
            return {
                ...state,
                [payload.dictionary]: [
                    ...(state[payload.dictionary] || []).filter(item => item.filterKey !== payload.filterKey),
                    {
                        filterKey: payload.filterKey,
                        data: payload.data
                    }
                ]
            };

        // clear dictionaries on de-reincarnation
        case profileActionTypes.UPDATE_REINCARNATION_TOKEN: {
            if ( !payload ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        // clear dictionaries on reincarnation
        case profileActionTypes.REINCARNATION_SUCCESS: {
            if ( payload.token ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        case exchangeActionTypes.PAYMENT_SYSTEM_FETCH_SUCCESS:
            return {
                ...state,
                paymentSystems: state.paymentSystems.map((item) =>
                    parseInt(item.id) !== parseInt(payload.id) ? item : { ...item, ...payload }
                ),
            };

        case exchangeActionTypes.PAYMENT_SYSTEM_CREATE_SUCCESS:
            return {
                ...state,
                paymentSystems: [
                    ...state.paymentSystems.filter((item) => parseInt(item.id) !== parseInt(payload.id)),
                    payload,
                ],
            };

        case exchangeActionTypes.PAYMENT_SYSTEM_UPDATE_SUCCESS:
            return {
                ...state,
                paymentSystems: state.paymentSystems.map((item) =>
                    parseInt(item.id) !== parseInt(payload.id) ? item : { ...item, ...payload.data }
                ),
            };

        case exchangeActionTypes.PAYMENT_SYSTEM_DELETE_SUCCESS:
            return {
                ...state,
                paymentSystems: state.paymentSystems.filter((item) => parseInt(item.id) !== parseInt(payload.id)),
            };

        // truncate actions dictionary if sharing changes
        case featuresActionTypes.FEATURE_UPDATE_SUCCESS:
            if ( payload.feature_name === 'sharing_actions' ) {
                return {
                    ...state,
                    sharedActions: []
                };
            }
            return state;

        // truncate actions dictionary if action was edited
        case actionsActionTypes.ACTION_UPDATE_SUCCESS:
            return {
                ...state,
                sharedActions: []
            };

        case actionTypes.DICTIONARIES_LANGUAGES_FETCH_SUCCESS:
            return {
                ...state,
                languages: [
                    ...payload
                ]
            };

        case creativetemplatesActionTypes.SHARED_CREATIVETEMPLATES_UPDATE_SUCCESS:
            return {
                ...state,
                creativeTemplates: []
            };

        default:
            return state;
    }
};

export default state;