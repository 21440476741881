import { pricinginfoTypes as actionTypes } from '../types';

export const initialState = {
    loading: false,
    pricingInfoData: {
        data: [],
        meta: {},
        isLoadData: false,
    },
    pricingUserInfoData: {
        data: [],
        meta: {},
        isLoadData: false,
    }
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.PRICING_INFO_FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            };

        case actionTypes.PRICING_INFO_FETCH_DATA_FAILURE:
            if ( payload?.error?.code === 'ERR_CANCELED' ) {
                return state;
            }
            return {
                ...state,
                loading: false
            };

        case actionTypes.PRICING_INFO_FETCH_DATA_SUCCESS:
            return {
                ...state,
                pricingInfoData: {
                    ...state.pricingInfoData,
                    ...payload,
                    isLoadData: true,
                },
                loading: false
            };

        case actionTypes.UPDATE_PRICING_INFO_DATA_SUCCESS:
            const newData = state.pricingInfoData.data.map(el => el.id === payload.id ? { ...payload } : el);

            return {
                ...state,
                pricingInfoData: {
                    ...state.pricingInfoData,
                    data: newData,
                    isLoadData: true,
                }
            };

        case actionTypes.PRICING_USER_INFO_FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            };

        case actionTypes.PRICING_USER_INFO_FETCH_DATA_FAILURE:
            if ( payload?.error?.code === 'ERR_CANCELED' ) {
                return state;
            }
            return {
                ...state,
                loading: false
            };

        case actionTypes.PRICING_USER_INFO_FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pricingUserInfoData: {
                    ...state.pricingUserInfoData,
                    ...payload,
                    isLoadData: true,
                }
            };

        default:
            return state;
    }
};

export default state;