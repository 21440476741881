export const FEEDTEMPLATES_FETCH_BEGIN = 'FeedTemplates/FetchBegin';
export const FEEDTEMPLATES_FETCH_SUCCESS = 'FeedTemplates/FetchSuccess';
export const FEEDTEMPLATES_FETCH_FAILURE = 'FeedTemplates/FetchFailure';

export const FEEDTEMPLATES_CREATE_BEGIN = 'FeedTemplates/CreateBegin';
export const FEEDTEMPLATES_CREATE_SUCCESS = 'FeedTemplates/CreateSuccess';
export const FEEDTEMPLATES_CREATE_FAILURE = 'FeedTemplates/CreateFailure';

export const FEEDTEMPLATES_UPDATE_BEGIN = 'FeedTemplates/UpdateBegin';
export const FEEDTEMPLATES_UPDATE_SUCCESS = 'FeedTemplates/UpdateSuccess';
export const FEEDTEMPLATES_UPDATE_FAILURE = 'FeedTemplates/UpdateFailure';