import { exchangeTypes as actionTypes } from "../types";

export const initialState = {
    exchangeCurrencies: {
        data: [],
        meta: {},
        loading: false,
    },
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.EXCHANGE_CURRENCIES_FETCH_BEGIN:
            return {
                ...state,
                exchangeCurrencies: {
                    ...state.exchangeCurrencies,
                    loading: true,
                },
            };

        case actionTypes.EXCHANGE_CURRENCIES_FETCH_SUCCESS:
            return {
                ...state,
                exchangeCurrencies: {
                    data: payload.data,
                    meta: payload.meta,
                    loading: false,
                },
            };

        case actionTypes.EXCHANGE_CURRENCIES_FETCH_FAILURE:
            return {
                ...state,
                exchangeCurrencies: {
                    ...state.exchangeCurrencies,
                    loading: false,
                },
            };

        default:
            return state;
    }
};

export default state;