export const MODERATION_FETCH_CAMPAIGNS_BEGIN = 'Moderation/FetchCampaignsBegin';
export const MODERATION_FETCH_CAMPAIGNS_SUCCESS = 'Moderation/FetchCampaignsSuccess';
export const MODERATION_FETCH_CAMPAIGNS_FAILURE = 'Moderation/FetchCampaignsFailure';

export const MODERATION_FETCH_CHANNELS_BEGIN = 'Moderation/FetchChannelsBegin';
export const MODERATION_FETCH_CHANNELS_SUCCESS = 'Moderation/FetchChannelsSuccess';
export const MODERATION_FETCH_CHANNELS_FAILURE = 'Moderation/FetchChannelsFailure';

export const MODERATION_FETCH_STATUS_CAMPAIGNS_CREATIVES_BEGIN = 'Moderation/FetchStatusCampaignsCreativesBegin';
export const MODERATION_FETCH_STATUS_CAMPAIGNS_CREATIVES_SUCCESS = 'Moderation/FetchStatusCampaignsCreativesSuccess';
export const MODERATION_FETCH_STATUS_CAMPAIGNS_CREATIVES_FAILURE = 'Moderation/FetchStatusCampaignsCreativesFailure';

export const MODERATION_GET_MODERATION_DIFF_BEGIN = 'Moderation/GetModerationDiffBegin';
export const MODERATION_GET_MODERATION_DIFF_SUCCESS = 'Moderation/GetModerationDiffSuccess';
export const MODERATION_GET_MODERATION_DIFF_FAILURE = 'Moderation/GetModerationDiffFailure';

export const MODERATION_SET_MODERATION_STATUS_BEGIN = 'Moderation/SetModerationStatusBegin';
export const MODERATION_SET_MODERATION_STATUS_SUCCESS = 'Moderation/SetModerationStatusSuccess';
export const MODERATION_SET_MODERATION_STATUS_FAILURE = 'Moderation/SetModerationStatusFailure';
