import { moderationTypes as actionTypes } from '../types';
import { assetTypesIDs, STATUS_CONFIRMED, STATUS_DENY } from '../../core/constants';

export const initialState = {
    campaignsData: [],
    channelsData: {
        data: [],
        meta: {},
        isLoadData: false,
    },
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.MODERATION_FETCH_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaignsData: [...payload.data]
            };

        case actionTypes.MODERATION_FETCH_CHANNELS_SUCCESS:
            return {
                ...state,
                channelsData: {
                    ...state.channelsData,
                    ...payload,
                    isLoadData: true,
                }
            };

        case actionTypes.MODERATION_GET_MODERATION_DIFF_BEGIN:
        case actionTypes.MODERATION_GET_MODERATION_DIFF_FAILURE:
        case actionTypes.MODERATION_GET_MODERATION_DIFF_SUCCESS:
            const fillModerationData = itemRow => {
                if (
                    (
                        itemRow.asset_id
                        && parseInt(payload.asset_type_id) === assetTypesIDs[itemRow.asset_type]
                        && parseInt(itemRow.asset_id) === parseInt(payload.asset_id)
                    )
                    || ( itemRow.channel_id && parseInt(itemRow.channel_id) === parseInt(payload.asset_id) )
                ) {
                    switch (type) {
                        case actionTypes.MODERATION_GET_MODERATION_DIFF_BEGIN:
                            itemRow.moderationInfo = {
                                data: {},
                                loadingData: true
                            };
                            break;

                        case actionTypes.MODERATION_GET_MODERATION_DIFF_FAILURE:
                            itemRow.moderationInfo = {
                                data: {},
                                loadingData: false
                            };
                            break;

                        case actionTypes.MODERATION_GET_MODERATION_DIFF_SUCCESS:
                            itemRow.moderationInfo = {
                                data: payload.data,
                                loadingData: false
                            };
                            break;

                        default: break;
                    }
                    return {...itemRow};
                }
                return itemRow;
            }

            if (
                parseInt(payload.asset_type_id) === assetTypesIDs.channel
                || parseInt(payload.asset_type_id) === assetTypesIDs.placement
            ) {
                return {
                    ...state,
                    channelsData: {
                        ...state.channelsData,
                        data: state.channelsData.data.map(fillModerationData)
                    }
                };
            }
            return {
                ...state,
                campaignsData: state.campaignsData.map(fillModerationData)
            };

        case actionTypes.MODERATION_SET_MODERATION_STATUS_SUCCESS:
            const filterModerationData = itemRow => {
                return !(
                    itemRow.asset_id
                    && parseInt(payload.asset_type_id) === assetTypesIDs[itemRow.asset_type]
                    && parseInt(itemRow.asset_id) === parseInt(payload.asset_id)
                );
            }
            const changeModerationStatus = itemRow => {
                if (
                    itemRow.channel_id
                    && parseInt(itemRow.channel_id) === parseInt(payload.asset_id)
                ) {
                    itemRow.channel_status_id = payload.status === 'confirm' ? STATUS_CONFIRMED : STATUS_DENY;
                    return {...itemRow};
                }
                return itemRow;
            }

            if (
                parseInt(payload.asset_type_id) === assetTypesIDs.channel
                || parseInt(payload.asset_type_id) === assetTypesIDs.placement
            ) {
                return {
                    ...state,
                    channelsData: {
                        ...state.channelsData,
                        data: state.channelsData.data.map(changeModerationStatus)
                    }
                };
            }
            return {
                ...state,
                campaignsData: state.campaignsData.filter(filterModerationData)
            };

        default:
            return state;
    }
};

export default state;