export const ACTIONS_FETCH_BEGIN = 'Actions/FetchListBegin';
export const ACTIONS_FETCH_SUCCESS = 'Actions/FetchListSuccess';
export const ACTIONS_FETCH_FAILURE = 'Actions/FetchListFailure';

export const ACTION_FETCH_BEGIN = 'Actions/FetchBegin';
export const ACTION_FETCH_SUCCESS = 'Actions/FetchSuccess';
export const ACTION_FETCH_FAILURE = 'Actions/FetchFailure';

export const ACTION_ADD_BEGIN = 'Actions/AddBegin';
export const ACTION_ADD_SUCCESS = 'Actions/AddSuccess';
export const ACTION_ADD_FAILURE = 'Actions/AddFailure';

export const ACTION_UPDATE_BEGIN = 'Actions/UpdateBegin';
export const ACTION_UPDATE_SUCCESS = 'Actions/UpdateSuccess';
export const ACTION_UPDATE_FAILURE = 'Actions/UpdateFailure';

export const ACTION_DELETE_BEGIN = 'Actions/DeleteBegin';
export const ACTION_DELETE_SUCCESS = 'Actions/DeleteSuccess';
export const ACTION_DELETE_FAILURE = 'Actions/DeleteFailure';

export const ACTION_FEATURE_STATUS_CHANGE_BEGIN = 'Actions/FeatureStatusChangeBegin';
export const ACTION_FEATURE_STATUS_CHANGE_SUCCESS = 'Actions/FeatureStatusChangeSuccess';
export const ACTION_FEATURE_STATUS_CHANGE_FAILURE = 'Actions/FeatureStatusChangeFailure';

export const MASS_SHARING_ACTIONS_FETCH_BEGIN = 'Actions/FetchMassSharingListBegin';
export const MASS_SHARING_ACTIONS_FETCH_SUCCESS = 'Actions/FetchMassSharingListSuccess';
export const MASS_SHARING_ACTIONS_FETCH_FAILURE = 'Actions/FetchMassSharingListFailure';

export const MASS_SHARING_ACTIONS_UPDATE_BEGIN = 'Actions/UpdateMassSharingListBegin';
export const MASS_SHARING_ACTIONS_UPDATE_SUCCESS = 'Actions/UpdateMassSharingListSuccess';
export const MASS_SHARING_ACTIONS_UPDATE_FAILURE = 'Actions/UpdateMassSharingListFailure';