import { createSelector, defaultMemoize } from 'reselect';
import {
    assetTags,
    categories,
    creativeTypes, nonStdTemplates,
    priorityTypes,
    statuses,
    trafficFormats,
    webAnalyticProviders
} from "./dictionaries";

export const campaignsData = createSelector(state => state.Moderation.campaignsData, campaignsData => campaignsData);
export const channelsData = createSelector(state => state.Moderation.channelsData, channelsData => channelsData);
export const channelsDataList = createSelector(state => state.Moderation.channelsData.data, data => data);
export const channelsDataTotal = createSelector(state => state.Moderation.channelsData.meta?.pagination?.total, total => total || 0);
export const channelsDataCurrentPage = createSelector(state => state.Moderation.channelsData.meta?.pagination?.current_page, current_page => current_page || 1);
export const channelsDataManagers = createSelector(state => state.Moderation.channelsData.meta?.managers, managers => managers || []);
export const channelsIsLoadData = createSelector(state => state.Moderation.channelsData.isLoadData, isLoadData => isLoadData);

export const channelsDictionaries = createSelector(
    [
        statuses,
        webAnalyticProviders,
        channelsData
    ],
    (statuses, web_analytic_providers, channelsData) => ({
        statuses,
        web_analytic_providers,
        managers: channelsData?.meta?.managers || []
    })
);
export const moderationDiffDictionaries = createSelector(
    [statuses, assetTags, categories, priorityTypes, creativeTypes, trafficFormats, nonStdTemplates, webAnalyticProviders],
    (statuses, assetTags, categories, priorityTypes, creativeTypes, trafficFormats, nonStdTemplates, webAnalyticProviders) => ({
        statuses,
        assetTags,
        categories,
        priorityTypes,
        creativeTypes,
        trafficFormats,
        nonStdTemplates,
        webAnalyticProviders
    })
);
export const moderationDiffAssetData = defaultMemoize(
    (assetType, assetId) => createSelector(
        [
            state => state.Moderation.channelsData,
            state => state.Moderation.campaignsData,
        ],
        (channelsData, campaignsData) => {
            return (['channel','placement'].indexOf(assetType) !== -1 ? channelsData.data : campaignsData)
                .find(item =>
                    ['channel','placement'].indexOf(assetType) !== -1
                        ? (parseInt(assetId) === parseInt(item.channel_id))
                        : (parseInt(assetId) === item.asset_id && assetType === item.asset_type)
                );
        })
);