import { activitiesTypes as actionTypes } from '../types';

export const initialState = {
    activitiesData: {
        data: [],
        meta: {},
        isLoadData: false,
        isLoadingProcess: false
    },
    loading: false,
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ACTIVITIES_FETCH_BEGIN:
            if ( state.activitiesData.isLoadingProcess ) {
                return state;
            }
            return {
                ...state,
                loading: true,
            };

        case actionTypes.ACTIVITIES_FETCH_SUCCESS:
            return {
                ...state,
                activitiesData: {
                    ...state.activitiesData,
                    ...payload,
                    isLoadData: true,
                    isLoadingProcess: false
                },
                loading: false,
            };

        case actionTypes.ACTIVITIES_FETCH_FAILURE:
            const { message } = payload.error;
            if ( message && message === 'Operation canceled due to new request.' ) {
                return state;
            }
            return {
                ...state,
                activitiesData: {
                    ...state.activitiesData,
                    isLoadingProcess: false
                },
                loading: false,
            };

        default:
            return state;
    }
};

export default state;