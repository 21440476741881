import { profileTypes as actionTypes } from "../types";

const checkAuthError = error => {
    if (
        'undefined' !== typeof error.response
        && 'undefined' !== typeof error.response.status
        && (
            error.response.status === 401
            || (error.response.status === 403 && error.response.data.messages === "Expire time tab token")
        )
    ) {
        console.log('LOGOUT !!!!!!!!!!!!');
        //window.location.replace('/login');
        return {
            type: actionTypes.LOGOUT
        };
    }
    return {
        type: actionTypes.ERROR_CHECK_AUTH
    };
};

export default checkAuthError;
