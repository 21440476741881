export const REPORTS_FETCH_BEGIN = 'Analytics/FetchReportsBegin';
export const REPORTS_FETCH_SUCCESS = 'Analytics/FetchReportsSuccess';
export const REPORTS_FETCH_FAILURE = 'Analytics/FetchReportsFailure';

export const REPORTS_ADD_TEMPLATE_BEGIN = 'Analytics/AddTemplateBegin';
export const REPORTS_ADD_TEMPLATE_SUCCESS = 'Analytics/AddTemplateSuccess';
export const REPORTS_ADD_TEMPLATE_FAILURE = 'Analytics/AddTemplateFailure';

export const REPORTS_UPDATE_TEMPLATE_BEGIN = 'Analytics/UpdateTemplateBegin';
export const REPORTS_UPDATE_TEMPLATE_SUCCESS = 'Analytics/UpdateTemplateSuccess';
export const REPORTS_UPDATE_TEMPLATE_FAILURE = 'Analytics/UpdateTemplateFailure';

export const REPORTS_DELETE_TEMPLATE_BEGIN = 'Analytics/DeleteTemplateBegin';
export const REPORTS_DELETE_TEMPLATE_SUCCESS = 'Analytics/DeleteTemplateSuccess';
export const REPORTS_DELETE_TEMPLATE_FAILURE = 'Analytics/DeleteTemplateFailure';

export const RUN_REPORT_BEGIN = 'Analytics/RunReportBegin';
export const RUN_REPORT_SUCCESS = 'Analytics/RunReportSuccess';
export const RUN_REPORT_FAILURE = 'Analytics/RunReportFailure';

export const SET_INTERVAL = 'Analytics/SetInterval';
export const RESET_INTERVAL = 'Analytics/ResetInterval';
export const SET_REPORT_PROGRESS = 'Analytics/SetReportProgress';
export const RESET_REPORT_PROGRESS = 'Analytics/ResetReportProgress';
export const RELOAD_REPORT = 'Analytics/ReloadReport';
export const CRASH_REPORT = 'Analytics/CrashReport';
export const SET_ORDER_COLUMNS = 'Analytics/SetOrderColumns';
export const REPORT_READY = 'Analytics/ReportReady';
export const REPORTS_METRICS_RECEIVED = 'Analytics/MetricsReceived';

export const PLACEMENT_LINK_FETCH_BEGIN = 'Analytics/FetchPlacementLinkBegin';
export const PLACEMENT_LINK_FETCH_SUCCESS = 'Analytics/FetchPlacementLinkSuccess';
export const PLACEMENT_LINK_FETCH_FAILURE = 'Analytics/FetchPlacementLinkFailure';

export const CAMPAIGN_LINK_FETCH_BEGIN = 'Analytics/FetchCampaignLinkBegin';
export const CAMPAIGN_LINK_FETCH_SUCCESS = 'Analytics/FetchCampaignLinkSuccess';
export const CAMPAIGN_LINK_FETCH_FAILURE = 'Analytics/FetchCampaignLinkFailure';

export const CREATIVE_LINK_FETCH_BEGIN = 'Analytics/FetchCreativeLinkBegin';
export const CREATIVE_LINK_FETCH_SUCCESS = 'Analytics/FetchCreativeLinkSuccess';
export const CREATIVE_LINK_FETCH_FAILURE = 'Analytics/FetchCreativeLinkFailure';