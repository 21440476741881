export const RELOAD_PAGE = 'Actions/reloadPage';

export const FEED_TEMPLATE_EDIT_JSON = 'FeedTemplate/EditJson';
export const FEED_TEMPLATE_UPDATE_CODE = 'FeedTemplate/UpdateCode';

export const CHECK_SAVED_FORM = 'Common/CheckSavedForm';

export const GET_APP_VERSION_BEGIN = 'Common/GetAppVersionBegin';
export const GET_APP_VERSION_SUCCESS = 'Common/GetAppVersionSuccess';
export const GET_APP_VERSION_FAILURE = 'Common/GetAppVersionFailure';

export const SERVICE_UNAVAILABLE = 'Common/ServiceUnavailable';
export const SERVICE_UNAVAILABLE_TIMEOUT = 'Common/ServiceUnavailableTimeout';
export const SERVICE_AVAILABLE = 'Common/ServiceAvailable';
