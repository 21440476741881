import { analyticsTypes as actionTypes } from '../types';
import { profileTypes as actionTypesProfiles } from '../types';
import { STATUS_REPORT_READY } from '../../core/constants';

export const initialState = {
    data: [],
    metrics: {},
    reports: {},
    progress: {},
    interval: [],
    errorsReport: {},
    orderColumns: {},
    deletedReports: [],
    receivedMetrics: false
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypesProfiles.REINCARNATION_SUCCESS:
            return { ...initialState };

        case actionTypes.REPORTS_FETCH_SUCCESS:
            return {
                ...state,
                data: [...payload.data],
                metrics: {...payload.analytic.metrics}
            };

        case actionTypes.REPORTS_METRICS_RECEIVED:
            return {
                ...state,
                receivedMetrics: true
            };

        case actionTypes.REPORTS_ADD_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data,
                    ...payload
                ]
            };

        case actionTypes.REPORTS_UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: state.data.map(item => parseInt(item.id) !== parseInt(payload.id) ?
                    item : { ...item, ...payload.data }),
            };

        case actionTypes.REPORTS_DELETE_TEMPLATE_BEGIN:
            return {
                ...state,
                deletedReports: [...state.deletedReports, payload],
            };

        case actionTypes.REPORTS_DELETE_TEMPLATE_FAILURE:
            return {
                ...state,
                deletedReports: [...state.deletedReports.filter(item => parseInt(item) !== parseInt(payload.id))],
            };

        case actionTypes.REPORTS_DELETE_TEMPLATE_SUCCESS:
            const reportsAfterDelete = {...state.reports};
            delete reportsAfterDelete[payload.id];

            return {
                ...state,
                data: [...state.data.filter(item => parseInt(item.id) !== parseInt(payload.id))],
                reports: reportsAfterDelete
            };

        case actionTypes.RUN_REPORT_SUCCESS:
            // preventing showing report witch was deleted
            if ( state.deletedReports.indexOf(payload?.report?.id) !== -1 ) {
                return state;
            }
            const report = {
                rows: payload.data[0].rows,
                summary: payload.data[0].summary,
                report: payload.report,
                columns: payload?.report?.columns || [],
                id: payload?.report?.id,
                report_status: payload.report ? payload.report.report_status : null
            };

            return {
                ...state,
                reports: {
                    ...state.reports,
                    [payload?.report?.id]: report
                },
                data: [
                    ...(state.data || []).filter(item => parseInt(item.id) !== parseInt(payload?.report?.id)),
                    payload.report
                ],
                progress: {
                    ...state.progress,
                    ...(payload.report.report_status !== STATUS_REPORT_READY &&
                        payload.report?.progress_percent &&
                        !state.progress[payload.report.id] && {
                            [payload.report.id]: {
                                id: payload.report.id,
                                progress: payload.report.progress_percent,
                            },
                        }),
                },
                interval: [
                    ...state.interval,
                    ...(payload.report.report_status !== STATUS_REPORT_READY &&
                    !state.interval.includes(payload.report.id)
                        ? [payload.report.id]
                        : []),
                ],
            };

        case actionTypes.SET_INTERVAL:
            const interval = [...state.interval, payload];
            const distincstInterval = [...new Set(interval)];
            return {
                ...state,
                interval: [
                    ...distincstInterval
                ]
            };

        case actionTypes.SET_REPORT_PROGRESS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    ...payload.reduce((obj, item) => {
                        obj[item.id] = item;
                        return obj;
                    }, {}),
                },
            };

        case actionTypes.RELOAD_REPORT:
            const newInterval = state.interval.filter(el => el !== payload);
            const newReport = {...state.reports};
            // delete newReport[payload];
            newReport[payload] = {
                ...newReport[payload],
                type: 'preload_tab'
            };
            const newData = state.data.map((report) => {
                if ( parseInt(report.id) === parseInt(payload) ) {
                    return {...report, type: 'preload_tab'};
                }
                return report;
            })

            return {
                ...state,
                interval: [
                    ...newInterval
                ],
                reports: {
                    ...newReport
                },
                data: newData,
            };

        case actionTypes.CRASH_REPORT:
            return {
                ...state,
                errorsReport: {
                    ...state.errorsReport,
                    [payload.id]: payload
                }
            }

        case actionTypes.SET_ORDER_COLUMNS:
            return {
                ...state,
                orderColumns: {
                    ...state.orderColumns,
                    [payload.id]: payload.params
                }
            }

        case actionTypes.REPORT_READY: {
            return {
                ...state,
                interval: state.interval.filter((id) => id !== payload.id),
                progress: Object.keys(state.progress)
                    .filter((key) => parseInt(key) !== parseInt(payload.id))
                    .reduce((obj, key) => {
                        obj[key] = state.progress[key];
                        return obj;
                    }, {}),
            };
        }

        default:
            return state;
    }
};

export default state;