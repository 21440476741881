import { featuresTypes as actionTypes } from '../types';

export const initialState = {
    data: {}
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.FEATURE_FETCH_SUCCESS:
            return {
                ...state,
                data: {
                    ...payload.data,
                },
            };

        default:
            return state;
    }
};

export default state;