export const ADD_REFILL_USER_BEGIN = 'Finance/AddRefillUserBegin';
export const ADD_REFILL_USER_SUCCESS = 'Finance/AddRefillUserSuccess';
export const ADD_REFILL_USER_FAILURE = 'Finance/AddRefillUserFailure';

export const FETCH_ACCOUNTS_LIST_BEGIN = 'Finance/FetchAccountsListBegin';
export const FETCH_ACCOUNTS_LIST_SUCCESS = 'Finance/FetchAccountsListSuccess';
export const FETCH_ACCOUNTS_LIST_FAILURE = 'Finance/FetchAccountsListFailure';

export const CREATE_PAYMENT_BEGIN = 'Finance/CreatePaymentBegin';
export const CREATE_PAYMENT_SUCCESS = 'Finance/CreatePaymentSuccess';
export const CREATE_PAYMENT_FAILURE = 'Finance/CreatePaymentFailure';

export const UPDATE_PAYMENT_BEGIN = 'Finance/UpdatePaymentBegin';
export const UPDATE_PAYMENT_SUCCESS = 'Finance/UpdatePaymentSuccess';
export const UPDATE_PAYMENT_FAILURE = 'Finance/UpdatePaymentFailure';

export const DELETE_PAYMENT_BEGIN = 'Finance/DeletePaymentBegin';
export const DELETE_PAYMENT_SUCCESS = 'Finance/DeletePaymentSuccess';
export const DELETE_PAYMENT_FAILURE = 'Finance/DeletePaymentFailure';

export const GET_PAYMENTS_BEGIN = 'Finance/GetPaymentsBegin';
export const GET_PAYMENTS_SUCCESS = 'Finance/GetPaymentsSuccess';
export const GET_PAYMENTS_FAILURE = 'Finance/GetPaymentsFailure';

