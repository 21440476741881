import { usersTypes as actionTypes } from '../types';
import { profileTypes as actionTypesProfiles } from '../types';

export const initialState = {
    usersData: {
        data: [],
        meta: {},
        isLoadData: false,
        isLoadingProcess: false
    },
    managers: [],
    all_users: [],
    // user: {},
    loading: false,
    viewedUsers: [],
    referralTraffic: [],
    referralTrafficLoading: false
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypesProfiles.REINCARNATION_SUCCESS:
            return {
                ...state,
                usersData: {
                    data: [],
                    meta: {},
                    params: {},
                    isLoadData: false,
                },
                managers: [],
                all_users: [],
                // user: {},
                viewedUsers: [],
                loading: false,
                referralTraffic: [],
                referralTrafficLoading: false
            };

        case actionTypes.USERS_FETCH_BEGIN:
            if ( state.usersData.isLoadingProcess ) {
                return {
                    ...state,
                    usersData: {
                        ...state.usersData,
                        params: payload,
                    },
                    loading: JSON.stringify(state.usersData.params) !== JSON.stringify(payload)
                };
            }
            let loading = !state.usersData.isLoadData;
            if ( JSON.stringify(state.usersData.params) !== JSON.stringify(payload) ) {
                loading = true;
            }
            return {
                ...state,
                usersData: {
                    ...state.usersData,
                    params: payload,
                    isLoadingProcess: true
                },
                loading
            };

        case actionTypes.USERS_FETCH_SUCCESS:
            return {
                ...state,
                usersData: {
                    ...state.usersData,
                    ...payload,
                    isLoadData: true,
                    isLoadingProcess: false,
                },
                loading: false,
            };

        case actionTypes.USERS_FETCH_FAILURE:
            const { message } = payload.error;
            if ( message && message === 'Operation canceled due to new request.' ) {
                return state;
            }
            return {
                ...state,
                loading: false,
                isLoadingProcess: false,
            }

        case actionTypes.USER_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
            }

        case actionTypes.MANAGERS_FETCH_SUCCESS:
            return {
                ...state,
                managers: [
                    ...payload
                ]
            };

        case actionTypes.USER_FETCH_BEGIN:
            return {
                ...state,
                loading: !state.viewedUsers.find(user => parseInt(user.id) === parseInt(payload)),
            };

        case actionTypes.USER_FETCH_SUCCESS:
            const oldViewedUsers = [...state.viewedUsers];
            let viewedUsers = oldViewedUsers.map(el => el.id === payload.id ? { ...payload } : el );

            if (!viewedUsers.find(item => item.id === payload.id)) {
                viewedUsers = viewedUsers.concat(payload);
            }

            return {
                ...state,
                // user: { ...payload },
                viewedUsers,
                loading: false,
            };

        case actionTypes.USER_UPDATE_SUCCESS:
        case actionTypes.USER_TOKEN_STATISTICS_SUCCESS:
            const managersList = state.managers.filter(item => parseInt(item.id) !== parseInt(payload.id));

            if (
                payload.data.roles.find(role => role.name === 'manager')
            ) {
                managersList.push(payload.data);
            }

            managersList.sort((a, b) => a.name - b.name);

            return {
                ...state,
                viewedUsers: state.viewedUsers.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : { ...item, ...payload.data }),
                managers: managersList,
            };

        case actionTypes.USER_ADD_SUCCESS:
            const managers = [...state.managers];
            if (payload.roles.find(role => role.name === 'manager')) {
                managers.push(payload);
            }
            return {
                ...state,
                viewedUsers: [
                    ...state.viewedUsers,
                    payload
                ],
                managers: managers
            };

        case actionTypes.USERS_ALL_FETCH_SUCCESS:
            return {
                ...state,
                all_users: [
                    ...payload
                ]
            };

        case actionTypes.USERS_UPDATE_SERVICE_DOMAINS_SUCCESS:
            return {
                ...state,
                viewedUsers: state.viewedUsers.map(item => {
                    if (parseInt(item.id) === parseInt(payload.id)) {
                        if ('undefined' === typeof item.params || item.params === null) {
                            item.params = {};
                        }
                        if ('undefined' === typeof item.params.service_domains) {
                            item.params.service_domains = {};
                        }
                        item.params.service_domains = payload.data.params.service_domains || [];
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.USERS_UPDATE_PAYMENT_REQUISITES_SUCCESS:
            return {
                ...state,
                viewedUsers: state.viewedUsers.map(item => {
                    if (parseInt(item.id) === parseInt(payload.id)) {
                        item.profile = payload.data.profile;
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.USERS_UPDATE_POSTBACK_SUCCESS:
            return {
                ...state,
                viewedUsers: state.viewedUsers.map(item => {
                    if (parseInt(item.id) === parseInt(payload.id) && payload.data.params && payload.data.params.profile) {
                        item.params.profile = payload.data.params.profile;
                        return {...item};
                    }
                    return item;
                })
            };

        case actionTypes.USERS_UPDATE_REFERRAL_TRAFFIC_SUCCESS:
            return {
                ...state,
                viewedUsers: state.viewedUsers.map(item => {
                    if (parseInt(item.id) === parseInt(payload.id) && 'undefined' !== typeof payload.data.referral_traffic_enable) {
                        item.referral_traffic = payload.data.referral_traffic_enable;
                        return {...item};
                    }
                    return item;
                }),
                referralTraffic: state.referralTraffic.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.id) ) {
                        return {id: payload.id, ...payload.data};
                    }
                    return item;
                }),
                referralTrafficLoading: false
            };

        case actionTypes.USERS_GET_REFERRAL_TRAFFIC_BEGIN:
        case actionTypes.USERS_UPDATE_REFERRAL_TRAFFIC_BEGIN:
            return {
                ...state,
                referralTrafficLoading: true
            };

        case actionTypes.USERS_GET_REFERRAL_TRAFFIC_SUCCESS:
            return {
                ...state,
                referralTraffic: [
                    ...state.referralTraffic.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    {id: payload.id, ...payload.data}
                ],
                referralTrafficLoading: false
            };

        case actionTypes.USERS_GET_REFERRAL_TRAFFIC_FAILURE:
        case actionTypes.USERS_UPDATE_REFERRAL_TRAFFIC_FAILURE:
            return {
                ...state,
                referralTrafficLoading: false
            };

        case actionTypes.USERS_GROUP_BEGIN:
            return {
                ...state,
                loading: true
            };

        case actionTypes.USERS_GROUP_FAILURE:
            return {
                ...state,
                loading: false
            };

        case actionTypes.USERS_GROUP_SUCCESS:
            // TODO update users data
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};

export default state;