import axios from '../../core/axiosInit';

import { actionsTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries } from '../helpers';

export const fetchActions = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.ACTIONS_FETCH_BEGIN) );
    try {
        const response = await axios.get(`actions`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.ACTIONS_FETCH_SUCCESS, {
            data: response.data.data,
            meta: response.data.meta
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ACTIONS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchAction = (id, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.ACTION_FETCH_BEGIN, id) );
    try {
        const response = await axios.get(`actions/${id}`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.ACTION_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ACTION_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addAction = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.ACTION_ADD_BEGIN) );
    try {
        const response = await axios.post(`actions`, {...params});
        dispatch( queryAction(actionTypes.ACTION_ADD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ACTION_ADD_FAILURE, { params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateAction = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ACTION_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`actions/${id}`, {...params});
        dispatch( queryAction(actionTypes.ACTION_UPDATE_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ACTION_UPDATE_FAILURE, { id, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteAction = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ACTION_DELETE_BEGIN, id) );
    try {
        const response = await axios.delete(`actions/${id}`);
        dispatch( queryAction(actionTypes.ACTION_DELETE_SUCCESS, {id: id}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ACTION_DELETE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changeFeatureStatus = ({feature_asset_type_id, asset_id, params}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.ACTION_FEATURE_STATUS_CHANGE_BEGIN, {feature_asset_type_id, asset_id}) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        dispatch( queryAction(actionTypes.ACTION_FEATURE_STATUS_CHANGE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ACTION_FEATURE_STATUS_CHANGE_FAILURE, { feature_asset_type_id, asset_id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchMassSharingActions = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.MASS_SHARING_ACTIONS_FETCH_BEGIN) );
    try {
        const response = await axios.get(`actions/shared`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.MASS_SHARING_ACTIONS_FETCH_SUCCESS, {
            data: response.data.data,
            meta: response.data.meta
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.MASS_SHARING_ACTIONS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateMassSharingActions = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.MASS_SHARING_ACTIONS_UPDATE_BEGIN) );
    try {
        const response = await axios.put(`actions/shared`, {...params});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.MASS_SHARING_ACTIONS_UPDATE_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.MASS_SHARING_ACTIONS_UPDATE_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};