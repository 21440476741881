export const DICTIONARIES_ASSET_TYPES_FETCH_BEGIN = 'Dictionaries/AssetTypesFetchBegin';
export const DICTIONARIES_ASSET_TYPES_FETCH_SUCCESS = 'Dictionaries/AssetTypesFetchSuccess';
export const DICTIONARIES_ASSET_TYPES_FETCH_FAILURE = 'Dictionaries/AssetTypesFetchFailure';

export const DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_BEGIN = 'Dictionaries/FeatureAssetTypesFetchBegin';
export const DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_SUCCESS = 'Dictionaries/FeatureAssetTypesFetchSuccess';
export const DICTIONARIES_FEATURE_ASSET_TYPES_FETCH_FAILURE = 'Dictionaries/FeatureAssetTypesFetchFailure';

export const DICTIONARIES_LIMITS_FETCH_BEGIN = 'Dictionaries/LimitsFetchBegin';
export const DICTIONARIES_LIMITS_FETCH_SUCCESS = 'Dictionaries/LimitsFetchSuccess';
export const DICTIONARIES_LIMITS_FETCH_FAILURE = 'Dictionaries/LimitsFetchFailure';

export const DICTIONARIES_ROLES_FETCH_BEGIN = 'Dictionaries/RolesFetchBegin';
export const DICTIONARIES_ROLES_FETCH_SUCCESS = 'Dictionaries/RolesFetchSuccess';
export const DICTIONARIES_ROLES_FETCH_FAILURE = 'Dictionaries/RolesFetchFailure';

export const DICTIONARIES_FEATURES_FETCH_BEGIN = 'Dictionaries/FeaturesFetchBegin';
export const DICTIONARIES_FEATURES_FETCH_SUCCESS = 'Dictionaries/FeaturesFetchSuccess';
export const DICTIONARIES_FEATURES_FETCH_FAILURE = 'Dictionaries/FeaturesFetchFailure';

export const DICTIONARIES_PLACEMENTTYPES_FETCH_BEGIN = 'Dictionaries/PlacementTypesFetchBegin';
export const DICTIONARIES_PLACEMENT_TYPES_FETCH_SUCCESS = 'Dictionaries/PlacementTypesFetchSuccess';
export const DICTIONARIES_PLACEMENT_TYPES_FETCH_FAILURE = 'Dictionaries/PlacementTypesFetchFailure';

export const DICTIONARIES_PRICING_TYPES_FETCH_BEGIN = 'Dictionaries/PricingTypesFetchBegin';
export const DICTIONARIES_PRICING_TYPES_FETCH_SUCCESS = 'Dictionaries/PricingTypesFetchSuccess';
export const DICTIONARIES_PRICING_TYPES_FETCH_FAILURE = 'Dictionaries/PricingTypesFetchFailure';

export const DICTIONARIES_COUNTRIES_FETCH_BEGIN = 'Dictionaries/ContriesFetchBegin';
export const DICTIONARIES_COUNTRIES_FETCH_SUCCESS = 'Dictionaries/ContriesFetchSuccess';
export const DICTIONARIES_COUNTRIES_FETCH_FAILURE = 'Dictionaries/ContriesFetchFailure';

export const DICTIONARIES_DEVICES_FETCH_BEGIN = 'Dictionaries/DevicesFetchBegin';
export const DICTIONARIES_DEVICES_FETCH_SUCCESS = 'Dictionaries/DevicesFetchSuccess';
export const DICTIONARIES_DEVICES_FETCH_FAILURE = 'Dictionaries/DevicesFetchFailure';

export const DICTIONARIES_SYSTEMS_FETCH_BEGIN = 'Dictionaries/SystemsFetchBegin';
export const DICTIONARIES_SYSTEMS_FETCH_SUCCESS = 'Dictionaries/SystemsFetchSuccess';
export const DICTIONARIES_SYSTEMS_FETCH_FAILURE = 'Dictionaries/SystemsFetchFailure';

export const DICTIONARIES_CURRENCIES_FETCH_SUCCESS = 'Dictionaries/CurrenciesFetchSuccess';
export const DICTIONARIES_CREATIVE_TYPES_FETCH_SUCCESS = 'Dictionaries/CreativeTypesFetchSuccess';
export const DICTIONARIES_GROUP_BY_FETCH_SUCCESS = 'Dictionaries/GroupByFetchSuccess';
export const DICTIONARIES_REPORT_METRICS_FETCH_SUCCESS = 'Dictionaries/ReportMetricsFetchSuccess';
export const DICTIONARIES_CATEGORIES_FETCH_SUCCESS = 'Dictionaries/CategoryFetchSuccess';

export const DICTIONARIES_DIMENSIONS_FETCH_SUCCESS = 'Dictionaries/DimensionsFetchSuccess';

export const DICTIONARIES_DIMENSIONS_DATE_FETCH_SUCCESS = 'Dictionaries/DimensionsDateFetchSuccess';

export const DICTIONARIES_TAGS_FETCH_BEGIN = 'Dictionaries/TagsFetchBegin';
export const DICTIONARIES_TAGS_FETCH_SUCCESS = 'Dictionaries/TagsFetchSuccess';
export const DICTIONARIES_TAGS_FETCH_FAILURE = 'Dictionaries/TagsFetchFailure';

export const DICTIONARIES_FETCH_BEGIN = 'Dictionaries/FetchBegin';
export const DICTIONARIES_FETCH_SUCCESS = 'Dictionaries/FetchSuccess';
export const DICTIONARIES_FETCH_FAILURE = 'Dictionaries/FetchFailure';

export const DICTIONARIES_FILTERED_FETCH_BEGIN = 'Dictionaries/FilteredFetchBegin';
export const DICTIONARIES_FILTERED_FETCH_SUCCESS = 'Dictionaries/FilteredFetchSuccess';
export const DICTIONARIES_FILTERED_FETCH_FAILURE = 'Dictionaries/FilteredFetchFailure';

export const DICTIONARIES_LANGUAGES_FETCH_BEGIN = 'Dictionaries/LanguagesFetchBegin';
export const DICTIONARIES_LANGUAGES_FETCH_SUCCESS = 'Dictionaries/LanguagesFetchSuccess';
export const DICTIONARIES_LANGUAGES_FETCH_FAILURE = 'Dictionaries/LanguagesFetchFailure';