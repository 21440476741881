export const CAMPAIGNS_FETCH_BEGIN = 'Campaigns/FetchListBegin';
export const CAMPAIGNS_FETCH_SUCCESS = 'Campaigns/FetchListSuccess';
export const CAMPAIGNS_FETCH_FAILURE = 'Campaigns/FetchListFailure';

export const CAMPAIGN_FETCH_BEGIN = 'Campaigns/FetchCampaignBegin';
export const CAMPAIGN_FETCH_SUCCESS = 'Campaigns/FetchCampaignSuccess';
export const CAMPAIGN_FETCH_FAILURE = 'Campaigns/FetchCampaignFailure';

export const CAMPAIGN_ADD_BEGIN = 'Campaigns/AddCampaignBegin';
export const CAMPAIGN_ADD_SUCCESS = 'Campaigns/AddCampaignSuccess';
export const CAMPAIGN_ADD_FAILURE = 'Campaigns/AddCampaignFailure';

export const CAMPAIGN_UPDATE_BEGIN = 'Campaigns/UpdateCampaignBegin';
export const CAMPAIGN_UPDATE_SUCCESS = 'Campaigns/UpdateCampaignSuccess';
export const CAMPAIGN_UPDATE_FAILURE = 'Campaigns/UpdateCampaignFailure';

export const CAMPAIGN_DELETE_BEGIN = 'Campaigns/DeleteCampaignBegin';
export const CAMPAIGN_DELETE_SUCCESS = 'Campaigns/DeleteCampaignSuccess';
export const CAMPAIGN_DELETE_FAILURE = 'Campaigns/DeleteCampaignFailure';

export const CAMPAIGN_RESTORE_BEGIN = 'Campaigns/RestoreCampaignBegin';
export const CAMPAIGN_RESTORE_SUCCESS = 'Campaigns/RestoreCampaignSuccess';
export const CAMPAIGN_RESTORE_FAILURE = 'Campaigns/RestoreCampaignFailure';

export const CAMPAIGN_CREATIVES_FETCH_BEGIN = 'Campaigns/FetchCreativesBegin';
export const CAMPAIGN_CREATIVES_FETCH_SUCCESS = 'Campaigns/FetchCreativesSuccess';
export const CAMPAIGN_CREATIVES_FETCH_FAILURE = 'Campaigns/FetchCreativesFailure';

export const CAMPAIGN_CREATIVE_FETCH_BEGIN = 'Campaigns/FetchCreativeBegin';
export const CAMPAIGN_CREATIVE_FETCH_SUCCESS = 'Campaigns/FetchCreativeSuccess';
export const CAMPAIGN_CREATIVE_FETCH_FAILURE = 'Campaigns/FetchCreativeFailure';

export const CAMPAIGN_CREATIVE_DELETE_BEGIN = 'Campaigns/DeleteCreativeCampaignBegin';
export const CAMPAIGN_CREATIVE_DELETE_SUCCESS = 'Campaigns/DeleteCreativeCampaignSuccess';
export const CAMPAIGN_CREATIVE_DELETE_FAILURE = 'Campaigns/DeleteCreativeCampaignFailure';

export const CAMPAIGN_CREATIVE_RESTORE_BEGIN = 'Campaigns/RestoreCreativeCampaignBegin';
export const CAMPAIGN_CREATIVE_RESTORE_SUCCESS = 'Campaigns/RestoreCreativeCampaignSuccess';
export const CAMPAIGN_CREATIVE_RESTORE_FAILURE = 'Campaigns/RestoreCreativeCampaignFailure';

export const CAMPAIGN_CREATIVE_ADD_BEGIN = 'Campaigns/AddCreativeBegin';
export const CAMPAIGN_CREATIVE_ADD_SUCCESS = 'Campaigns/AddCreativeSuccess';
export const CAMPAIGN_CREATIVE_ADD_FAILURE = 'Campaigns/AddCreativeFailure';

export const CAMPAIGN_CREATIVE_UPDATE_BEGIN = 'Campaigns/UpdateCreativeBegin';
export const CAMPAIGN_CREATIVE_UPDATE_SUCCESS = 'Campaigns/UpdateCreativeSuccess';
export const CAMPAIGN_CREATIVE_UPDATE_FAILURE = 'Campaigns/UpdateCreativeFailure';

export const CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_BEGIN = 'Campaigns/ChangeCreativeFeatureStatusBegin';
export const CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_SUCCESS = 'Campaigns/ChangeCreativeFeatureStatusSuccess';
export const CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_FAILURE = 'Campaigns/ChangeCreativeFeatureStatusFailure';

export const CAMPAIGN_FEATURE_STATUS_CHANGE_BEGIN = 'Campaigns/ChangeFeatureStatusBegin';
export const CAMPAIGN_FEATURE_STATUS_CHANGE_SUCCESS = 'Campaigns/ChangeFeatureStatusSuccess';
export const CAMPAIGN_FEATURE_STATUS_CHANGE_FAILURE = 'Campaigns/ChangeFeatureStatusFailure';

export const CAMPAIGN_CREATIVE_PRELOAD_VIDEO_BEGIN = 'Campaigns/PreloadVideoCreativeBegin';
export const CAMPAIGN_CREATIVE_PRELOAD_VIDEO_SUCCESS = 'Campaigns/PreloadVideoCreativeSuccess';
export const CAMPAIGN_CREATIVE_PRELOAD_VIDEO_FAILURE = 'Campaigns/PreloadVideoCreativeFailure';

export const CAMPAIGN_COPY_BEGIN = 'Campaigns/CopyBegin';
export const CAMPAIGN_COPY_SUCCESS = 'Campaigns/CopySuccess';
export const CAMPAIGN_COPY_FAILURE = 'Campaigns/CopyFailure';

export const CAMPAIGN_CREATIVE_COPY_BEGIN = 'Campaigns/CopyCreativeBegin';
export const CAMPAIGN_CREATIVE_COPY_SUCCESS = 'Campaigns/CopyCreativeSuccess';
export const CAMPAIGN_CREATIVE_COPY_FAILURE = 'Campaigns/CopyCreativeFailure';
