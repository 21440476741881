export const TREE_CHANNELS_FETCH_BEGIN = 'Tree/ChannelsFetchBegin';
export const TREE_CHANNELS_FETCH_SUCCESS = 'Tree/ChannelsFetchSuccess';
export const TREE_CHANNELS_FETCH_FAILURE = 'Tree/ChannelsFetchFailure';

export const TREE_CHANNELS_ALL_FETCH_BEGIN = 'Tree/AllChannelsFetchBegin';
export const TREE_CHANNELS_ALL_FETCH_SUCCESS = 'Tree/AllChannelsFetchSuccess';
export const TREE_CHANNELS_ALL_FETCH_FAILURE = 'Tree/AllChannelsFetchFailure';

export const TREE_PLACEMENTS_FETCH_BEGIN = 'Tree/PlacementsFetchBegin';
export const TREE_PLACEMENTS_FETCH_SUCCESS = 'Tree/PlacementsFetchSuccess';
export const TREE_PLACEMENTS_FETCH_FAILURE = 'Tree/PlacementsFetchFailure';

export const TREE_PROJECTS_FETCH_BEGIN = 'Tree/ProjectsFetchBegin';
export const TREE_PROJECTS_FETCH_SUCCESS = 'Tree/ProjectsFetchSuccess';
export const TREE_PROJECTS_FETCH_FAILURE = 'Tree/ProjectsFetchFailure';

export const TREE_PROJECTS_ALL_FETCH_BEGIN = 'Tree/AllProjectsFetchBegin';
export const TREE_PROJECTS_ALL_FETCH_SUCCESS = 'Tree/AllProjectsFetchSuccess';
export const TREE_PROJECTS_ALL_FETCH_FAILURE = 'Tree/AllProjectsFetchFailure';

export const TREE_CAMPAIGNS_FETCH_BEGIN = 'Tree/CampaignsFetchBegin';
export const TREE_CAMPAIGNS_FETCH_SUCCESS = 'Tree/CampaignsFetchSuccess';
export const TREE_CAMPAIGNS_FETCH_FAILURE = 'Tree/CampaignsFetchFailure';

export const TREE_CREATIVES_FETCH_BEGIN = 'Tree/CreativesFetchBegin';
export const TREE_CREATIVES_FETCH_SUCCESS = 'Tree/CreativesFetchSuccess';
export const TREE_CREATIVES_FETCH_FAILURE = 'Tree/CreativesFetchFailure';

export const TREE_DATA_UPDATE = 'Tree/UpdateData';

export const TREE_GROUP_BEGIN = 'Tree/GroupOperationsFetchBegin';
export const TREE_GROUP_SUCCESS = 'Tree/GroupOperationsFetchSuccess';
export const TREE_GROUP_FAILURE = 'Tree/GroupOperationsFetchFailure';

export const TREE_ITEM_STATUS_UPDATE = 'Tree/ItemStatusUpdate';

export const TREE_LOAD_INDEX_BEGIN = 'Tree/LoadIndexBegin';
export const TREE_LOAD_INDEX_SUCCESS = 'Tree/LoadIndexSuccess';
export const TREE_LOAD_INDEX_FAILURE = 'Tree/LoadIndexFailure';

export const TREE_PROJECTS_SET_FILTERS = 'Tree/ProjectsSetFilter';
export const TREE_PROJECTS_SET_FILTERS_SELECTED = 'Tree/ProjectsSetFilterSelected';
export const TREE_PROJECTS_UPDATE_FILTERS = 'Tree/ProjectsUpdateFilter';

export const TREE_CHANNELS_SET_FILTERS = 'Tree/ChannelsSetFilter';
export const TREE_CHANNELS_SET_FILTERS_SELECTED = 'Tree/ChannelsSetFilterSelected';
export const TREE_CHANNELS_UPDATE_FILTERS = 'Tree/ChannelsUpdateFilter';