export const FEATURE_FETCH_BEGIN = 'Fetures/FetchBegin';
export const FEATURE_FETCH_SUCCESS = 'Fetures/FetchSuccess';
export const FEATURE_FETCH_FAILURE = 'Fetures/FetchFailure';

export const FEATURE_UPDATE_BEGIN = 'Fetures/UpdateBegin';
export const FEATURE_UPDATE_SUCCESS = 'Fetures/UpdateSuccess';
export const FEATURE_UPDATE_FAILURE = 'Fetures/UpdateFailure';

export const RESET_COUNTER_LIMIT_BEGIN = 'Feature/ResetCounterBegin';
export const RESET_COUNTER_LIMIT_SUCCESS = 'Feature/ResetCounterSuccess';
export const RESET_COUNTER_LIMIT_FAILURE = 'Feature/ResetCounterFailure';
