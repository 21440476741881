import axios from '../../core/axiosInit';
import { feedtemplatesTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries } from '../helpers';

export const fetchFeedTemplates = (params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.FEEDTEMPLATES_FETCH_BEGIN) );
    try {

        const response = await axios.get(`feed-templates`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.FEEDTEMPLATES_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.FEEDTEMPLATES_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};
export const createFeedTemplate = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.FEEDTEMPLATES_CREATE_BEGIN) );
    try {

        const response = await axios.post(`feed-templates`, {...params});

        dispatch( queryAction(actionTypes.FEEDTEMPLATES_CREATE_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.FEEDTEMPLATES_CREATE_FAILURE, { error, params }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateFeedTemplate = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.FEEDTEMPLATES_UPDATE_BEGIN, id) );
    try {

        const response = await axios.put(`feed-templates/${id}`, {...params});

        dispatch( queryAction(actionTypes.FEEDTEMPLATES_UPDATE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.FEEDTEMPLATES_UPDATE_FAILURE, { id, error, params }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};