export const dateRange = [
    {id: 1, name: "Today", value: "today" },
    {id: 2, name: "Yesterday", value: "yesterday" },
    {id: 3, name: "Current week", value: "current_week" },
    {id: 4, name: "Current month", value: "current_month" },
    {id: 5, name: "Last 7 days", value: "last_seven_days" },
    {id: 6, name: "Last 30 days", value: "last_thirty_days" },
    {id: 7, name: "Last month", value: "last_month" },
    {id: 8, name: "Last 3 months", value: "last_three_months" },
    {id: 9, name: "Last 6 months", value: "last_six_months" },
    {id: 10, name: "Current year", value: "current_year" },
    {id: 11, name: "Last year", value: "last_year" },
]

export const typeOfReport = [
    {id: 1, name: "General"},
    // {id: 2, name: "Browser & OS"},
    // {id: 3, name: "Targeting Cookie"},
    // {id: 4, name: "3rd Party Fill Rate"},
    // {id: 5, name: "Today"},
];

export const breakdown = [
    {id: 1, name: "None"},
    {id: 2, name: "Hour"},
    {id: 3, name: "Day"},
    {id: 4, name: "Month"},
    {id: 5, name: "Year"},
    {id: 6, name: "24 Hours"},
];

export const group = [
    {id: 1, name: "Site"},
    {id: 3, name: "Placement"},
    {id: 4, name: "Placement Size"},
    {id: 5, name: "Placement Label"},
    {id: 6, name: "Advertiser"},
    {id: 7, name: "Campaign"},
    {id: 8, name: "Banner"},
    {id: 9, name: "Banner Label"},
    {id: 10, name: "Channel"},
    {id: 11, name: "Country"},
    {id: 12, name: "Action"},
];

export const countriesList = [
    {id: 0, name: "Country"},
    {id: 1, name: 'Afganistan'},
    {id: 21, name: "Austria"},
    {id: 2, name: 'Albania'},
    {id: 34, name: "Belgium"},
    {id: 3, name: 'Bulgaria'},
    {id: 4, name: 'Canada'},
    {id: 5, name: 'Denmark'},
    {id: 84, name: "Italy"},
    {id: 44, name: "Spain"},
    {id: 65, name: "Ukraine"},
    {id: 67, name: "Uganda"}
];

export const placementType = [
    {id: 0, name: "Site Placement"},
    {id: 1, name: "Non-standard Placement"},
    {id: 2, name: "Mobile Site Placement"},
    {id: 3, name: "Video Placement"},
    {id: 4, name: "Application Placement"},
    {id: 5, name: "Email Placement"},
    {id: 6, name: "Click-through Placement"},
];

export const placementSize = [
    {id: 0, name: "320x250"},
    {id: 1, name: "180x150"},
    {id: 2, name: "728x90"},
    {id: 3, name: "468x60"},
    {id: 4, name: "160x600"},
    {id: 5, name: "300x600"},
    {id: 6, name: "120x60"},
    {id: 7, name: "88x31"},
];

export const actionFilter = [
    {id: 1, name: "AD COMPLETE"},
    {id: 2, name: "AD CREATIVE VIEW"},
    {id: 3, name: "AD FIRST QUARTILE"},
    {id: 4, name: "AD FULLSCREEN"},
    {id: 5, name: "AD MID POINT"},
    {id: 6, name: "24 MUTE"},
];

export const publisherFilter = [
    {id: 0, value: ['channels', 'placements'],  name: "Channels/Placements"},
    {id: 1, value: ['channels'],  name: "Channels"},
    {id: 2, value: ['placements'], name: "Placements"},
];

export const advertisersFilter = [
    {id: 0, value: ['projects', 'campaigns', 'creatives'],  name: "Projects/Campaigns/Creatives"},
    {id: 1, value: ['projects'],  name: "Projects"},
    {id: 2, value: ['campaigns'],  name: "Campaigns"},
    {id: 3, value: ['creatives'], name: "Creatives"},
];

export const categoryFilter = [
    {id: 'all', type: 'all', name: "All"},
    {id: 'without_category', type: 'without_category', name: "All Without Category"},
];

export const tagsFilterArr = [
    {id: 'all', type: 'all', name: "All"},
    {id: 'without_tags', type: 'without_tags', name: "All Without Tags"},
]

export const sharingType = [
    {id: 1, name: "All"},
    {id: 2, name: "Is Owner"},
    {id: 3, name: "Can Edit"},
    {id: 4, name: "Can View"},
];

export const publisherAnalytics = [
    {id: 1, name: "Impressions", value: "impressions"},    
    {id: 2, name: "Clicks", value: "clicks"},    
    {id: 3, name: "CTR", value: "ctr"},    
    {id: 4, name: "Requests", value: "requests"},    
    {id: 5, name: "Fill Rate", value: "fill_rate"},    
    {id: 6, name: "Conversions", value: "conversions"},    
    {id: 7, name: "I2C", value: "i2c"},    
    {id: 8, name: "CCR", value: "ccr"},    
    {id: 9, name: "Revenue", value: "revenue"},    
    {id: 10, name: "eCPM", value: "ecpm"},  
];

export const advertiserAnalytics = [
    {id: 1, name: "Impressions", value: "impressions"},    
    {id: 2, name: "Clicks", value: "clicks"},    
    {id: 3, name: "CTR", value: "ctr"},    
    {id: 4, name: "Requests", value: "requests"},    
    {id: 5, name: "Fill Rate", value: "fill_rate"},    
    {id: 6, name: "Conversions", value: "conversions"},    
    {id: 7, name: "I2C", value: "i2c"},    
    {id: 8, name: "CCR", value: "ccr"},    
    {id: 9, name: "Charges", value: "charges"},    
    {id: 10, name: "eCPM", value: "ecpm"},  
];

export const sizePerPageList = [ 
    { text: '25', value: 25},
    { text: '50', value: 50},
    { text: '100', value: 100},
    { text: '500', value: 500},
];

export const mapFiltersKeys = [ 
    // { value: 'timezone', title: 'Timezone', dictionary: 'timezone', type: 'string', id: 'tzCode', name: 'label' },
    { value: 'dimensions', title: 'Group By', dictionary: 'dimensions', type: 'array', id: 'key', name: 'name' },
    { value: 'dimension_date', title: 'Breakdown By', dictionary: 'dimensions_date', type: 'array', id: 'value', name: 'name' },
    { value: 'placement_type', title: 'Placement type', dictionary: 'placement_types', type: 'number', id: 'id', name: 'name' },
    { value: 'countries', title: 'Country', dictionary: 'countries', type: 'array', id: 'code', name: 'name_en'  },
    { value: 'creative_tags', title: 'Creative tags', dictionary: 'asset_tags', type: 'array', id: 'id', name: 'name' },
    { value: 'placement_tags', title: 'Placement tags', dictionary: 'asset_tags', type: 'array', id: 'id', name: 'name' },
    { value: 'publishers', title: 'Publishers (placements IDs)', dictionary: 'publishers', type: 'filter' },
    { value: 'advertisers', title: 'Advertisers (creatives IDs)', dictionary: 'advertisers', type: 'filter' },
    { value: 'action_id', title: 'Actions', dictionary: 'actions', type: 'array', id: 'id', name: 'name' },
    { value: 'traffic_format_id', title: 'Traffic format', dictionary: 'trafficFormats', type: 'number', id: 'id', name: 'name' },
    { value: 'syntetics', title: 'Syntetic', dictionary: 'syntetics', type: 'array', id: 'id', name: 'syntetic_id' },
];