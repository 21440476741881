export const arraysContainSame = (a, b) => {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every(el => b.includes(el));
};

export const arrayContainSameOfArray = (arr, inArray) => {
    arr = Array.isArray(arr) ? arr : [];
    inArray = Array.isArray(inArray) ? inArray : [];
    return arr.every(el => inArray.includes(el));
};

export const arrayContainSomeOfArray = (arr1, arr2) => {
    arr1 = Array.isArray(arr1) ? arr1 : [];
    arr2 = Array.isArray(arr2) ? arr2 : [];
    return arr1.some(el => arr2.includes(el));
};

export const removeFromArrayByArray = ( arr, toRemove ) => {
    arr = Array.isArray(arr) ? arr : [];
    toRemove = Array.isArray(toRemove) ? toRemove : [];
    return arr.filter( ( el ) => !toRemove.includes( el ) );
}

export const mergeArrays = ( ...arrays ) => {
    let jointArray = []
    arrays.forEach(array => {
        jointArray = [...jointArray, ...array];
    });
    return uniqueArray([...jointArray]);
}

export const uniqueArray = array => [...new Set(array)]

export const groupedDimensions = (dimensions) => {
    let output = [];
    const combineDimensions = dimensions.reduce((prev, current) => {
        prev.push({
            label: current.type,
            options: [...prev[current.type] || [], {
                ...current,
                value: current.value,
                label: current.name,
                key: current.key
            }]
        });
        return prev;
    }, []);

    combineDimensions.forEach((item) => {
        let existing = output.filter((v, i) => {
            return v.label === item.label;
        });
        if (existing.length) {
            let existingIndex = output.indexOf(existing[0]);
            output[existingIndex].options = output[existingIndex].options.concat(item.options);
        } else {
            if (typeof item.options === 'string')
                item.options = [item.options];
            output.push(item);
        }
    });

    output.map(item => {
        if (item.label === 'asset') {
            item.options = item.options.sort( (a, b) => a.order - b.order )
        }
        return item
    })

    return output;
};

 /**
 * Function to sort alphabetically an array of objects by some specific key.
 * usage: array.sort( dynamicSort('-name') )
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = (property) => {
    let sortOrder = 1;

    if ( property[0] === "-" ) {
        sortOrder = -1;
        property = property.substr(1);
    }

    return (a, b) => {
        if ( sortOrder === -1 ){
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }
    }
};

export const sortByKey = (array, key) => {
    return array.sort( dynamicSort(key) );
};

export const checkArrayValuesByType = (arr, type = 'string') => {
    return arr.every(i => typeof i === type);
};

export const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
};

export const groupByKey = (arr, key) => {
    return arr.reduce(function (prev, current, index) {
        if (!index || prev[prev.length - 1][0][key] !== current[key]) {
            return prev.concat([[current]]);
        }
        prev[prev.length - 1].push(current);
        return prev;
    }, []);
};

export const arrayItemMaxLength = (arr) => {
    return arr.reduce((result, item) => {
        return Math.max(result, item.length);
    }, 0);
};