export const EXCHANGE_CURRENCIES_FETCH_BEGIN = "Exchange/FetchExchangeCurrenciesBegin";
export const EXCHANGE_CURRENCIES_FETCH_SUCCESS = "Exchange/FetchExchangeCurrenciesSuccess";
export const EXCHANGE_CURRENCIES_FETCH_FAILURE = "Exchange/FetchExchangeCurrenciesFailure";

export const PAYMENT_SYSTEM_FETCH_BEGIN = "Exchange/FetchPaymentSystemBegin";
export const PAYMENT_SYSTEM_FETCH_SUCCESS = "Exchange/FetchPaymentSystemSuccess";
export const PAYMENT_SYSTEM_FETCH_FAILURE = "Exchange/FetchPaymentSystemFailure";

export const PAYMENT_SYSTEM_CREATE_BEGIN = "Exchange/CreatePaymentSystemBegin";
export const PAYMENT_SYSTEM_CREATE_SUCCESS = "Exchange/CreatePaymentSystemSuccess";
export const PAYMENT_SYSTEM_CREATE_FAILURE = "Exchange/CreatePaymentSystemFailure";

export const PAYMENT_SYSTEM_UPDATE_BEGIN = "Exchange/UpdatePaymentSystemBegin";
export const PAYMENT_SYSTEM_UPDATE_SUCCESS = "Exchange/UpdatePaymentSystemSuccess";
export const PAYMENT_SYSTEM_UPDATE_FAILURE = "Exchange/UpdatePaymentSystemFailure";

export const PAYMENT_SYSTEM_DELETE_BEGIN = "Exchange/DeletePaymentSystemBegin";
export const PAYMENT_SYSTEM_DELETE_SUCCESS = "Exchange/DeletePaymentSystemSuccess";
export const PAYMENT_SYSTEM_DELETE_FAILURE = "Exchange/DeletePaymentSystemFailure";
