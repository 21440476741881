import axios from '../../core/axiosInit';

import { usersTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import checkPermission from "../../core/checkPermiss";
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';

export const fetchUsers = params => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.USERS_FETCH_BEGIN, params) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = [];
        if ( checkPermission('user status show') ) {
            dictionaries.push('status');
        }
        if ( checkPermission('user.edit.role.show') ) {
            dictionaries.push('role');
        }
        if ( dictionaries.length ) {
            params = requestAssignDictionaries(dicts, dictionaries, params);
        }

        const response = await axios.get(`users`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        if ( 'undefined' !== typeof response.data.meta.dictionaries ) {
            delete response.data.meta.dictionaries;
        }
        dispatch( queryAction(actionTypes.USERS_FETCH_SUCCESS, 
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USERS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchManagers = () => async (dispatch, getState) => {
    const { Users: { managers } } = getState();
    if ( managers.length ) {
        return false;
    }
    dispatch( queryAction(actionTypes.MANAGERS_FETCH_BEGIN) );
    try {
        const params = { filters: { user_type: 'manager' } };
        const response = await axios.get(`users`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.MANAGERS_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.MANAGERS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
    }
};

export const fetchUser = (id) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.USER_FETCH_BEGIN, id) );
    try {
        let params = {};
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['PaymentSystem', 'service_domain', 'payment_amount', 'payment_period'];
        if ( checkPermission('user status show') ) {
            dictionaries.push('status');
        }
        // if ( checkPermission('user.edit.role.show') ) {
            dictionaries.push('role');
        // }
        if ( dictionaries.length ) {
            params = requestAssignDictionaries(dicts, dictionaries, params);
        }

        const response = await axios.get(`user/${id}`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.USER_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USER_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addUser = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.USER_ADD_BEGIN) );
    try {
        const response = await axios.post(`user`, {...params});
        dispatch( queryAction(actionTypes.USER_ADD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USER_ADD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateUser = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.USER_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`user/${id}`, {...params});
        dispatch( queryAction(actionTypes.USER_UPDATE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USER_UPDATE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

// export const deleteUser = (id) => async (dispatch) => {
//     dispatch( queryAction(actionTypes.USER_DELETE_BEGIN, id) );
//     try {
//         const response = await axios.delete(`user/${id}`);
//         dispatch( queryAction(actionTypes.USER_DELETE_SUCCESS, {id: id, data: response.data.data[0]}) );
//         return response.data ? response.data.success : false;
//     } catch (error) {
//         dispatch( queryAction(actionTypes.USER_DELETE_FAILURE, { id, error }) );
//         dispatch( checkAuthError(error) );
//         return checkResponseError(error);
//     }
// };

// export const restoreUser = (id) => async (dispatch) => {
//     dispatch( queryAction(actionTypes.USER_RESTORE_BEGIN, id) );
//     try {
//         const response = await axios.put(`user/${id}`);
//         dispatch( queryAction(actionTypes.USER_RESTORE_SUCCESS, {id: id, data: response.data.data[0]}) );
//         return response.data;
//     } catch (error) {
//         dispatch( queryAction(actionTypes.USER_RESTORE_FAILURE, { id, error }) );
//         dispatch( checkAuthError(error) );
//         return checkResponseError(error);
//     }
// };

export const groupActionsUsers = (action, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.USERS_GROUP_BEGIN, action) );
    try {
        let response;
        if ( action === 'delete' ) {
            response = await axios.delete(`users`, {data: {...params}});
        } else if ( action === 'restore' ) {
            response = await axios.put(`users`, {...params});
        } else {
            response = await axios.patch(`users`, params);
        }
        dispatch( queryAction(actionTypes.USERS_GROUP_SUCCESS, {action: action, data: response.data.data}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USERS_GROUP_FAILURE, { action, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchAllUsers = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.USERS_ALL_FETCH_BEGIN) ); 
    try {
        const response = await axios.get(`users-all`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.USERS_ALL_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch(error) {
        dispatch( queryAction(actionTypes.USERS_ALL_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};


export const updateApiToken = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.USER_TOKEN_STATISTICS_BEGIN, id) );
    try {
        const response = await axios.put(`users-api-token-update/${id}`, {...params});
        dispatch( queryAction(actionTypes.USER_TOKEN_STATISTICS_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USER_TOKEN_STATISTICS_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updatePaymentRequisites = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.USERS_UPDATE_PAYMENT_REQUISITES_BEGIN, id) );
    try {
        const response = await axios.put(`user/${id}/payment-requisites`, {...params});
        dispatch( queryAction(actionTypes.USERS_UPDATE_PAYMENT_REQUISITES_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USERS_UPDATE_PAYMENT_REQUISITES_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateServiceDomains = (id, params) => async (dispatch) => {
        dispatch( queryAction(actionTypes.USERS_UPDATE_SERVICE_DOMAINS_BEGIN, id) );
    try {
        const response = await axios.put(`user/${id}/service-domains`, {...params});
        dispatch( queryAction(actionTypes.USERS_UPDATE_SERVICE_DOMAINS_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USERS_UPDATE_SERVICE_DOMAINS_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updatePostback = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.USERS_UPDATE_POSTBACK_BEGIN, id) );
    try {
        const response = await axios.put(`user/${id}/postback`, {...params});
        dispatch( queryAction(actionTypes.USERS_UPDATE_POSTBACK_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USERS_UPDATE_POSTBACK_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const getReferralSettings = (id, params = {}) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.USERS_GET_REFERRAL_TRAFFIC_BEGIN) );
    try {
        if ( params.dictionaries ) {
            const { Dictionaries: dicts } = getState();
            params = requestAssignDictionaries(dicts, params.dictionaries || [], params);
        }
        const response = await axios.get(`user/${id}/referral-traffic`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.USERS_GET_REFERRAL_TRAFFIC_SUCCESS, {
            id: id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch(error) {
        dispatch( queryAction(actionTypes.USERS_GET_REFERRAL_TRAFFIC_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateReferralSettings = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.USERS_UPDATE_REFERRAL_TRAFFIC_BEGIN, id) );
    try {
        const response = await axios.put(`user/${id}/referral-traffic`, {...params});
        dispatch( queryAction(actionTypes.USERS_UPDATE_REFERRAL_TRAFFIC_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.USERS_UPDATE_REFERRAL_TRAFFIC_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};