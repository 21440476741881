import { createSelector } from 'reselect';
import { currencies, paymentGateways, paymentSystems } from "./dictionaries";

export const loading = createSelector(state => state.Autopayments.loading, loading => loading);
export const autopaymentsData = createSelector(state => state.Autopayments.autopaymentsData, autopaymentsData => autopaymentsData);
export const total = createSelector(state => state.Autopayments.autopaymentsData.meta?.pagination?.total, total => total || 0);
export const totalAmounts = createSelector(state => state.Autopayments.autopaymentsData.meta['auto-payments']?.total_amounts, total_amounts => total_amounts || null);
export const list = createSelector(state => state.Autopayments.autopaymentsData.data, data => data || []);
export const isLoadData = createSelector(state => state.Autopayments.autopaymentsData.isLoadData, isLoadData => isLoadData);
export const isLoadingProcess = createSelector(state => state.Autopayments.autopaymentsData.isLoadingProcess, isLoadingProcess => isLoadingProcess);

export const autopaymentsDictionaries = createSelector(
    [
        paymentSystems,
        paymentGateways,
        currencies
    ],
    (paymentSystems, paymentGateways, currencies) => ({
        paymentSystems,
        payment_gateways: paymentGateways,
        currencies
    })
);