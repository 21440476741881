import {
    mdiAccount, mdiBriefcase, mdiMessage, mdiMonitorDashboard, mdiLinkVariant, mdiNote,
    mdiToggleSwitchOff, mdiToggleSwitch, mdiDelete, mdiCheckboxMultipleMarkedOutline,
    mdiDockWindow, mdiAlphaNCircleOutline, mdiTelevisionPlay, mdiMovieOpenPlay, mdiDockBottom,
    mdiPlayBoxOutline, mdiPictureInPictureTopRightOutline
} from '@mdi/js';

// get api url from meta tag
let metaApiUrl = null;
const metaApi = document.head.querySelector("meta[name~='api-url'][content]");
if ( metaApi ) {
    metaApiUrl = metaApi.content;
}
// get sentry DSN
let metaSentryDsn = null;
const sentryDsn = document.head.querySelector("meta[name~='sentry-dsn'][content]");
if ( sentryDsn ) {
    metaSentryDsn = sentryDsn.content;
}
// get current application version
let metaCurrentAppVersion = null;
const currentAppVersion = document.head.querySelector("meta[name~='current-app-version'][content]");
if ( currentAppVersion ) {
    metaCurrentAppVersion = currentAppVersion.content;
}

export const _DEV_ = process.env.NODE_ENV !== 'production';
export const _PROD_ENV_ = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'production';
export const APP_WEB_NAME = process.env.REACT_APP_APP_WEB_NAME || 'AdServer';
export const APP_WEB_PREFIX = process.env.REACT_APP_APP_WEB_PREFIX || 'adserver';
export const showLimitTrafficMessageENV = process.env.REACT_APP_SHOW_LIMIT_TRAFFIC_MESSAGE === 'true';
export const apiUrl = metaApiUrl || process.env.REACT_APP_API_URL;
export const sentryUrl = metaSentryDsn || process.env.REACT_APP_SENTRY_API_DSN;
export const isProtoData = process.env.REACT_APP_PROTO_DATA === 'true';
export const scriptsGetURL = process.env.REACT_APP_SCRIPTS_URL || '';
export const i18nDebug = process.env.REACT_APP_I18N_DEBUG === 'true';
export const wsUrl = process.env.REACT_APP_WS_URL;
export const recaptchaKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;
export const invocPushSWLink = process.env.REACT_APP_INVOC_PUSHSW_LINK;
export const getAccountDataInterval = 180;
export const getDashboardDataInterval = 600;
export const getTreeType = 'all'; // all / partial
export const getLimitsInterval = 30;
export const getTreeDataInterval = 60;
export const getRolesDataInterval = 60;
export const allowGroupOperationsOnTree = true;
export const timeSubmitFilters = 700;
export const showLimitResetErrorTime = 15000;

export const metaAppVersion = metaCurrentAppVersion || 0;
export const appVersion = process.env.REACT_APP_VERSION_FOR_RELOAD || 0;
export const getAppVersionInterval = 60 * 2;

export const usedLanguages = ['en'];

export const actionsPeriodTypes = {
    minutes: 'Minute(s)',
    hours: 'Hour(s)',
    days: 'Day(s)',
    weeks: 'Week(s)',
    months: 'Month(s)',
    years: 'Year(s)'
};

export const PUSH_PL_ID = 1;
export const FEED_PL_ID = 2;
export const NON_STD_PL_ID = 3;
export const CLICKUNDER_PL_ID = 4;
export const OVERROLL_PL_ID = 5;
export const PREROLL_PL_ID = 6;
export const CATFISH_PL_ID = 7;
export const BANNER_PL_ID = 8;

export const PUSH_CR_ID = 1;
export const FEED_CR_ID = 2;
export const NON_STD_CR_ID = 3;
export const DIRECTLINK_CR_ID = 4;
export const VIDEO_CR_ID = 5;
export const CATFISH_CR_ID = 6;
export const BANNER_CR_ID = 7;

export const assetTypesIDs = {
    'campaign': 1,
    'channel': 2,
    'placement': 3,
    'creative': 4,
    'project': 5,
    'action': 6
};

export const creativeSettings = {
    video: {
        duration: process.env.REACT_APP_CREATIVE_VIDEO_DURATION || 60,
        size: process.env.REACT_APP_CREATIVE_VIDEO_SIZE || 10240,
        bitrateMin: process.env.REACT_APP_CREATIVE_BITRATE_MIN || 50000,
        bitrateMax: process.env.REACT_APP_CREATIVE_BITRATE_MAX || 500000,
    },
    image: {
        size: process.env.REACT_APP_CREATIVE_IMAGE_SIZE || 5000,
    }
};

export const projectsGroupOperationsActions = ['disable','enable','remove'];
export const channelsGroupOperationsActions = ['disable','enable','remove'];
export const groupOperationsActionsIcons = {
    enable: mdiToggleSwitch,
    disable: mdiToggleSwitchOff,
    remove: mdiDelete,
    checkall: mdiCheckboxMultipleMarkedOutline
};

const pushIcons = {
    default: mdiMessage,
};

const feedIcons = {
    default: mdiLinkVariant,
};

const nonStdIcons = {
    default: mdiAlphaNCircleOutline,
};

const clickunderIcons = {
    default: mdiDockWindow
};

const overrollIcons = {
    default: mdiTelevisionPlay
};

const prerollIcons = {
    default: mdiMovieOpenPlay
};

const catfishIcons = {
    default: mdiDockBottom
};

const directLinkIcons = {
    default: mdiDockWindow
};

const videoIcons = {
    default: mdiPlayBoxOutline
};

const bannerIcons = {
    default: mdiPictureInPictureTopRightOutline
};

export const projectsIconConfig = {
    levels: ['project', 'campaign', 'creative'],
    project: {
        default: mdiAccount,
    },
    campaign: {
        default: mdiBriefcase,
    },
    creative: {
        default: {
            default: mdiNote,
        },
        [PUSH_CR_ID]: {
            ...pushIcons
        },
        [FEED_CR_ID]: {
            ...feedIcons
        },
        [NON_STD_CR_ID]: {
            ...nonStdIcons
        },
        [DIRECTLINK_CR_ID]: {
            ...directLinkIcons
        },
        [VIDEO_CR_ID]: {
            ...videoIcons
        },
        [BANNER_CR_ID]: {
            ...bannerIcons
        },
    }
};

export const channelsIconConfig = {
    levels: ['channel', 'placement'],
    channel: {
        default: mdiMonitorDashboard,
    },
    placement: {
        default: {
            default: mdiNote,
        },
        [PUSH_PL_ID]: {
            ...pushIcons
        },
        [FEED_PL_ID]: {
            ...feedIcons
        },
        [NON_STD_PL_ID]: {
            ...nonStdIcons
        },
        [CLICKUNDER_PL_ID]: {
            ...clickunderIcons
        },
        [OVERROLL_PL_ID]: {
            ...overrollIcons
        },
        [PREROLL_PL_ID]: {
            ...prerollIcons
        },
        [CATFISH_PL_ID]: {
            ...catfishIcons
        },
        [BANNER_PL_ID]: {
            ...bannerIcons
        }
    }
};

export const assetIcons = {
    projects: projectsIconConfig,
    channels: channelsIconConfig,
};

export const STATUS_REPORT_LOADING = 1;
export const STATUS_REPORT_READY = 2;
export const STATUS_REPORT_FAILED = 5;

export const STATUS_CONFIRMED = 1;
export const STATUS_MODERATION = 2;
export const STATUS_DENY = 3;
export const STATUS_ACTIVE = 4;
export const STATUS_BLOCK = 5;
export const STATUS_DELETED = 6;

export const statusesColors = {
    '1': 'success',
    '2': 'warning',
    '3': 'danger',
    '4': 'success',
    '5': 'danger',
    '6': 'secondary'
}

export const CpcId = 1;
export const CpmId = 2;

export const userTypes = [
    { id: 1, value: 'advertiser', name: 'Advertiser' },
    { id: 2, value: 'publisher', name: 'Publisher' },
];

export const contactTypes = [
    { value: 'skype', name: 'Skype' },
    { value: 'whatsapp', name: 'WhatsApp' },
    { value: 'telegram', name: 'Telegram' },
];

export const sizePerPageList = [
    { text: '25', value: 25},
    { text: '50', value: 50},
    { text: '100', value: 100},
    { text: '500', value: 500},
];

export const creativeMacros = [
    // '{{PUBLISHER_ID}}',
    // '{{PLACEMENT_ID}}',
    '{{SOURCE_ID}}',
    '{{CLIENT_USER_AGENT}}',
    '{{CLIENT_USER_AGENT_ESC}}',
    '{{REMOTE_COUNTRY}}',
    '{{REMOTE_IP}}',
    '{{BANNER_ID}}',
    '{{CAMPAIGN_ID}}',
    '{{PROJECT_ID}}',
    '{{ACTION_USER_ID}}',
    '{{TIMESTAMP}}'
];