import { createSelector } from 'reselect';

const isCheckSavedFormSelector = state => state.Common.isFormNotSaved;

export const selectors = {
    isCheckSavedFormSelector,
};

export const isFormNotSaved = createSelector(
    isCheckSavedFormSelector,
    isFormNotSaved => isFormNotSaved
);

export const getIsFormNotSaved = createSelector(
    isCheckSavedFormSelector,
    isFormNotSaved => ({isFormNotSaved: isFormNotSaved})
);

export const isNeedReloadPage = createSelector(
    state => state.Common.isNeedReloadPage,
    isNeedReloadPage => isNeedReloadPage
);

export const reloadToStartPage = createSelector(
    state => state.Common.reloadToStartPage,
    reloadToStartPage => reloadToStartPage
);