export const isObject = data => typeof data === 'object' && data !== null;

export const isObjectsEquivalent = (a, b) => {
    // if they are strictly equal, they both need to be object at least
    if (!(a instanceof Object)) { return false; }
    if (!(b instanceof Object)) { return false; }

    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (
            JSON.stringify(a[propName]) !== JSON.stringify(b[propName])
        ) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export const filterObjectByKeys = (data, allowed) => {
    allowed = Array.isArray(allowed) ? allowed : Object.keys(allowed);
    return Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
}

export const checkTypeByTouchValues = (touched, initialValues, values) => {
    let isSaved = false;
    if (!Object.keys(touched).length) {
        isSaved = false;
        return isSaved;
    }
    Object.keys(touched).forEach(el => {
        const type = checkTypeInitialValues(initialValues[el]);
        // const val = (type === 'array' || type === 'object') ? JSON.stringify(initialValues[el]) : initialValues[el];
        if (type !== 'undefined' && typeof values[el] !== 'undefined') {
            // if (val !== convertValueToType(type, values[el])) {
            if (JSON.stringify(initialValues[el]) !== JSON.stringify(values[el])) {
                isSaved = true;
                return;
            }
        }
    });
    return isSaved;
}

const checkTypeInitialValues = (value) => {
    if (value === null) {
        return 'null';
    }
    if (value === 'undefined') {
        return 'undefined';
    }
    if (Array.isArray(value)) {
        return 'array';
    }
    return typeof value;
}

export const convertValueToType = (type, value) => {
    switch (type) {
        case 'string':
            return value.toString();

        case 'number':
            return +value;

        case 'object':
        case 'array':
            return JSON.stringify(value);

        case 'null':
            return value ? value : null;

        case 'undefined':
            return 'undefined';

        default:
            break;
    }
}

export const deepCopyObject = (object) => {
    if ( !object ) {
        return object;
    }
    return JSON.parse(JSON.stringify(object));
}

export const iterationCopy = function (src) {
    let target = {};
    if ( Array.isArray(src) ) {
        target = [];
    }
    for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
            // if the value is a nested object && not instance of Blob, recursively copy all it's properties
            if (isObject(src[prop]) && !(src[prop] instanceof Blob)) {
                target[prop] = iterationCopy(src[prop]);
            } else {
                target[prop] = src[prop];
            }
        }
    }
    return target;
}

export const isPlainObject = (value) => {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    let proto = value;

    while (Object.getPrototypeOf(proto) !== null) {
      proto = Object.getPrototypeOf(proto);
    }

    return Object.getPrototypeOf(value) === proto;
}

export const isIdenticalObjects = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}