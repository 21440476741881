// export const DASHBOARD_STATISTICS_TABLE_BEGIN = 'Dashboard/StatisticsFetchBegin';
// export const DASHBOARD_STATISTICS_TABLE_SUCCESS = 'Dashboard/StatisticsFetchSuccess';
// export const DASHBOARD_STATISTICS_TABLE_FAILURE = 'Dashboard/StatisticsFetchFailure';

// export const DASHBOARD_STATISTICS_CHART_BEGIN = 'Dashboard/StatisticsChartFetchBegin';
// export const DASHBOARD_STATISTICS_CHART_SUCCESS = 'Dashboard/StatisticsChartFetchSuccess';
// export const DASHBOARD_STATISTICS_CHART_FAILURE = 'Dashboard/StatisticsChartFetchFailure';

export const DASHBOARD_STATISTICS_BEGIN = 'Dashboard/StatisticsFetchBegin';
export const DASHBOARD_STATISTICS_SUCCESS = 'Dashboard/StatisticsFetchSuccess';
export const DASHBOARD_STATISTICS_FAILURE = 'Dashboard/StatisticsFetchFailure';

export const DASHBOARD_STATISTICS_COMPARATIVES_BEGIN = 'Dashboard/StatisticsComparativesFetchBegin';
export const DASHBOARD_STATISTICS_COMPARATIVES_SUCCESS = 'Dashboard/StatisticsComparativesFetchSuccess';
export const DASHBOARD_STATISTICS_COMPARATIVES_FAILURE = 'Dashboard/StatisticsComparativesFetchFailure';
