// just import actions from other files (modules)
import * as wsActions from './ws';
import * as profileActions from './profile';
import * as usersActions from './users';
import * as channelsActions from './channels';
import * as dictionariesActions from './dictionaries';
import * as featuresActions from './features';
import * as campaignsActions from './campaigns';
import * as billingActions from './billing';
import * as analyticsActions from './analytics';
import * as projectsActions from './projects';
import * as dashboardActions from './dashboard';
import * as financeActions from './finance';
import * as activitiesActions from './activities';
import * as treesActions from './trees';
import * as rolesActions from './roles';
import * as feedtemplatesActions from './feedtemplates';
import * as remoteActions from './remote';
import * as actionsActions from './actions';
import * as commonActions from './common';
import * as autopaymentsActions from './autopayments';
import * as moderationActions from './moderation';
import * as pricinginfoActions from './pricinginfo';
import * as postbackslogActions from './postbackslog';
import * as exchangeActions from './exchange';
import * as creativetemplatesActions from './creativetemplates';

// export all actions as modules
export { 
    wsActions,
    profileActions, 
    usersActions, 
    channelsActions,
    dictionariesActions,
    featuresActions, 
    campaignsActions, 
    billingActions, 
    analyticsActions,
    projectsActions,
    dashboardActions,
    financeActions,
    activitiesActions,
    treesActions,
    rolesActions,
    feedtemplatesActions,
    remoteActions,
    actionsActions,
    commonActions,
    autopaymentsActions,
    moderationActions,
    pricinginfoActions,
    postbackslogActions,
    exchangeActions,
    creativetemplatesActions,
};
