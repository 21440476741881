import React from "react";
import toast from './transToast';
import { Trans } from "react-i18next";

export const errorsProcessor = (messages, setErrors) => {
    if ( 'string' === typeof messages ) {
        toast.error(messages, 'Messages.' + messages);
    } else if ( 'undefined' !== typeof messages && Object.prototype.toString.call(messages) === '[object Array]' ) {
        const errors = {};
        messages.forEach(item => {
            if ( 'string' === typeof item ) {
                toast.error(item, 'Messages.' + item);
            } else if ( 'object' === typeof item && null !== item ) {
                if ( 'undefined' !== typeof item.fieldName ) {
                    if ( 'undefined' === typeof errors[ item.fieldName ] ) {
                        errors[ item.fieldName ] = [];
                    }
                    errors[ item.fieldName ].push(
                        <React.Fragment key={errors[ item.fieldName ].length}>
                            <Trans i18nKey={`Errors.${item.message}`}>{item.message}</Trans>{' '}
                        </React.Fragment>
                    );
                    toast.error(item.message, 'Errors.' + item.message);
                } else {
                    Object.keys(item).forEach(error => {
                        errors[error] = [];
                        item[error].forEach(errorItem => {
                            console.log('errorItem',errorItem);
                            errors[error].push(
                                <React.Fragment key={errors[error].length}>
                                    <Trans i18nKey={`Errors.${errorItem}`}>{errorItem}</Trans>{' '}
                                </React.Fragment>
                            );
                        });
                    });
                }
            }
        });
        setErrors && setErrors(errors);
    }
};

export const getErrorsString = messages => {
    if ( messages ) {
        if ( 'string' === typeof messages ) {
            return messages;
        }
        else if ( Object.prototype.toString.call(messages) === '[object Array]' ) {
            return messages.reduce((message, cur) => {
                if ( 'string' === typeof cur ) {
                    message = `${message} ${cur}`;
                } else if ( 'object' === typeof cur && null !== cur ) {
                    if ( 'undefined' !== typeof cur.fieldName ) {
                        if ( 'string' === typeof cur.message ) {
                            message = `${message} ${cur.message}`;
                        } else {
                            Object.keys(cur.message).forEach(error => {
                                cur.message[error].forEach(errorItem => {
                                    message = `${message} ${errorItem}`;
                                });
                            });
                        }
                    } else {
                        Object.keys(cur).forEach(error => {
                            cur[error].forEach(errorItem => {
                                message = `${message} ${errorItem}`;
                            });
                        });
                    }
                }
                return message;
            }, '');
        }
    }
    return null;
}