import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

export const Translate = props => {
    if ( props.string ) {
        const { t } = props;
        return t(props.i18nKey);
    }
    return <Trans i18nKey={props.i18nKey}>{props.children}</Trans>;
};

const translateFunc = props => {
    const { t } = props;
    return t(props.i18nKey);
};

export const Transt = withTranslation()(translateFunc);

export default withTranslation()(Translate);