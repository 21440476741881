export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const changeUrlHashParameter = (url, name, value) => {
    url = new URL(url.indexOf('://') === -1 ? window.location.origin + url : url);
    let hash;
    const pattern = new RegExp(`${name}\\|.+?\\|`);
    const urlHash = url.hash.slice(1);
    if ( value ) {
        hash = urlHash && urlHash.match(pattern) ? urlHash.replace(pattern, `${name}|${value}|`) : urlHash + `${name}|${value}|`;
    } else {
        hash = urlHash && urlHash.match(pattern)  ? urlHash.replace(pattern, ``) : urlHash + ``;
    }

    return `${url.pathname}${url.search}${hash ? `#${hash}` : ''}`;
}

export const getUrlHashParameter = name => {
    const pattern = new RegExp(`${name}\\|(.+?|)?\\|`);
    if ( name && window.location.hash && window.location.hash.match(pattern) ) {
        return decodeURIComponent(window.location.hash.match(pattern)[1]).replace(/=/g,'');
    }
    return null;
}

export const updateUrlHashPart = (hashName, hashValue) => {
    const url = changeUrlHashParameter(window.location.toString(), hashName, hashValue);
    window.history.replaceState(null, null, url);
}

export const encodeUrlHash = (params) => window.btoa(encodeURIComponent(JSON.stringify({ ...params })));
export const decodeUrlHash = (hashData) => {
    const data = decodeURIComponent(atob(hashData));
    if (data) {
        return JSON.parse(data);
    }
    return null;
};