import { apiUrl } from './constants';

import axios from 'axios';
import i18n from './i18n';
import store from "./store";
import { updateReincarnationToken } from '../redux/actions/profile';
import { setServiceAvailable, setServiceUnavailable } from '../redux/actions/common';

const cancelationMap = {};
const intervalRequests = {};

let globalCancelToken = axios.CancelToken.source();

const client = (apiUrl = null) => {
    const defaultOptions = {
        baseURL: apiUrl ? apiUrl : '',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            /*Authorization: {
                toString () {
                    return `Bearer ${localStorage.getItem('token')}`
                }
            }*/
        },
    };

    axios.interceptors.response.use(response => {
        const { data: { meta } } = response;

        /*if ( 'undefined' !== typeof headers.authorization ) {
            store.dispatch(updateReincarnationToken( headers.authorization.replace('Bearer ', '') ));
        }*/

        const isServiceAlive = store.getState().Common.isServiceAvailable;
        if ( !isServiceAlive ) {
            store.dispatch(setServiceAvailable());
        }

        if ( meta && 'undefined' !== typeof meta.tab_token ) {
            store.dispatch(updateReincarnationToken( meta.tab_token ));
        }

        return response;
    }, function (error) {
        // const isServiceAlive = store.getState().Common.isServiceAvailable;
        if ( error?.response?.status === 503 ) {
            store.dispatch(setServiceUnavailable(error?.response?.data?.data?.timeout_left));
        }
        // Do something with response error
        return Promise.reject(error);
    });

    /*axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (
            'undefined' === typeof error.response
            || 'undefined' === typeof error.response.status
            || error.response.status === 401
        ) {
            window.location('/logout');
        }
        return error;
    });*/

    axios.interceptors.request.use((config) => {
        config.params = config.params || {};
        config.params['locale'] = i18n.language;

        const { Profile: { userData: { parentData } } } = store.getState();
        if ( parentData && localStorage.getItem(`t__${parentData.hash}`) ) {
            config.params['tab_token'] = localStorage.getItem(`t__${parentData.hash}`);
        }

        return config;
    });

    return {
        all: (arr) => axios.all([...arr]),
        get: (url, options = {}) => {
            // console.log('****** url ******', url);
            //Check if need cancelation methods
            if ( options.params && 'undefined' !== typeof options.params.withCancelToken && options.params.withCancelToken ) {
                // console.log('*** withCancelToken ***');
                //Check if there are any previous pending requests
                if ( 'undefined' !== typeof options.params.withCancelTokenById && options.params.withCancelTokenById ) {
                    try {
                        const searchUrl = url.match(/^(.*)\/[0-9]+$/);
                        if ('undefined' !== typeof cancelationMap[searchUrl[1] + '/']) {
                            // console.log('*** cancel pending request ***');
                            cancelationMap[searchUrl[1] + '/'].cancel("Operation canceled due to new request.");
                        }
                        //Save the cancel token for the current request
                        cancelationMap[searchUrl[1] + '/'] = axios.CancelToken.source();
                        options.cancelToken = cancelationMap[searchUrl[1] + '/'].token;
                    } catch (e){}
                    delete options.params.withCancelTokenById;
                } else {
                    if ( 'undefined' !== typeof cancelationMap[url] ) {
                        // console.log('*** cancel pending request ***');
                        cancelationMap[url].cancel("Operation canceled due to new request.");
                    }
                    //Save the cancel token for the current request
                    cancelationMap[url] = axios.CancelToken.source();
                    options.cancelToken = cancelationMap[url].token;
                }
                delete options.params.withCancelToken;
            }
            else {
                options.cancelToken = globalCancelToken.token;
            }
            return axios.get(url, { ...defaultOptions, ...options });
        },
        post: (url, data, options = {}) => {
            //Check if need cancelation methods
            if ( 'undefined' !== typeof data.withCancelToken && data.withCancelToken ) {
                // console.log('*** withCancelToken ***');
                //Check if there are any previous pending requests
                if ( 'undefined' !== typeof cancelationMap[url] ) {
                    // console.log('*** cancel pending request ***');
                    cancelationMap[url].cancel("Operation canceled due to new request.");
                }
                //Save the cancel token for the current request
                cancelationMap[url] = axios.CancelToken.source();
                options.cancelToken = cancelationMap[url].token;
                delete data.withCancelToken;
            }
            if ( 'undefined' !== typeof data.withGlobeCancelToken && data.withGlobeCancelToken ) {
                // console.log('*** withGlobeCancelToken ***');
                options.cancelToken = globalCancelToken.token;
                delete data.withGlobeCancelToken;
            }
            return axios.post(url, data, { ...defaultOptions, ...options });
        },
        put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
        patch: (url, data, options = {}) => axios.patch(url, data, { ...defaultOptions, ...options }),
        delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
        isCancel: (err) => axios.isCancel(err),
        killAll: (level) => {
            if ( level === 9 ) {
                globalCancelToken.cancel("Operations canceled");
                for (let i in cancelationMap) {
                    cancelationMap[i].cancel("Operation canceled");
                }
                globalCancelToken = axios.CancelToken.source();
            }
        },
        addTimeout: (timeoutId, interval) => {
            intervalRequests[timeoutId] = interval;
        },
        removeTimeout: (timeoutId) => {
            if ( 'undefined' !== typeof intervalRequests[timeoutId] ) {
                delete intervalRequests[timeoutId];
            }
        },
        clearTimeout: (timeoutId) => {
            if ( 'undefined' !== typeof intervalRequests[timeoutId] ) {
                clearTimeout(intervalRequests[timeoutId]);
            }
        },
        clearAllTimeouts: () => {
            Object.keys(intervalRequests).forEach(timeoutId => {
                clearTimeout(intervalRequests[timeoutId]);
            });
        }
    };
};

const request = client(apiUrl);

export default request;
