import axios from '../../core/axiosInit';

import { campaignsTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';

export const fetchCampaigns = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGNS_FETCH_BEGIN) );
    try {
        const response = await axios.get(`campaigns`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CAMPAIGNS_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGNS_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchCampaign = (id, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        params = requestAssignDictionaries(dicts, params && params.dictionaries ? params.dictionaries : [], params);

        const response = await axios.get(`campaigns/${id}`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CAMPAIGN_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addCampaign = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_ADD_BEGIN) );
    try {
        const response = await axios.post(`projects/${id}/campaigns`, {...params});
        dispatch( queryAction(actionTypes.CAMPAIGN_ADD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_ADD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateCampaign = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`campaigns/${id}`, {...params});
        dispatch( queryAction(actionTypes.CAMPAIGN_UPDATE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_UPDATE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteCampaign = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_DELETE_BEGIN, id) );
    try {
        const response = await axios.delete(`campaigns/${id}`);
        dispatch( queryAction(actionTypes.CAMPAIGN_DELETE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_DELETE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const restoreCampaign = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_RESTORE_BEGIN, id) );
    try {
        const response = await axios.patch(`campaigns/${id}`);
        dispatch( queryAction(actionTypes.CAMPAIGN_RESTORE_SUCCESS, {id: id, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_RESTORE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changeCampaignFeatureStatus = ({feature_asset_type_id, asset_id, params}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_FEATURE_STATUS_CHANGE_BEGIN, {feature_asset_type_id, asset_id}) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        dispatch( queryAction(actionTypes.CAMPAIGN_FEATURE_STATUS_CHANGE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_FEATURE_STATUS_CHANGE_FAILURE, { feature_asset_type_id, asset_id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changeCreativeFeatureStatus = ({feature_asset_type_id, asset_id, params}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_BEGIN, {feature_asset_type_id, asset_id}) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_FAILURE, { feature_asset_type_id, asset_id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchCreatives = (id, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVES_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['status', 'creative_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`campaigns/${id}/creatives`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVES_FETCH_SUCCESS, {
            campaignId: id,
            data: response.data.data
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVES_FETCH_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteCreative = ({campaignId, creativeId}) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_DELETE_BEGIN ) );
    try {
        const response = await axios.delete(`campaigns/${campaignId}/creatives/${creativeId}`);
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_DELETE_SUCCESS, { data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_DELETE_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const restoreCreative = (campaignId, creativeId) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_RESTORE_BEGIN ) );
    try {
        const response = await axios.patch(`campaigns/${campaignId}/creatives/${creativeId}`);
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_RESTORE_SUCCESS, { data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_RESTORE_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchCreative = (campaignId, id, {dictionaries, ...params}) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_FETCH_BEGIN) );
    try {
        if ( 'undefined' !== typeof dictionaries ) {
            const {Dictionaries: dicts} = getState();
            params = requestAssignDictionaries(dicts, dictionaries, params);
        }

        const response = await axios.get(`campaigns/${campaignId}/creatives/${id}`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_FETCH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_FETCH_FAILURE, { campaignId, id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addCreative = (campaignId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_ADD_BEGIN) );
    try {
        const response = await axios.post(`campaigns/${campaignId}/creatives`, params, {headers: {'Content-Type': 'multipart/form-data'}} );
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_ADD_SUCCESS, {campaign: campaignId, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_ADD_FAILURE, { campaignId, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateCreative = (campaignId, id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_UPDATE_BEGIN, id) );
    try {
        const response = await axios.post(`campaigns/${campaignId}/creatives/${id}`, params, {headers: {'Content-Type': 'multipart/form-data'}});
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_UPDATE_SUCCESS, {
            campaign: campaignId,
            id: id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_UPDATE_FAILURE, { campaignId, id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const preloadVideoCreative = (campaignId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_PRELOAD_VIDEO_BEGIN) );
    try {
        const response = await axios.post(`campaigns/${campaignId}/creatives/preupload`, params, {headers: {'Content-Type': 'multipart/form-data'}} );
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_PRELOAD_VIDEO_SUCCESS, {campaign: campaignId, data: response.data.data[0]}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_PRELOAD_VIDEO_FAILURE, { campaignId, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const copyCampaign = (projectId, campaignId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_COPY_BEGIN, {projectId, campaignId, params}) );
    try {
        const response = await axios.post(`projects/${projectId}/campaigns/${campaignId}`, params);
        dispatch( queryAction(actionTypes.CAMPAIGN_COPY_SUCCESS, {
            project: projectId,
            campaign: campaignId,
            data: response.data.data
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_COPY_FAILURE, { projectId, campaignId, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const copyCreative = (campaignId, creativeId, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_COPY_BEGIN, {campaignId, creativeId, params}) );
    try {
        const response = await axios.post(`campaigns/${campaignId}/creatives/${creativeId}`, params);
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_COPY_SUCCESS, {
            creative: creativeId,
            campaign: campaignId,
            data: response.data.data
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CAMPAIGN_CREATIVE_COPY_FAILURE, { campaignId, creativeId, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};