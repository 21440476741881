import axios from '../../core/axiosInit';

import { featuresTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';
import { OVERROLL_PL_ID, PREROLL_PL_ID, VIDEO_CR_ID } from '../../core/constants';

const dictionaries = {
    pricing: ['pricing_type', 'currency', 'country', 'placement_type', 'action'],
    capping: ['capping_types','period_types', 'subject_types'],
    invocation_code : {
        placement: {
            [OVERROLL_PL_ID]: ['action'],
            [PREROLL_PL_ID]: ['action'],
        }
    },
    // targeting: ['device', 'country', 'system'],
    additional_settings: {
        creative: {
            [VIDEO_CR_ID]: ['action']
        }
    },
    third_party_events_tracking: ['tracking_event', 'action'],
    shaping: ['country', 'action'],
    limits: ['time_zone']
};

const getNeededDictionaries = (dictionariesList, assetData) => {
    if ( Array.isArray(dictionariesList) ) {
        return dictionariesList;
    }

    let result = [];

    if ( dictionariesList[assetData.assetType] ) {
        const assetDictionaries = dictionariesList[assetData.assetType];

        if ( Array.isArray(assetDictionaries) ) {
            result = assetDictionaries;
        }

        if ( 'undefined' !== typeof assetData.placementTypeId && assetData.placementTypeId ) {
            if ( 'undefined' !== typeof assetDictionaries[assetData.placementTypeId] ) {
                result = assetDictionaries[assetData.placementTypeId];
            }
        } else if ( 'undefined' !== typeof assetData.creativeTypeId && assetData.creativeTypeId ) {
            if ( 'undefined' !== typeof assetDictionaries[assetData.creativeTypeId] ) {
                result = assetDictionaries[assetData.creativeTypeId];
            }
        }
    }

    return result;
}

export const getFeatureData = (feature_asset_type_id, asset_id, featureType, assetData) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.FEATURE_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();

        let params = {};
        if ( 'undefined' !== typeof dictionaries[featureType] ) {
            params = requestAssignDictionaries(dicts, getNeededDictionaries(dictionaries[featureType], assetData));
        }
        const response = await axios.get(`features/${feature_asset_type_id}/assets/${asset_id}`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.FEATURE_FETCH_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.FEATURE_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateFeatureData = (feature_asset_type_id, asset_id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.FEATURE_UPDATE_BEGIN) );
    try {
        const featureName = Object.keys(params)[0];

        const response = await axios.put(`features/${feature_asset_type_id}/assets/${asset_id}`, {...params});
        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.FEATURE_UPDATE_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            data: response.data.data,
            feature_name: featureName
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.FEATURE_UPDATE_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const resetCounterLimit = (feature_asset_type_id, asset_id, asset_type_limit_id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.RESET_COUNTER_LIMIT_BEGIN) );
    try {
        const response = await axios.patch(`features/${feature_asset_type_id}/assets/${asset_id}/limits/${asset_type_limit_id}`);
        dispatch( queryAction(actionTypes.RESET_COUNTER_LIMIT_SUCCESS, {
            feature_asset_type_id: feature_asset_type_id,
            asset_id: asset_id,
            asset_type_limit_id: asset_type_limit_id,
            data: response.data.data[0]
        }) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.RESET_COUNTER_LIMIT_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};
