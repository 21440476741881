import { createSelector, defaultMemoize } from "reselect";
import { arrayContainSomeOfArray } from "../../helpers/arrayHelpers";
import checkPermission from "../../core/checkPermiss";

export const projectsDataSelector = createSelector(state => state.Trees.projectsFiltered, projectsFiltered => projectsFiltered);
export const projectsIsLoaded = createSelector(state => state.Trees.projectsLoaded, projectsLoaded => projectsLoaded);
export const projectsFiltersSelector = createSelector(state => state.Trees.projectsFilters, projectsFilters => projectsFilters);
export const projectsFiltersSelectedSelector = createSelector(state => state.Trees.projectsFiltersSelected, projectsFiltersSelected => projectsFiltersSelected);

export const projectsTreeDictionariesSelector = createSelector(
    [
        state => state.Dictionaries.creativeTypes,
        state => state.Dictionaries.trafficFormats,
        state => state.Dictionaries.pricingTypes,
        state => state.Dictionaries.priorityTypes,
        state => state.Dictionaries.assetTags,
        state => state.Dictionaries.statuses,
        state => state.Trees.projectsUsers,
        state => state.Users.managers
    ],
    (
        creativeTypes, trafficFormats, pricingTypes, priorityTypes, assetTags, statuses, projectsUsers, managers
    ) => {
        return {
            creativeTypes: creativeTypes
                .map(item => ({...item, allow: checkPermission('creative',{asset: 'creative', type: 'show', role: item.type})}))
                .filter(item => item.allow),
            trafficFormats,
            pricingTypes: pricingTypes.filter( item => arrayContainSomeOfArray(item.asset_types, ['project', 'campaign', 'creative']) ),
            priorityTypes,
            assetTags: assetTags.filter( item => arrayContainSomeOfArray(item.asset_types, ['project', 'campaign', 'creative']) ),
            statuses: statuses.filter( item => arrayContainSomeOfArray(item.scope, ['project', 'campaign', 'creative']) ),
            users: projectsUsers,
            managers
        }
    }
);

export const channelsDataSelector = createSelector(state => state.Trees.channelsFiltered, channelsFiltered => channelsFiltered);
export const channelsIsLoaded = createSelector(state => state.Trees.channelsLoaded, channelsLoaded => channelsLoaded);
export const channelsFiltersSelector = createSelector(state => state.Trees.channelsFilters, channelsFilters => channelsFilters);
export const channelsFiltersSelectedSelector = createSelector(state => state.Trees.channelsFiltersSelected, channelsFiltersSelected => channelsFiltersSelected);

export const channelsTreeDictionariesSelector = createSelector(
    [
        state => state.Dictionaries.placementTypes,
        state => state.Dictionaries.trafficFormats,
        state => state.Dictionaries.pricingTypes,
        state => state.Dictionaries.assetTags,
        state => state.Dictionaries.categories,
        state => state.Dictionaries.statuses,
        state => state.Trees.channelsUsers,
        state => state.Users.managers
    ],
    (
        placementTypes, trafficFormats, pricingTypes, assetTags, categories, statuses, channelsUsers, managers
    ) => {
        return {
            placementTypes: placementTypes
                .map(item => ({...item, allow: checkPermission('placement_type',{asset: 'placement', type: 'show', role: item.type})}))
                .filter(item => item.allow),
            trafficFormats,
            pricingTypes: pricingTypes.filter( item => arrayContainSomeOfArray(item.asset_types, ['channel', 'placement']) ),
            assetTags: assetTags.filter( item => arrayContainSomeOfArray(item.asset_types, ['channel', 'placement']) ),
            categories: categories.filter( item => arrayContainSomeOfArray(item.asset_types, ['channel', 'placement']) ),
            statuses: statuses.filter( item => arrayContainSomeOfArray(item.scope, ['channel', 'placement']) ),
            users: channelsUsers,
            managers
        }
    }
);

export const treeDataByType = defaultMemoize(
    treeType => createSelector(state => state.Trees[`${treeType}Filtered`], data => data || [])
);