export const USERS_FETCH_BEGIN = 'Users/FetchListBegin';
export const USERS_FETCH_SUCCESS = 'Users/FetchListSuccess';
export const USERS_FETCH_FAILURE = 'Users/FetchListFailure';

export const USER_FETCH_BEGIN = 'Users/FetchBegin';
export const USER_FETCH_SUCCESS = 'Users/FetchSuccess';
export const USER_FETCH_FAILURE = 'Users/FetchFailure';

export const USER_UPDATE_BEGIN = 'Users/UpdateBegin';
export const USER_UPDATE_SUCCESS = 'Users/UpdateSuccess';
export const USER_UPDATE_FAILURE = 'Users/UpdateFailure';

// export const USER_DELETE_BEGIN = 'Users/DeleteBegin';
// export const USER_DELETE_SUCCESS = 'Users/DeleteSuccess';
// export const USER_DELETE_FAILURE = 'Users/DeleteFailure';

// export const USER_RESTORE_BEGIN = 'Users/RestoreBegin';
// export const USER_RESTORE_SUCCESS = 'Users/RestoreSuccess';
// export const USER_RESTORE_FAILURE = 'Users/RestoreFailure';

export const USER_ADD_BEGIN = 'Users/AddBegin';
export const USER_ADD_SUCCESS = 'Users/AddSuccess';
export const USER_ADD_FAILURE = 'Users/AddFailure';

export const USERS_GROUP_BEGIN = 'Users/GroupBegin';
export const USERS_GROUP_SUCCESS = 'Users/GroupSuccess';
export const USERS_GROUP_FAILURE = 'Users/GroupFailure';

export const MANAGERS_FETCH_BEGIN = 'Users/FetchManagersBegin';
export const MANAGERS_FETCH_SUCCESS = 'Users/FetchManagersSuccess';
export const MANAGERS_FETCH_FAILURE = 'Users/FetchManagersFailure';

export const USERS_ALL_FETCH_BEGIN = 'User/FetchAllUsersBegin';
export const USERS_ALL_FETCH_SUCCESS = 'User/FetchAllUsersSuccess';
export const USERS_ALL_FETCH_FAILURE = 'User/FetchAllUsersFailure';

export const USER_TOKEN_STATISTICS_BEGIN = 'User/FetchUserTokenBegin';
export const USER_TOKEN_STATISTICS_SUCCESS = 'User/FetchUserTokenSuccess';
export const USER_TOKEN_STATISTICS_FAILURE = 'User/FetchUserTokenFailure';

export const USERS_UPDATE_PAYMENT_REQUISITES_BEGIN = 'User/UpdatePaymentRequisitesBegin';
export const USERS_UPDATE_PAYMENT_REQUISITES_SUCCESS = 'User/UpdatePaymentRequisitesSuccess';
export const USERS_UPDATE_PAYMENT_REQUISITES_FAILURE = 'User/UpdatePaymentRequisitesFailure';

export const USERS_UPDATE_SERVICE_DOMAINS_BEGIN = 'User/UpdateServiceDomainsBegin';
export const USERS_UPDATE_SERVICE_DOMAINS_SUCCESS = 'User/UpdateServiceDomainsSuccess';
export const USERS_UPDATE_SERVICE_DOMAINS_FAILURE = 'User/UpdateServiceDomainsFailure';

export const USERS_UPDATE_POSTBACK_BEGIN = 'User/UpdatePostbackBegin';
export const USERS_UPDATE_POSTBACK_SUCCESS = 'User/UpdatePostbackSuccess';
export const USERS_UPDATE_POSTBACK_FAILURE = 'User/UpdatePostbackFailure';

export const USERS_GET_REFERRAL_TRAFFIC_BEGIN = 'User/GetReferralTrafficBegin';
export const USERS_GET_REFERRAL_TRAFFIC_SUCCESS = 'User/GetReferralTrafficSuccess';
export const USERS_GET_REFERRAL_TRAFFIC_FAILURE = 'User/GetReferralTrafficFailure';

export const USERS_UPDATE_REFERRAL_TRAFFIC_BEGIN = 'User/UpdateReferralTrafficBegin';
export const USERS_UPDATE_REFERRAL_TRAFFIC_SUCCESS = 'User/UpdateReferralTrafficSuccess';
export const USERS_UPDATE_REFERRAL_TRAFFIC_FAILURE = 'User/UpdateReferralTrafficFailure';
