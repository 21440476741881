import axios from '../../core/axiosInit';
import { treesTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';

export const fetchChannels = channelId => async (dispatch, getState) => {
    const requestType = channelId ? 'PLACEMENTS' : 'CHANNELS';
    dispatch( queryAction(actionTypes['TREE_' + requestType + '_FETCH_BEGIN']) );
    try {
        let params = {
            limit: 1000,
            sort: 'name',
            page: 1
        };

        const { Dictionaries: dicts } = getState();
        const dictionaries = ['placement_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params);
        const response = await axios.get(channelId ? `channels/${channelId}/placements` : `channels`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(
                            actionTypes['TREE_' + requestType + '_FETCH_SUCCESS'],
                            {
                                data: response.data.data,
                                channelId
                            }
                        )
        );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes['TREE_' + requestType + '_FETCH_FAILURE'], { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchProjects = ( projectId, campaignId ) => async (dispatch, getState) => {
    let requestType = projectId ? 'CAMPAIGNS' : 'PROJECTS';
    requestType = campaignId ? 'CREATIVES' : requestType;

    let url = projectId ? `projects/${projectId}/campaigns` : 'projects';
    url = campaignId ? `campaigns/${campaignId}/creatives` : url;

    dispatch( queryAction(actionTypes['TREE_' + requestType + '_FETCH_BEGIN']) );
    try {
        let params = {
            limit: 1000,
            sort: 'name',
            page: 1
        };

        const { Dictionaries: dicts } = getState();
        const dictionaries = ['placement_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(url, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(
            actionTypes['TREE_' + requestType + '_FETCH_SUCCESS'],
            {
                data: response.data.data,
                projectId,
                campaignId
            }
            )
        );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes['TREE_' + requestType + '_FETCH_FAILURE'], { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchProjectsTree = (params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.TREE_PROJECTS_ALL_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['creative_type','traffic_format', 'pricing_type', 'priority_type', 'asset_tag','status'];
        params = requestAssignDictionaries(dicts, dictionaries, params || {});

        const response = await axios.get(`projects-tree`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.TREE_PROJECTS_ALL_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.TREE_PROJECTS_ALL_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchChannelsTree = (params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.TREE_CHANNELS_ALL_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['placement_type','traffic_format', 'pricing_type', 'priority_type', 'asset_tag','category','status'];
        params = requestAssignDictionaries(dicts, dictionaries, params || {});

        const response = await axios.get(`channels-tree`, {params: {...params}});

        checkDictionaries(dispatch, response.data);
        dispatch( queryAction(actionTypes.TREE_CHANNELS_ALL_FETCH_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.TREE_CHANNELS_ALL_FETCH_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updateTreeData = (dispatch, type, data) =>  {
    dispatch( queryAction(actionTypes.TREE_DATA_UPDATE, {
        data: data,
        type: type
    }) );
};

export const updateTreeItemStatus = (newStatus, assetData) => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_ITEM_STATUS_UPDATE, {
        newStatus, assetData
    }) );
}

export const groupOperations = (action, params) => async (dispatch, getState) => {
    dispatch( queryAction(actionTypes.TREE_GROUP_BEGIN, action) );
    try {
        let response = await axios.patch(`asset-batch`, params);
        dispatch( queryAction(actionTypes.TREE_GROUP_SUCCESS, {data: params}) );
        fetchProjectsTree()(dispatch, getState);
        fetchChannelsTree()(dispatch, getState);
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.TREE_GROUP_FAILURE, { action, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const loadAssetTreeIndex = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_LOAD_INDEX_BEGIN) );
    try {
        const response = await axios.get(`asset-tree-index`);
        dispatch( queryAction(actionTypes.TREE_LOAD_INDEX_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.TREE_LOAD_INDEX_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const setProjectsFilters = filters => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_PROJECTS_SET_FILTERS, filters) );
};

export const setProjectsFiltersSelected = selectedFilters => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_PROJECTS_SET_FILTERS_SELECTED, selectedFilters) );
};

export const updateProjectsFilters = (selectedFilters, filters) => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_PROJECTS_UPDATE_FILTERS, {
        selectedFilters,
        filters
    }) );
};

export const setChannelsFilters = filters => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_CHANNELS_SET_FILTERS, filters) );
};

export const setChannelsFiltersSelected = selectedFilters => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_CHANNELS_SET_FILTERS_SELECTED, selectedFilters) );
};

export const updateChannelsFilters = (selectedFilters, filters) => async (dispatch) => {
    dispatch( queryAction(actionTypes.TREE_CHANNELS_UPDATE_FILTERS, {
        selectedFilters,
        filters
    }) );
};