import {
    profileTypes as profileActionTypes,
    projectsTypes as actionTypes,
    treesTypes as treeActionTypes
} from '../types';

export const initialState = {
    list: [],
    listMeta: {},
    loading: false,
    data: [],
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.PROJECTS_FETCH_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.PROJECTS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.PROJECTS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                list: [
                    ...payload.list
                ],
                listMeta: {...payload.meta}
            };

        case actionTypes.PROJECT_FETCH_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    payload
                ]
            };

        case actionTypes.PROJECT_ADD_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    payload
                ]
            };

        case actionTypes.PROJECT_UPDATE_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data}),
                data: state.data.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data}),
            };

        case actionTypes.PROJECT_CAMPAIGNS_FETCH_SUCCESS:
            return {
                ...state,
                data: state.data.map(project => {
                    if ( parseInt(project.id) === parseInt(payload.projectId) ) {
                        project.campaigns = payload.data;
                        return {...project};
                    }
                    if ( 'undefined' === typeof project.campaigns ) {
                        project.campaigns = [];
                        return {...project};
                    }
                    return project;
                })
            };

        case actionTypes.PROJECT_FEATURE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                data: state.data.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = payload.data.enabled;
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.PROJECT_FEATURE_STATUS_CHANGE_FAILURE:
            return {
                ...state,
                data: state.data.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = 1 === parseInt(feature.required) ? 1 : parseInt(feature.enabled);
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case treeActionTypes.TREE_GROUP_SUCCESS:
            const actions = payload.data.action;
            const action = Object.keys(actions)[0];

            const switchActions = (action, item) => {
                switch (action) {
                    case 'enable':
                        item.enabled = 1;
                        return {...item};

                    case 'disable':
                        item.enabled = 0;
                        return {...item};

                    case 'remove':
                        return null;

                    default:
                        break;
                }
                return item;
            };

            if ( 'undefined' !== typeof actions[action].project ) {
                return {
                    ...state,
                    list: state.list.map(item => {
                        if ( 'undefined' !== typeof actions[action].project ) {
                            if (actions[action].project.includes('' + item.id) || actions[action].project.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item),
                    data: JSON.parse(JSON.stringify(state.data)).map(item => {
                        if ( 'undefined' !== typeof actions[action].project ) {
                            if (actions[action].project.includes('' + item.id) || actions[action].project.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item)
                }
            }
            return state;

        case profileActionTypes.REINCARNATION_SUCCESS: {
            if ( payload.token ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        default:
            return state;
    }
};

export default state;