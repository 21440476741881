import { createSelector } from 'reselect';
import { paymentMethods, paymentSystemMethods, paymentSystems } from "./dictionaries";

export const loadingSelector = createSelector(state => state.Finance.loading, loading => loading);
export const financeDataSelector = createSelector(state => state.Finance.financeData, financeData => financeData);
export const paymentsDataSelector = createSelector(state => state.Finance.paymentsData, paymentsData => paymentsData);
export const listPayments = createSelector(state => state.Finance.paymentsData.data, paymentsData => paymentsData);
export const totalPayments = createSelector(state => state.Finance.paymentsData.meta?.pagination?.total, total => total || 0)
export const isLoadDataPayments = createSelector(state => state.Finance.paymentsData.isLoadData, isLoadData => isLoadData);
export const isLoadingProcessPayments = createSelector(state => state.Finance.paymentsData.isLoadingProcess, isLoadingProcess => isLoadingProcess);

export const listFinance = createSelector(state => state.Finance.financeData.data, paymentsData => paymentsData);
export const totalFinance = createSelector(state => state.Finance.financeData.meta?.pagination?.total, total => total || 0)
export const isLoadDataFinance = createSelector(state => state.Finance.financeData.isLoadData, isLoadData => isLoadData);
export const isLoadingProcessFinance = createSelector(state => state.Finance.financeData.isLoadingProcess, isLoadingProcess => isLoadingProcess);

export const paymentsDictionaries = createSelector(
    [paymentSystems, paymentMethods, paymentSystemMethods],
    (paymentSystems, paymentMethods, paymentSystemMethods) => ({
        paymentSystems,
        paymentMethods,
        paymentSystemMethods
    })
);