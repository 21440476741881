export const PROJECTS_FETCH_BEGIN = 'Projects/FetchListBegin';
export const PROJECTS_FETCH_SUCCESS = 'Projects/FetchListSuccess';
export const PROJECTS_FETCH_FAILURE = 'Projects/FetchListFailure';

export const PROJECT_FETCH_BEGIN = 'Projects/FetchProjectBegin';
export const PROJECT_FETCH_SUCCESS = 'Projects/FetchProjectSuccess';
export const PROJECT_FETCH_FAILURE = 'Projects/FetchProjectFailure';

export const PROJECT_ADD_BEGIN = 'Projects/AddProjectBegin';
export const PROJECT_ADD_SUCCESS = 'Projects/AddProjectSuccess';
export const PROJECT_ADD_FAILURE = 'Projects/AddProjectFailure';

export const PROJECT_UPDATE_BEGIN = 'Projects/UpdateProjectBegin';
export const PROJECT_UPDATE_SUCCESS = 'Projects/UpdateProjectSuccess';
export const PROJECT_UPDATE_FAILURE = 'Projects/UpdateProjectFailure';

export const PROJECT_DELETE_BEGIN = 'Projects/DeleteProjectBegin';
export const PROJECT_DELETE_SUCCESS = 'Projects/DeleteProjectSuccess';
export const PROJECT_DELETE_FAILURE = 'Projects/DeleteProjectFailure';

export const PROJECT_RESTORE_BEGIN = 'Projects/RestoreProjectBegin';
export const PROJECT_RESTORE_SUCCESS = 'Projects/RestoreProjectSuccess';
export const PROJECT_RESTORE_FAILURE = 'Projects/RestoreProjectFailure';

export const PROJECT_CAMPAIGNS_FETCH_BEGIN = 'Projects/FetchCampaignsBegin';
export const PROJECT_CAMPAIGNS_FETCH_SUCCESS = 'Projects/FetchCampaignsSuccess';
export const PROJECT_CAMPAIGNS_FETCH_FAILURE = 'Projects/FetchCampaignsFailure';

export const PROJECT_FEATURE_STATUS_CHANGE_BEGIN = 'Projects/ChangeFeatureStatusBegin';
export const PROJECT_FEATURE_STATUS_CHANGE_SUCCESS = 'Projects/ChangeFeatureStatusSuccess';
export const PROJECT_FEATURE_STATUS_CHANGE_FAILURE = 'Projects/ChangeFeatureStatusFailure';

export const PROJECT_COPY_BEGIN = 'Projects/CopyBegin';
export const PROJECT_COPY_SUCCESS = 'Projects/CopySuccess';
export const PROJECT_COPY_FAILURE = 'Projects/CopyFailure';

