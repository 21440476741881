import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import wsMiddleware from "./middleware/socketMiddleware";
import rootReducer from '../redux/reducers';

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const middlewares = [thunk, wsMiddleware];

function createAppStore(initialState) {
    const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middlewares))(createStore);
    return createStoreWithMiddleware(rootReducer, initialState);
}

export default createAppStore;