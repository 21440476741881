import store from "./store";

const makeCheck = {
    placement_type: ({ asset, type, role }) => {
        switch (type) {
            case 'create':
                return `create ${asset} with placement_type ${role}`;

            case 'update':
                return `update ${asset} with placement_type ${role}`;

            case 'view':
            case 'show':
                return `show ${asset} with placement_type ${role}`;

            case 'delete':
            case 'destroy':
                return `destroy ${asset} with placement_type ${role}`;

            default:
                return `${type} ${asset} with placement_type ${role}`;

        }
    },
    creative: ({ asset, type, role }) => {
        switch (type) {
            case 'create':
                return `create ${asset} with creative_type ${role}`;

            case 'update':
                return `update ${asset} with creative_type ${role}`;

            case 'view':
            case 'show':
                return `show ${asset} with creative_type ${role}`;

            case 'delete':
            case 'destroy':
                return `destroy ${asset} with creative_type ${role}`;

            default:
                return `${type} ${asset} with creative_type ${role}`;

        }
    },
    asset_permission: ({ asset, type, userId, userData }) => {
        switch (type) {
            case 'show':
                return [
                    `show ${userId === userData.id ? 'my' : 'child'} ${asset}`,
                    `show all ${asset}`,
                ];

            case 'store':
            case 'create':
                return [
                    `store ${userId === userData.id ? 'my' : 'child'} ${asset}`,
                    `store all ${asset}`,
                ];

            case 'update':
                return [
                    `update ${userId === userData.id ? 'my' : 'child'} ${asset}`,
                    `update all ${asset}`,
                ];

            case 'destroy':
            case 'delete':
                return [
                    `destroy ${userId === userData.id ? 'my' : 'child'} ${asset}`,
                    `destroy all ${asset}`,
                ];

            case 'copy':
            case 'clone':
                return [
                    `clone ${userId === userData.id ? 'my' : 'child'} ${asset}`,
                    `clone all ${asset}`,
                ];

            default:
                return [
                    `${type} ${userId === userData.id ? 'my' : 'child'} ${asset}`,
                    `${type} all ${asset}`,
                ];

        }
    },
    actions_permission: ({ type }) => {
        switch (type) {
            case 'show':
                return `show action`;

            case 'store':
            case 'create':
                return `create action`;

            case 'update':
                return `update action`;

            case 'destroy':
            case 'delete':
                return `destroy action`;

            default:
                return `${type} action`;

        }
    },
    field_enabled: ({ type }) => {
        switch (type) {
            case 'show':
            case 'read':
                return `can read field enabled`;

            case 'store':
            case 'update':
            case 'save':
                return `can save field enabled`;

            default:
                return `can ${type} field enabled`;

        }
    },
    check_menu: ({ permission }) => {
        if ( Array.isArray(permission) ) {
            return permission.map(permiss => `menu ${permiss}`);
        }
        return `menu ${permission}`;
    },
    dashboard_permission: ({ type }) => {
        switch (type) {
            case 'adv':
                return `show dashboard traffic advertiser`;

            case 'pub':
                return `show dashboard traffic publisher`;

            case 'statistics':
                return `show dashboard comparatives`;

            default:
                return ``;

        }
    },
    moderation_permission: ({ asset_type, status }) => {
        return `${asset_type} status ${status}`;
    },

    profile_permission: ({ type, status }) => {
        switch (type) {
            case 'postback':
                return `profile.${type}.${status}`;

            case 'api.token':
                return `profile.${type}.${status}`;

            case 'payment.requisites':
                return `profile.${type}.${status}`;

            default:
                return ``;

        }
    },
};
/**
 * usage :
 * 1. checkPermission('check_method_name', {object of params});
 * 2. checkPermission('checking_role_permission_name');
 * @param string|array checkedPermissionName
 * @param object|undefined checkedPermissionTypes
 * @returns boolean
 */
const checkPermission = (checkedPermissionName, checkedPermissionTypes = undefined) => {
    if (
        ('undefined' === typeof checkedPermissionName || !checkedPermissionName)
        || ('undefined' !== typeof checkedPermissionTypes && 'undefined' === typeof makeCheck[checkedPermissionName])
    ) {
        return false;
    }

    const { Profile: { userData: { permissions }, userData } } = store.getState();

    let role = checkedPermissionName;

    if ('undefined' !== typeof checkedPermissionTypes) {
        checkedPermissionTypes.userData = userData;
        role = makeCheck[checkedPermissionName](checkedPermissionTypes);
    }

    // console.log('checkPermission', role, permissions.includes(role));

    if ( Array.isArray(role) ) {
        return role.some(roleItem => permissions.includes(roleItem));
    }

    return permissions.includes(role);
};
export default checkPermission;

export const checkAssetPermission = assetName => actionName => (ownerId = 0) => {
    //console.log('checkAssetPermission', assetName, actionName, ownerId);
    return checkPermission('asset_permission', { asset: assetName, type: actionName, userId: ownerId })
};

export const checkGOActions = (checkedPermissionName) => {
    const { Profile: { userData: { permissions }, userData } } = store.getState();

    const getListOfAssetPermissions = (assets, typeOfAction, ownerId, userData) => {
        let list = [];
        if ( Array.isArray(assets) ) {
            assets.forEach(assetType => {
                list = list.concat( makeCheck['asset_permission']({ asset: assetType, type: typeOfAction, userId: ownerId, userData: userData }) );
            });
        }
        return list;
    }

    let listOfPermissions = [];

    switch (checkedPermissionName) {
        case 'enable_channels':
        case 'disable_channels':
            listOfPermissions = [
                // child asset permission + all
                ...getListOfAssetPermissions(['channel', 'placement'], 'update', 0, userData),
                // my asset permission + all
                ...getListOfAssetPermissions(['channel', 'placement'], 'update', userData.id, userData),
            ];

            return permissions.includes(makeCheck['field_enabled']({ type: 'save' }))
                && listOfPermissions.some(permission => permissions.includes(permission));

        case 'remove_channels':
            listOfPermissions = [
                ...getListOfAssetPermissions(['channel', 'placement'], 'destroy', 0, userData),
                ...getListOfAssetPermissions(['channel', 'placement'], 'destroy', userData.id, userData),
            ];

            return listOfPermissions.some(permission => permissions.includes(permission));

        case 'enable_projects':
        case 'disable_projects':
            listOfPermissions = [
                ...getListOfAssetPermissions(['project', 'campaign', 'creative'], 'update', 0, userData),
                ...getListOfAssetPermissions(['project', 'campaign', 'creative'], 'update', userData.id, userData),
            ];

            return permissions.includes(makeCheck['field_enabled']({ type: 'save' }))
                && listOfPermissions.some(permission => permissions.includes(permission));

        case 'remove_projects':
            listOfPermissions = [
                ...getListOfAssetPermissions(['project', 'campaign', 'creative'], 'destroy', 0, userData),
                ...getListOfAssetPermissions(['project', 'campaign', 'creative'], 'destroy', userData.id, userData),
            ];

            return listOfPermissions.some(permission => permissions.includes(permission));

        default:
            return false;
    }
};