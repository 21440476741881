import {
    channelsTypes as actionTypes,
    profileTypes as profileActionTypes,
    treesTypes as treeActionTypes
} from '../types';

export const initialState = {
    channels: {
        list: [],
        meta: {},
    },
    loading: false,
    data: [],
    placements: [],
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.CHANNELS_FETCH_BEGIN:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CHANNELS_FETCH_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actionTypes.CHANNELS_FETCH_SUCCESS:
            return {
                ...state,
                channels: {
                    list: [
                        ...payload.list
                    ],
                    meta: {...payload.meta},
                },
                loading: false
            };

        case actionTypes.CHANNEL_FETCH_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data.map(item => {
                        if ( parseInt(item.id) !== parseInt(payload.id) ) {
                            return {...item, ...payload};
                        }

                        return item;
                    }),
                    payload
                ]
            };

        case actionTypes.CHANNEL_UPDATE_SUCCESS:
            return {
                ...state,
                channels: {
                    ...state.channels,
                    list: state.channels.list.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data})
                },
                data: state.data.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data}),
            };

        case actionTypes.CHANNEL_CHANGE_PARAMS:
            return {
                ...state,
                channels: {
                    ...state.channels,
                    list: state.channels.list.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data})
                },
                data: state.data.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data}),
            };

        case actionTypes.CHANNEL_ADD_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    payload
                ]
            };

        case actionTypes.CHANNEL_DELETE_BEGIN:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CHANNEL_DELETE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actionTypes.CHANNEL_DELETE_SUCCESS:
            return {
                ...state,
                channels: {
                    ...state.channels,
                    list: state.channels.list.filter(item => parseInt(item.id) !== parseInt(payload.id))
                },
                data: state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                placements: state.placements.filter(item => parseInt(item.channel_id) !== parseInt(payload.id)),
                loading: false
            };

        case actionTypes.CHANNEL_PLACEMENT_FETCH_SUCCESS:
            return {
                ...state,
                placements: [
                    state.placements.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    payload
                ]
            };

        case actionTypes.CHANNEL_PLACEMENTS_FETCH_SUCCESS:
            return {
                ...state,
                data: state.data.map(channel => {
                    if ( parseInt(channel.id) === parseInt(payload.channelId) ) {
                        channel.placements = payload.data;
                        return {...channel};
                    }
                    if ( 'undefined' === typeof channel.placements ) {
                        channel.placements = [];
                        return {...channel};
                    }
                    return channel;
                })
            };

        case actionTypes.CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                placements: state.placements.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = payload.data.enabled;
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.CHANNEL_PLACEMENT_FEATURE_STATUS_CHANGE_FAILURE:
            return {
                ...state,
                placements: state.placements.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = 1 === parseInt(feature.required) ? 1 : parseInt(feature.enabled);
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.CHANNEL_FEATURE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                data: state.data.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = payload.data.enabled;
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.CHANNEL_FEATURE_STATUS_CHANGE_FAILURE:
            return {
                ...state,
                data: state.data.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = 1 === parseInt(feature.required) ? 1 : parseInt(feature.enabled);
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.CHANNEL_PLACEMENT_UPDATE_SUCCESS:
            return {
                ...state,
                data: state.data.map(channel => {
                    if ( parseInt(channel.id) === parseInt(payload.channel) ) {
                        channel.placements = [
                            ...(channel.placements || []).map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data})
                        ];
                        return {...channel};
                    }
                    return channel;
                }),
                placements: state.placements.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data})
            };

        case actionTypes.CHANNEL_PLACEMENT_ADD_SUCCESS:
            return {
                ...state,
                data: state.data.map(channel => {
                    if ( parseInt(channel.id) === parseInt(payload.channel) ) {
                        channel.placements = [
                            ...(channel.placements || []).filter(item => parseInt(item.id) !== parseInt(payload.data.id)),
                            payload.data
                        ];
                        return {...channel};
                    }
                    return channel;
                }),
                placements: [
                    ...(state.placements || []).filter(item => parseInt(item.id) !== parseInt(payload.data.id)),
                    payload.data
                ]
            };

        case treeActionTypes.TREE_GROUP_SUCCESS:
            const actions = payload.data.action;
            const action = Object.keys(actions)[0];

            const switchActions = (action, item) => {
                switch (action) {
                    case 'enable':
                        item.enabled = 1;
                        return {...item};

                    case 'disable':
                        item.enabled = 0;
                        return {...item};

                    case 'remove':
                        return null;

                    default:
                        break;
                }
                return item;
            };

            if ( 'undefined' !== typeof actions[action].channel || 'undefined' !== typeof actions[action].placement ) {
                return {
                    ...state,
                    channels: {
                        ...state.channels,
                        list: state.channels.list.map(item => {
                            if ( 'undefined' !== typeof actions[action].channel ) {
                                if (actions[action].channel.includes('' + item.id) || actions[action].channel.includes(item.id)) {
                                    item = switchActions(action, item);
                                }
                            }
                            return item;
                        }).filter(item => !!item)
                    },
                    data: state.data.map(item => {
                        if ( 'undefined' !== typeof actions[action].placement && item.placements ) {
                            item.placements = (item.placements || []).map(placement => {
                                if (actions[action].placement.includes('' + placement.id) || actions[action].placement.includes(placement.id)) {
                                    placement = switchActions(action, placement);
                                }
                                return placement;
                            }).filter(placement => !!placement);
                        }
                        if ( 'undefined' !== typeof actions[action].channel ) {
                            if (actions[action].channel.includes('' + item.id) || actions[action].channel.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item),
                    placements: state.placements.map(item => {
                        if ( 'undefined' !== typeof actions[action].placement ) {
                            if (actions[action].placement.includes('' + item.id) || actions[action].placement.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        if ( 'undefined' !== typeof actions[action].channel && action === 'remove' ) {
                            if (actions[action].channel.includes('' + item.channel_id) || actions[action].channel.includes(item.channel_id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item),
                }
            }
            return state;

        case profileActionTypes.REINCARNATION_SUCCESS: {
            if ( payload.token ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        default:
            return state;
    }
};

export default state;