import {createSelector, defaultMemoize} from 'reselect';
import * as dictionaries from './dictionaries';

const channelsSelector = state => state.Channels.data;
const placementsSelector = state => state.Channels.placements;

export const channels = createSelector(channelsSelector, channels => channels);
export const list = createSelector(state => state.Channels.channels.list, list => list);
export const loading = createSelector(state => state.Channels.loading, loading => loading);
export const total = createSelector(state => state.Channels.channels.meta?.pagination?.total, total => total);
export const page = createSelector(state => state.Channels.channels.meta?.pagination?.current_page, current_page => current_page);

export const channelData = defaultMemoize(
    channelId => createSelector(
        channelsSelector,
            channels => channels.find( item => parseInt(item.id) === parseInt(channelId) )
    )
);
export const channelPlacements = defaultMemoize(
    channelId => createSelector(
        channelsSelector,
            channels => channels.find( item => parseInt(item.id) === parseInt(channelId) )?.placements || []
    )
);
export const placementData = defaultMemoize(
    placementId => createSelector(
        placementsSelector,
        placements => placements.find( item => parseInt(item.id) === parseInt(placementId) )
    )
);

export const getChannelDictionaries = defaultMemoize(
    (channelId, assetType) => createSelector(
        [
            dictionaries.assetTagsByAssetType(assetType),
            dictionaries.categoriesByAssetType(assetType),
            dictionaries.statusesByAssetType(assetType),
            dictionaries.webAnalyticProviders,
        ],
        (
            assetTags,
            categories,
            statuses,
            web_analytic_providers
        ) => {
            return {
                    assetTags: assetTags,
                    categories: categories,
                    statuses: statuses,
                    web_analytic_providers: web_analytic_providers,
            };
        }
    )
);

export const channelsDictionaries = createSelector(
    [dictionaries.statusesByAssetType('channel')],
    (statuses) => ({
        statuses
    })
);
