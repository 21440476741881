import axios from "../../core/axiosInit";
import { creativetemplatesTypes as actionTypes } from "../types";

import checkAuthError from "./checkAuthError";
import {queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries} from "../helpers";

export const fetchCreativeTemplates = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.CREATIVETEMPLATES_FETCH_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['creative_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params || {});

        const response = await axios.get(`creative_templates`, { params: { ...params } });

        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.CREATIVETEMPLATES_FETCH_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.CREATIVETEMPLATES_FETCH_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const fetchItem = (id, params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.CREATIVETEMPLATES_ITEM_FETCH_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['creative_type'];
        params = requestAssignDictionaries(dicts, dictionaries, params || {});

        const response = await axios.get(`creative_templates/${id}`, { params: { ...params } });

        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.CREATIVETEMPLATES_ITEM_FETCH_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.CREATIVETEMPLATES_ITEM_FETCH_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const updateItem = (id, params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_UPDATE_BEGIN, id) );
    try {
        const response = await axios.put(`creative_templates/${id}`, {...params});
        dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_UPDATE_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_UPDATE_FAILURE, { id, params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const addItem = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_ADD_BEGIN) );
    try {
        const response = await axios.post(`creative_templates`, {...params});
        dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_ADD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_ADD_FAILURE, { params, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const deleteItem = (id) => async (dispatch) => {
    dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_DELETE_BEGIN, id) );
    try {
        const response = await axios.delete(`creative_templates/${id}`);
        dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_DELETE_SUCCESS, {id: id}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CREATIVETEMPLATES_ITEM_DELETE_FAILURE, { id, error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const fetchMassSharingCreativeTemplates = (params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.SHARED_CREATIVETEMPLATES_FETCH_BEGIN));
    try {
        const response = await axios.get(`creative_templates/shared`, { params: { ...params } });

        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.SHARED_CREATIVETEMPLATES_FETCH_SUCCESS, response.data.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.SHARED_CREATIVETEMPLATES_FETCH_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const updateMassSharingCreativeTemplates = (params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.SHARED_CREATIVETEMPLATES_UPDATE_BEGIN));
    try {
        const response = await axios.put(`creative_templates/shared`, { ...params });

        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.SHARED_CREATIVETEMPLATES_UPDATE_SUCCESS, response.data));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.SHARED_CREATIVETEMPLATES_UPDATE_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};