import axios from '../../core/axiosInit';
import { remoteTypes as actionTypes } from '../types';

import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError } from '../helpers';

/**
 * Exec remote methods
 *
 * params structure:
 *
 *       [
 *        {
 *           method: "encode"
 *           params: {test: [12, 56, 89], name: "test", test1: {path: "/cont"}}
 *         },
 *        {
 *           method: "encode"
 *           params: {test: [12, 56, 89], name: "test", test1: {path: "/cont"}}
 *         },
 *        {
 *           method: "decode"
 *           params: "75593269634f45684b63305630666b71664a725548523744344e7a5531756a4b424166353341505776446a5530757a4e4741656932682f4d2b513d3d"
 *         }
 *        ]
 *
 *
 * @param params array or object
 * @returns {Function}
 */
export const execRemote = (params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.REMOTE_BEGIN) );
    try {
        const response = await axios.post(`rpc`, Array.isArray(params) ? params : [params]);
        dispatch( queryAction(actionTypes.REMOTE_SUCCESS, response.data.data) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.REMOTE_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};