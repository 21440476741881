import { postbackslogTypes as actionTypes } from '../types';

export const initialState = {
    loading: false,
    postbacksLogData: {
        data: [],
        meta: {},
        isLoadData: false,
    },
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.POSTBACKSLOG_FETCH_BEGIN:
            return {
                ...state,
                loading: true
            };

        case actionTypes.POSTBACKSLOG_FETCH_FAILURE:
            if ( payload?.error?.code === 'ERR_CANCELED' ) {
                return state;
            }
            return {
                ...state,
                loading: false
            };

        case actionTypes.POSTBACKSLOG_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                postbacksLogData: {
                    ...state.postbacksLogData,
                    ...payload,
                    isLoadData: true,
                }
            };

        default:
            return state;
    }
};

export default state;