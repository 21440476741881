import axios from '../../core/axiosInit';

import { profileTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import { queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries } from '../helpers';
import { SentryConfigureScope } from '../../helpers/Sentry';
import { wsDisconnect, wsConnect } from "./ws";

export const fetchProfile = () => async (dispatch, getState) => {
    await dispatch( queryAction(actionTypes.PROFILE_FETCH_BEGIN) );
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['time_zone'];
        const params = requestAssignDictionaries(dicts, dictionaries);

        const response = await axios.get(`profile/details`, {params: {...params}});
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.PROFILE_FETCH_SUCCESS, { ...response.data.data[0], ws_token: response.data.meta?.ws_token }));
        const data = response.data.data[0];
        SentryConfigureScope({
            id: data.id,
            username: data.name,
        });
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_FETCH_FAILURE, { error }) );
        if ( error.response.status === 403 || error.response.status === 0 ) {
            dispatch( checkAuthError( { ...error, response: {...error.response, status: 401} } ) );
        } else {
            dispatch( checkAuthError( error ) );
        }
        return checkResponseError(error);
    }
};

export const auth = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.AUTH_BEGIN) );
    try {
        const response = await axios.post(`login`, {...params});
        dispatch( queryAction(actionTypes.AUTH_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        if( error.response && error.response.data.status === 409 ) {
            dispatch( queryAction(actionTypes.AUTH_VERIFIED_FAILURE, { error }) );
        }
        dispatch( queryAction(actionTypes.AUTH_FAILURE, { error }) );
        return checkResponseError(error);
    }
};

export const logOut = () => async (dispatch, getState)  => {
    try {
        let response;
        const { Profile: { userData: { parentData } } } = getState();
        if ( parentData ) {
            await dispatch(queryAction(actionTypes.UPDATE_REINCARNATION_TOKEN, null));
            await axios.clearAllTimeouts();
            await axios.killAll(9);

            response = await axios.get(`profile/details`);
        } else {
            await axios.clearAllTimeouts();
            await axios.killAll(9);

            response = await axios.post(`/logout`, {});
        }

        if (response.data.success === true && response.data.data.length === 1) {
            const { data: { data, meta } } = response;
            await dispatch( wsDisconnect() );
            await dispatch( queryAction(actionTypes.REINCARNATION_SUCCESS, {data: data[0], token: null, ws_token: meta?.ws_token}) );
            await dispatch( wsConnect() );

            // change saved ID of user
            sessionStorage.setItem('hiddenTabID', response.data.data[0].id);
            sessionStorage.setItem('fetchCurrentID', response.data.data[0].id);

            return response.data;
        }
    } catch (error) {
        dispatch({type: actionTypes.LOGOUT});
        return true;
    }
};

export const selfRegistration = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.REGISTER_BEGIN) );
    try {
        const response = await axios.post(`register`, {...params});
        dispatch( queryAction(actionTypes.REGISTER_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.REGISTER_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const profileEdit = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROFILE_EDIT_BEGIN) );
    try {
        const response = await axios.put(`profile/update`, {...params});
        dispatch( queryAction(actionTypes.PROFILE_EDIT_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_EDIT_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const changePassword = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROFILE_CHANGE_PASSWORD_BEGIN) );
    try {
        const response = await axios.put(`profile/update-password`, {...params});
        dispatch( queryAction(actionTypes.PROFILE_CHANGE_PASSWORD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_CHANGE_PASSWORD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const resetPassword = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.RESET_PASSWORD_BEGIN) );
    try {
        const response = await axios.post(`reset-password`, {...params});
        dispatch( queryAction(actionTypes.RESET_PASSWORD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.RESET_PASSWORD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const restorePassword = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.RESET_CHANGE_PASSWORD_BEGIN) );
    try {
        const response = await axios.post(`change-password`, {...params});
        dispatch( queryAction(actionTypes.RESET_CHANGE_PASSWORD_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.RESET_CHANGE_PASSWORD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const confirmEmail = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.CONFIRM_EMAIL_BEGIN) );
    try {
        const response = await axios.post(`resend-registration-mail`, {...params});
        dispatch( queryAction(actionTypes.CONFIRM_EMAIL_SUCCESS, {} ) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.CONFIRM_EMAIL_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const cancelConfirmEmail = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.CANCEL_CONFIRM_EMAIL_SUCCESS));
};

export const addPaymentSystems = params => async dispatch => {
    dispatch( queryAction(actionTypes.ADD_PAYMENTS_SYSTEMS_BEGIN) );
    try {
        const response = await axios.post(`profile/save-requisites`, {...params});
        dispatch( queryAction(actionTypes.ADD_PAYMENTS_SYSTEMS_SUCCESS, response.data.data[0] ) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.ADD_PAYMENTS_SYSTEMS_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const verificationPassword = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.VERIFICATION_PASSWORD_BEGIN) );
    try {
        const response = await axios.post(`profile/save-requisites-verification-password`, {...params});
        dispatch( queryAction(actionTypes.VERIFICATION_PASSWORD_SUCCESS, {} ) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.VERIFICATION_PASSWORD_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const reincarnation = (params, onSuccess) => async (dispatch) => {
    dispatch( queryAction(actionTypes.REINCARNATION_BEGIN) );
    try {
        const response = await axios.post(`login-user`, {...params});
        let token = null;
        let ws_token = null;

        /*const { headers: { authorization } } = response;
        if ( authorization ) {
            token = authorization.replace('Bearer ', '');
        }*/
        const { data: { meta } } = response;
        if ( meta && 'undefined' !== typeof meta.tab_token ) {
            token = meta.tab_token;
        }
        if ( meta && 'undefined' !== typeof meta.ws_token ) {
            ws_token = meta.ws_token;
        }

        const data = response.data.data[0];
        await dispatch( wsDisconnect() );
        await dispatch( queryAction(actionTypes.REINCARNATION_SUCCESS, {data: data, token, ws_token}) );
        await dispatch( wsConnect() );

        if ( 'function' === typeof onSuccess ) {
            onSuccess();
        }

        SentryConfigureScope({
            id: data.id,
            username: data.name,
        });

        // change saved ID of user
        sessionStorage.setItem('hiddenTabID', data.id);
        sessionStorage.setItem('fetchCurrentID', data.id);

        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.REINCARNATION_FAILURE, { error }) );
        return checkResponseError(error);
    }
};

export const updateReincarnationToken = token => {
    return queryAction(actionTypes.UPDATE_REINCARNATION_TOKEN, token);
}

export const setSettings = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROFILE_SET_SETTINGS_BEGIN) );
    try {
        const response = await axios.patch(`profile/update-settings`, {settings: {...params}});
        dispatch( queryAction(actionTypes.PROFILE_SET_SETTINGS_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_SET_SETTINGS_FAILURE, { error }) );
        return checkResponseError(error);
    }
};

export const updateUserStatisticApiToken = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROFILE_UPDATE_TOKEN_STATISTICS_BEGIN) );
    try {
        const response = await axios.put(`profile/refresh-api-token`);
        dispatch( queryAction(actionTypes.PROFILE_UPDATE_TOKEN_STATISTICS_SUCCESS, response.data.data[0]) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_UPDATE_TOKEN_STATISTICS_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const getPostbackUrl = params => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROFILE_FETCH_POSTBACK_BEGIN) );
    try {
        const response = await axios.get(`profile/postback`, {...params});
        dispatch( queryAction(actionTypes.PROFILE_FETCH_POSTBACK_SUCCESS, response.data.data[0]?.postback_url) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_FETCH_POSTBACK_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const updatePostbackUrl = (params) => async (dispatch) => {
    dispatch( queryAction(actionTypes.PROFILE_POSTBACK_URL_BEGIN) );
    try {
        const response = await axios.put(`profile/postback`, {...params});
        dispatch( queryAction(actionTypes.PROFILE_POSTBACK_URL_SUCCESS, {data: response.data.data[0]?.postback_url}) );
        return response.data;
    } catch (error) {
        dispatch( queryAction(actionTypes.PROFILE_POSTBACK_URL_FAILURE, { error }) );
        dispatch( checkAuthError(error) );
        return checkResponseError(error);
    }
};

export const hideAlertMessage = () => async (dispatch) => {
    dispatch( queryAction(actionTypes.HIDE_ALERT_MESSAGE) );
}