import React, { Component, Suspense } from 'react';
import { connect } from "react-redux";
import { withRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { bindActionCreators } from "redux";
import { profileActions, commonActions } from "./redux/actions";
import Loading from './views/Loading/Loading';
import { profileSelectors } from "./redux/selectors";
import { appVersion, metaAppVersion } from './core/constants';
import { SentryMessage } from "./helpers/Sentry";

import { LicenseManager } from "ag-grid-enterprise";
import { Trans } from "react-i18next";
import { Alert } from "reactstrap";
LicenseManager.setLicenseKey("GCDUDKCF[vEDDORJA]SXUTLFDCVHUFD94141b418fd197fee19d7cde6c116bb9");

const mapStateToProps = state => profileSelectors.getIsAuth(state);

const mapDispatchToProps = (dispatch) => {
    const { fetchProfile } = profileActions;
    const { getAppVersion, setServiceAvailable, setServiceTimeout } = commonActions;
    return bindActionCreators({
        fetchProfile, getAppVersion, setServiceAvailable, setServiceTimeout
    }, dispatch);
};

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Forgot = React.lazy(() => import('./views/Pages/Forgot'));
const RestorePassword = React.lazy(() => import('./views/Pages/RestorePassword'));
const ConfirmedLink = React.lazy(() => import('./views/Components/ConfirmedLink'));
const ChangedLink = React.lazy(() => import('./views/Components/ChangedLink'));

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

class App extends Component {
    state = {
        isLoading: true,
    };

    async getProfile() {
        const { fetchProfile } = this.props;
        await fetchProfile();

        this.setState({ isLoading: false });
    }

    countDown = async () => {
        const { isServiceAvailable, fetchProfile, unavailableTimeout, setServiceTimeout } = this.props;

        if ( !isServiceAvailable && unavailableTimeout !== null && unavailableTimeout > 0 ) {
            const newTimeOut = unavailableTimeout - 1;

            setTimeout( async () => {
                setServiceTimeout(newTimeOut);
                if ( newTimeOut === 0 ) {
                    await fetchProfile();
                }
            }, 1000);
        }
    }

    componentDidMount = async () => {
        if (appVersion && metaAppVersion && appVersion !== metaAppVersion) {
            let reloadCounter = 0;
            if (window.sessionStorage) {
                let reloadCounterSession = sessionStorage.getItem("reloadCounter");
                if (!reloadCounterSession) {
                    reloadCounterSession = 0;
                }
                reloadCounter = parseInt(reloadCounterSession) + 1;
                sessionStorage.setItem("reloadCounter", reloadCounter.toString());
            }
            console.log("[VersionError] Reloading due to version dismatch", appVersion, metaAppVersion, reloadCounter);
            if (reloadCounter < 3) {
                window.location.reload();
                return false;
            } else {
                if (!sessionStorage.getItem("reloadMessageSent")) {
                    SentryMessage(
                        `[VersionError] Reloading due to version dismatch, appVersion=${appVersion}, metaAppVersion=${metaAppVersion}, reloadCounter=${reloadCounter}`
                    );
                    sessionStorage.setItem("reloadMessageSent", "1");
                }
            }
        } else {
            if (window.sessionStorage) {
                if (sessionStorage.getItem("reloadCounter")) {
                    sessionStorage.removeItem("reloadCounter");
                }
                if (sessionStorage.getItem("reloadMessageSent")) {
                    sessionStorage.removeItem("reloadMessageSent");
                }
            }
        }
        await this.getProfile();
    };

    componentDidUpdate = async () => {
        const { isServiceAvailable } = this.props;
        if ( !isServiceAvailable ) {
            await this.countDown();
        }
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <Loading />;
        }

        const { isAuth, isServiceAvailable, unavailableTimeout } = this.props;

        const notAuthRoutes = (
            <Switch>
                <Route exact path="/confirmed" name="Confirmed" component={ConfirmedLink} />
                <Route exact path="/changed" name="Changed" component={ChangedLink} />
                <Route exact path="/register" name="Register Page" component={Register} />
                {/*<Route exact path="/404" name="Page 404" component={Page404} />*/}
                {/*<Route exact path="/500" name="Page 500" component={Page500} />*/}
                <Route exact path="/forgot" name="Forgot" component={Forgot} />
                <Route exact path="/restore-password/:key" name="Restore Password" component={RestorePassword} />
                <Route exact path="/login" name="Login Page" component={Login} />
                <Route name="Login Page" component={Login} />
            </Switch>
        );

        const authorizedRoutes = (
            <Switch>
                <Route exact path="/confirmed" name="Confirmed" component={ConfirmedLink} />
                <Route exact path="/changed" name="Changed" component={ChangedLink} />
                {/*<Route exact path="/404" name="Page 404" component={Page404} />*/}
                {/*<Route exact path="/500" name="Page 500" component={Page500} />*/}
                <Route exact path="/restore-password/:key" name="Restore Password" component={RestorePassword} />
                <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
        );

        return (
            <>
                {!isServiceAvailable &&
                    <div style={{position: "absolute", top: '5px', left: '5px', right: '5px', zIndex: '9999999999999'}}>
                        <Alert color="danger" className="mb-0">
                            <i className="fa fa-warning"/>{' '}
                            <Trans i18nKey={`Messages.Service is unavailable`}>
                                Technical work is underway. Some parts of the site may not work or work incorrectly.
                            </Trans>{' '}
                            { unavailableTimeout === 0 &&
                                <Trans
                                    i18nKey={`Messages.Checking`}
                                >
                                    Checking...
                                </Trans>
                            }
                            { unavailableTimeout > 0 &&
                                <Trans
                                    i18nKey={`Messages.Automatic status check after sec`}
                                    values={{
                                        seconds: `${unavailableTimeout}`,
                                    }}
                                >
                                    Automatic status check after 60 sec
                                </Trans>
                            }
                        </Alert>
                    </div>
                }
                <Suspense fallback={<Loading />}>{isAuth ? authorizedRoutes : notAuthRoutes}</Suspense>
            </>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);