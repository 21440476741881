import { dashboardTypes as actionTypes } from '../types';
import { profileTypes as actionTypesProfile } from '../types';

export const initialState = {
    dataTabs: {},
    // charts: {},
    // tables: {},
    dataStatistics: {},
    comparatives: {}
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.DASHBOARD_STATISTICS_SUCCESS:
            return {
                ...state,
                dataStatistics: {
                    ...state.dataStatistics,
                    [payload.userId]: {
                        ...state.dataStatistics[payload.userId],
                        [payload.tab]: { ...payload.data }
                    }
                }
            };

        case actionTypes.DASHBOARD_STATISTICS_FAILURE:
            const { message } = payload.error;
            if (message && message === 'Operation canceled due to new request.') {
                return state;
            }
            return {
                ...state,
                dataStatistics: {
                    ...state.dataStatistics,
                    [payload.userId]: {
                        ...state.dataStatistics[payload.userId],
                        [payload.tab]: { ...payload.data }
                    }
                }
            };

        // case actionTypes.DASHBOARD_STATISTICS_TABLE_SUCCESS:
        //     return {
        //         ...state,
        //         tables: {
        //             ...state.tables,
        //             [payload.userId]: {
        //                 ...state.tables[payload.userId],
        //                 [payload.tab]: { ...payload.data }
        //             }
        //         }
        //     };

        // case actionTypes.DASHBOARD_STATISTICS_TABLE_FAILURE:
        //     const { message } = payload.error;
        //     if (message && message === 'Operation canceled due to new request.') {
        //         return state;
        //     }
        //     return {
        //         ...state,
        //         tables: {
        //             ...state.tables,
        //             [payload.userId]: {
        //                 ...state.tables[payload.userId],
        //                 [payload.tab]: { ...payload.data }
        //             }
        //         }
        //     };

        // case actionTypes.DASHBOARD_STATISTICS_CHART_SUCCESS:
        // case actionTypes.DASHBOARD_STATISTICS_CHART_FAILURE:
        //     return {
        //         ...state,
        //         charts: {
        //             ...state.charts,
        //             [payload.userId]: {
        //                 ...state.charts[payload.userId],
        //                 [payload.tab]: { ...payload.data }
        //             }
        //         }
        //     };

        case actionTypes.DASHBOARD_STATISTICS_COMPARATIVES_BEGIN:
            return {
                ...state,
                comparatives: {
                    ...state.comparatives,
                    [payload.userId]: {
                        loading: true,
                    }
                }
            }

        case actionTypes.DASHBOARD_STATISTICS_COMPARATIVES_SUCCESS:
        case actionTypes.DASHBOARD_STATISTICS_COMPARATIVES_FAILURE:
            return {
                ...state,
                comparatives: {
                    ...state.comparatives,
                    [payload.userId]: {
                        data: payload.data,
                        time: payload.time,
                        loading: false,
                    }
                }
            };

        // truncate data on de-reincarnation
        case actionTypesProfile.UPDATE_REINCARNATION_TOKEN:
            if ( !payload ) {
                return {
                    dataTabs: {},
                    dataStatistics: {},
                    comparatives: {}
                }
            }
            return state;

        default:
            return state;
    }
};

export default state;