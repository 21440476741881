// just import actions types from other files (modules)
import * as wsTypes from './ws';
import * as profileTypes from './profile';
import * as usersTypes from './users';
import * as channelsTypes from './channels';
import * as dictionariesTypes from './dictionaries';
import * as featuresTypes from './features';
import * as campaignsTypes from './campaigns';
import * as billingTypes from './billing';
import * as analyticsTypes from './analytics';
import * as projectsTypes from './projects';
import * as dashboardTypes from './dashboard';
import * as financeTypes from './finance';
import * as activitiesTypes from './activities';
import * as treesTypes from './trees';
import * as rolesTypes from './roles';
import * as feedtemplatesTypes from './feedtemplates';
import * as remoteTypes from './remote';
import * as actionsTypes from './actions';
import * as commonTypes from './common';
import * as autopaymentsTypes from './autopayments';
import * as moderationTypes from './moderation';
import * as pricinginfoTypes from './pricinginfo';
import * as postbackslogTypes from './postbackslog';
import * as exchangeTypes  from './exchange';
import * as creativetemplatesTypes from './creativetemplates';

// export all actions types as modules
export { 
    wsTypes,
    profileTypes, 
    usersTypes, 
    channelsTypes,
    dictionariesTypes,
    featuresTypes, 
    campaignsTypes, 
    billingTypes,
    analyticsTypes,
    projectsTypes,
    dashboardTypes,
    financeTypes,
    activitiesTypes,
    treesTypes,
    rolesTypes,
    feedtemplatesTypes,
    remoteTypes,
    actionsTypes,
    commonTypes,
    autopaymentsTypes,
    moderationTypes,
    pricinginfoTypes,
    postbackslogTypes,
    exchangeTypes,
    creativetemplatesTypes,
};
