import axios from '../../core/axiosInit';

import { autopaymentsTypes as actionTypes } from '../types';
import checkAuthError from './checkAuthError';
import {queryAction, checkResponseError, checkDictionaries, requestAssignDictionaries} from '../helpers';

export const fetchAutoPayments = (params) => async (dispatch, getState) => {
    dispatch(queryAction(actionTypes.AUTOPAYMENTS_FETCH_BEGIN));
    try {
        const { Dictionaries: dicts } = getState();
        const dictionaries = ['PaymentSystem', 'currency', 'payment_gateway'];
        params = requestAssignDictionaries(dicts, dictionaries, params);

        const response = await axios.get(`auto-payments`, { params: { ...params } });
        checkDictionaries(dispatch, response.data);
        dispatch(queryAction(actionTypes.AUTOPAYMENTS_FETCH_SUCCESS,
            {
                data: response.data.data,
                meta: response.data.meta
            }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.AUTOPAYMENTS_FETCH_FAILURE, { error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};

export const groupActionsAutoPayments = (action, params) => async (dispatch) => {
    dispatch(queryAction(actionTypes.AUTOPAYMENTS_GROUP_BEGIN));
    try {
        let response;
        if (action === 'delete') {
            response = await axios.delete(`auto-payments`, { data: { ...params } });
        } else if (action === 'completion') {
            response = await axios.put(`auto-payments-completion`, { ...params });
        } else if (action === 'attach') {
            response = await axios.put(`auto-payments`, { ...params });
        }

        dispatch(queryAction(actionTypes.AUTOPAYMENTS_GROUP_SUCCESS, { action: action, data: response.data.data }));
        return response.data;
    } catch (error) {
        dispatch(queryAction(actionTypes.AUTOPAYMENTS_GROUP_FAILURE, { action, error }));
        dispatch(checkAuthError(error));
        return checkResponseError(error);
    }
};