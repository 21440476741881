export const CREATIVETEMPLATES_FETCH_BEGIN = "CreativeTemplates/FetchBegin";
export const CREATIVETEMPLATES_FETCH_SUCCESS = "CreativeTemplates/FetchSuccess";
export const CREATIVETEMPLATES_FETCH_FAILURE = "CreativeTemplates/FetchFailure";
export const CREATIVETEMPLATES_ITEM_FETCH_BEGIN = "CreativeTemplates/FetchItemBegin";
export const CREATIVETEMPLATES_ITEM_FETCH_SUCCESS = "CreativeTemplates/FetchItemSuccess";
export const CREATIVETEMPLATES_ITEM_FETCH_FAILURE = "CreativeTemplates/FetchItemFailure";
export const CREATIVETEMPLATES_ITEM_UPDATE_BEGIN = "CreativeTemplates/UpdateItemBegin";
export const CREATIVETEMPLATES_ITEM_UPDATE_SUCCESS = "CreativeTemplates/UpdateItemSuccess";
export const CREATIVETEMPLATES_ITEM_UPDATE_FAILURE = "CreativeTemplates/UpdateItemFailure";
export const CREATIVETEMPLATES_ITEM_ADD_BEGIN = "CreativeTemplates/AddItemBegin";
export const CREATIVETEMPLATES_ITEM_ADD_SUCCESS = "CreativeTemplates/AddItemSuccess";
export const CREATIVETEMPLATES_ITEM_ADD_FAILURE = "CreativeTemplates/AddItemFailure";
export const CREATIVETEMPLATES_ITEM_DELETE_BEGIN = "CreativeTemplates/DeleteItemBegin";
export const CREATIVETEMPLATES_ITEM_DELETE_SUCCESS = "CreativeTemplates/DeleteItemSuccess";
export const CREATIVETEMPLATES_ITEM_DELETE_FAILURE = "CreativeTemplates/DeleteItemFailure";

export const SHARED_CREATIVETEMPLATES_FETCH_BEGIN = "SharedCreativeTemplates/FetchBegin";
export const SHARED_CREATIVETEMPLATES_FETCH_SUCCESS = "SharedCreativeTemplates/FetchSuccess";
export const SHARED_CREATIVETEMPLATES_FETCH_FAILURE = "SharedCreativeTemplates/FetchFailure";

export const SHARED_CREATIVETEMPLATES_UPDATE_BEGIN = "SharedCreativeTemplates/UpdateBegin";
export const SHARED_CREATIVETEMPLATES_UPDATE_SUCCESS = "SharedCreativeTemplates/UpdateSuccess";
export const SHARED_CREATIVETEMPLATES_UPDATE_FAILURE = "SharedCreativeTemplates/UpdateFailure";