import {
    campaignsTypes as actionTypes,
    profileTypes as profileActionTypes,
    treesTypes as treeActionTypes
} from '../types';
import { deepCopyObject } from "../../helpers/objectHelpers";

export const initialState = {
    list: [],
    loadingCampaigns: false,
    loadingCreatives: false,
    data: [],
    creatives: [],
};

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.CAMPAIGNS_FETCH_BEGIN:
            return {
                ...state,
                loadingCampaigns: true
            };
        case actionTypes.CAMPAIGNS_FETCH_FAILURE:
            return {
                ...state,
                loadingCampaigns: false
            };
        case actionTypes.CAMPAIGNS_FETCH_SUCCESS:
            return {
                ...state,
                list: [
                    ...payload
                ],
                loadingCampaigns: false
            };

        case actionTypes.CAMPAIGN_FETCH_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    payload
                ]
            };

        case actionTypes.CAMPAIGN_UPDATE_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data}),
                data: state.data.map(item => parseInt(item.id) !== parseInt(payload.id) ? item : {...item, ...payload.data}),
            };

        case actionTypes.CAMPAIGN_ADD_SUCCESS:
            return {
                ...state,
                data: [
                    ...state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    payload
                ]
            };

        case actionTypes.CAMPAIGN_DELETE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                list: state.list.filter(item => parseInt(item.id) !== parseInt(payload.id))
            };

        case actionTypes.CAMPAIGN_CREATIVE_FETCH_SUCCESS:
            return {
                ...state,
                creatives: [
                    ...state.creatives.filter(item => parseInt(item.id) !== parseInt(payload.id)),
                    {
                        ...payload,
                        ...payload.params
                    }
                ]
            };

        case actionTypes.CAMPAIGN_CREATIVE_ADD_SUCCESS:
            return {
                ...state,
                creatives: [
                    ...(state.creatives || []).filter(item => parseInt(item.id) !== parseInt(payload.data.id)),
                    {
                        ...payload.data,
                        ...payload.data.params
                    }
                ]
            };

        case actionTypes.CAMPAIGN_CREATIVE_UPDATE_SUCCESS:
            return {
                ...state,
                creatives: state.creatives.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.id) ) {
                        item = { ...item, ...payload.data, ...payload.data.params };
                    }
                    return item;
                })
            };

        case actionTypes.CAMPAIGN_CREATIVES_FETCH_BEGIN:
            return {
                ...state,
                loadingCreatives: true,
            };
        case actionTypes.CAMPAIGN_CREATIVES_FETCH_FAILURE:
            return {
                ...state,
                loadingCreatives: false,
            };
        case actionTypes.CAMPAIGN_CREATIVES_FETCH_SUCCESS:
            return {
                ...state,
                loadingCreatives: false,
                data: state.data.map(campaign => {
                    if ( parseInt(campaign.id) === parseInt(payload.campaignId) ) {
                        campaign.creatives = payload.data;
                        return {...campaign};
                    }
                    if ( 'undefined' === typeof campaign.creatives ) {
                        campaign.creatives = [];
                        return {...campaign};
                    }
                    return campaign;
                })
            };

        case actionTypes.CAMPAIGN_FEATURE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                data: state.data.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = 1 === parseInt(feature.required) ? 1 :
                                    (parseInt(feature.enabled) - 1 < 0 ? 1 : 0);
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case actionTypes.CAMPAIGN_CREATIVE_FEATURE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                creatives: state.creatives.map(item => {
                    if ( parseInt(item.id) === parseInt(payload.asset_id) ) {
                        item.features = item.features.map(feature => {
                            if ( parseInt(feature.feature_asset_type_id) === parseInt(payload.feature_asset_type_id) ) {
                                feature.enabled = 1 === parseInt(feature.required) ? 1 :
                                    (parseInt(feature.enabled) - 1 < 0 ? 1 : 0);
                                return {...feature};
                            }
                            return feature;
                        });
                        return {...item};
                    }
                    return item;
                }),
            };

        case treeActionTypes.TREE_GROUP_SUCCESS:
            const actions = payload.data.action;
            const action = Object.keys(actions)[0];

            const switchActions = (action, item) => {
                switch (action) {
                    case 'enable':
                        item.enabled = 1;
                        return {...item};

                    case 'disable':
                        item.enabled = 0;
                        return {...item};

                    case 'remove':
                        return null;

                    default:
                        break;
                }
                return item;
            };

            if (
                ( 'undefined' !== typeof actions[action].project && action === 'remove' )
                || 'undefined' !== typeof actions[action].campaign
                || 'undefined' !== typeof actions[action].creative
            ) {
                let listOnAction = deepCopyObject(state.list);
                let dataOnAction = deepCopyObject(state.data);
                let creativesOnAction = deepCopyObject(state.creatives);

                if ('undefined' !== typeof actions[action].project && action === 'remove') {
                    listOnAction = listOnAction.filter(camp => {
                        if (actions[action].project.includes('' + camp.project_id) || actions[action].project.includes(camp.project_id)) {
                            creativesOnAction = creativesOnAction.filter(creative => parseInt(creative.campaign_id) !== parseInt(camp.id));
                            return false;
                        }
                        return true;
                    });
                    dataOnAction = dataOnAction.filter(camp => !(actions[action].project.includes('' + camp.project_id) || actions[action].project.includes(camp.project_id)));
                }

                return {
                    ...state,
                    list: listOnAction.map(item => {
                        if ('undefined' !== typeof actions[action].campaign) {
                            if (actions[action].campaign.includes('' + item.id) || actions[action].campaign.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item),
                    data: dataOnAction.map(item => {
                        if ('undefined' !== typeof actions[action].creative && item.creatives) {
                            item.creatives = item.creatives.map(creative => {
                                if (actions[action].creative.includes('' + creative.id) || actions[action].creative.includes(creative.id)) {
                                    creative = switchActions(action, creative);
                                }
                                return creative;
                            }).filter(creative => !!creative);
                        }
                        if ('undefined' !== typeof actions[action].campaign) {
                            if (actions[action].campaign.includes('' + item.id) || actions[action].campaign.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item),
                    creatives: creativesOnAction.map(item => {
                        if ('undefined' !== typeof actions[action].creative) {
                            if (actions[action].creative.includes('' + item.id) || actions[action].creative.includes(item.id)) {
                                item = switchActions(action, item);
                            }
                        }
                        if ('undefined' !== typeof actions[action].campaign && action === 'remove') {
                            if (actions[action].campaign.includes('' + item.campaign_id) || actions[action].campaign.includes(item.campaign_id)) {
                                item = switchActions(action, item);
                            }
                        }
                        return item;
                    }).filter(item => !!item)
                };
            }
            return state;

        case treeActionTypes.TREE_ITEM_STATUS_UPDATE:
            if ( 'undefined' !== typeof payload.assetData.project_id || 'undefined' !== typeof payload.assetData.campaign_id ) {
                return {
                    ...state,
                    data: state.data.map(item => {
                        if ( 'undefined' !== typeof payload.assetData.project_id && parseInt(item.id) === parseInt(payload.assetData.id) ) {
                            item.status = payload.newStatus;
                            return {...item};
                        } else if ( 'undefined' !== typeof payload.assetData.campaign_id && parseInt(item.id) === parseInt(payload.assetData.campaign_id) ) {
                            //creatives
                            item.creatives = item.creatives.map(creative => {
                                if ( parseInt(creative.id) === parseInt(payload.assetData.id) ) {
                                    creative.status = payload.newStatus;
                                    return {...creative};
                                }
                                return creative;
                            });
                        }
                        return item;
                    }),
                    creatives: state.creatives.map(item => {
                        if ( 'undefined' !== typeof payload.assetData.campaign_id && parseInt(item.id) === parseInt(payload.assetData.id) ) {
                            item.status = payload.newStatus;
                            return {...item};
                        }
                        return item;
                    }),
                };
            }
            return state;

        case profileActionTypes.REINCARNATION_SUCCESS: {
            if ( payload.token ) {
                return {
                    ...initialState
                };
            }
            return state;
        }

        default:
            return state;
    }
};

export default state;